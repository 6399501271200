import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import PublishIcon from '@material-ui/icons/Publish';
import { CountryService } from '../Services/country-service';
const UploadBox = ({ device_type, tab_id, banner_id, SetBannerMap, bannerMap, selectedTavalue,
    SeturltextPopup,SeturltextPopupBool, countryUrlValidationDict, SetcountryUrlValidationDict, countryBannerValidationDict, SetcountryBannerValidationDict, SetcountryBannerValidationBool}) => {
    let UID = device_type + "_" + tab_id + "_" + banner_id;
    const [urlValidation, SeturlValidation] = React.useState('');
    React.useEffect(() => {
    }, [bannerMap]);
    const UploadImage = (field, value) => {
        let dict = Object.assign({}, bannerMap);
        let subDic = dict[selectedTavalue][banner_id];
        dict[selectedTavalue][banner_id][field] = value;
        subDic[field] = value;
        let urlValidationDict = Object.assign({},countryUrlValidationDict);
        if (field === "file") {
            imgPreviewHandler(value, dict);
        } else {
            SetBannerMap(dict);
        }
        if (field === 'url') {
            try {
                let countryService = new CountryService();
                countryService.GetValidURLCheck(value).then((urlCheck) => {
                    if(urlCheck){
                        urlValidationDict[banner_id] = '';
                    }else{
                        urlValidationDict[banner_id] = value;
                    }
                    SetcountryUrlValidationDict(urlValidationDict)
                })
            } catch (err) {
                urlValidationDict[banner_id] = value;
                SetcountryUrlValidationDict(urlValidationDict)
            }
        }
    }
    const DeleteImage = (selectedTavalue, banner_id) => {
        let dict = Object.assign({}, bannerMap);
        dict[selectedTavalue][banner_id]['file'] = "";
        dict[selectedTavalue][banner_id]['url'] = "";
        dict[selectedTavalue][banner_id]['target'] = "";
        dict[selectedTavalue][banner_id]['displayfile'] = "";
        let bannerValidationDict = Object.assign({},countryBannerValidationDict);
        bannerValidationDict[banner_id] = ''
        SetcountryBannerValidationDict(bannerValidationDict)
        let urlValidationDict = Object.assign({},countryUrlValidationDict);
        urlValidationDict[banner_id] = '';
        SetcountryUrlValidationDict(urlValidationDict)
        SetBannerMap(dict);
    }
    const DisplayFileLengthCheck = (selectedValue, bannerId) => {
        try {
            if (bannerMap[selectedValue][bannerId]['displayfile']) {
                if (bannerMap[selectedValue][bannerId]['displayfile'].length > 0) {
                    return true;
                } else {
                    return false
                }
            }
            else {
                return false
            }
        } catch (err) {
            return false;
        }
    }
    const GetCheckingConditionValue = (selectedValue, bannerId) => {
        try {
            if (bannerMap[selectedValue][bannerId]) {
                let keys = Object.keys(bannerMap[selectedValue][bannerId]);
                if (Array.isArray(keys)) {
                    if (keys.length > 0) {
                        if (bannerMap[selectedValue][bannerId]['displayfile']) {
                            if (bannerMap[selectedValue][bannerId]['displayfile'].length > 0) {
                                return true
                            }
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            else {
                return false;
            }
        } catch (err) {
            return false;
        }
        return false;
    }
    // Image Upload Preview 
    const [imgPreview, setImgPreview] = React.useState("");
    const imageSizeValidation = (buffer, banner_id) => {
        let image = new Image();
        let bannerValidationDict = Object.assign({},countryBannerValidationDict);
        image.onload = function () {
            let width = this.width;
            let height = this.height;
            if(device_type === 'mobile'){
                if (!(width === 375 && height === 375)) {
                    SeturltextPopup('image dimesnion is not correct');
                    bannerValidationDict[banner_id] = 'Banner image dimension is not correct'
                    SetcountryBannerValidationBool(true);
                }else{
                    bannerValidationDict[banner_id] = ''
                    SetcountryBannerValidationBool(false);
                }
            } else {
                if (!(width === 1700 && height === 420)) {
                    SeturltextPopup('image dimesnion is not correct');
                    bannerValidationDict[banner_id] = 'Banner image dimension is not correct'
                    SetcountryBannerValidationBool(true);
                }else{
                    bannerValidationDict[banner_id] = ''
                    SetcountryBannerValidationBool(false);
                }
            }
        }
            SetcountryBannerValidationDict(bannerValidationDict)

        image.src = buffer;
    }
    const imgPreviewHandler = (file, dict) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = function () {
                setImgPreview(this.result);
                let countryService = new CountryService();
                dict[selectedTavalue][banner_id]['displayfile'] = this.result;
                imageSizeValidation(this.result,banner_id);
                let stateFileReader = new FileReader();
                stateFileReader.onload = function () {
                    let fileName = 'Country/Banner/'+ (new Date()).getTime().toString()+"-"+file.name;
                    countryService.LoadFile(file, fileName).then((data => {
                        dict[selectedTavalue][banner_id]['file'] = data;
                        SetBannerMap(dict);
                    }))
                }
                stateFileReader.readAsDataURL(file);
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <Box component="div" className={banner_id+' upload_box'}>
                {device_type === 'mobile' ?
                    <div className={banner_id+' image_control'}>
                        {
                            GetCheckingConditionValue(selectedTavalue, banner_id) ?
                                (DisplayFileLengthCheck(selectedTavalue, banner_id) ?
                                    <img className={banner_id}  src={bannerMap[selectedTavalue][banner_id]['displayfile']} alt="imgpreview" width="100%" height="100%" /> :
                                    <img className={banner_id} src={require('../../../public/img/placeholder_4_375x375.png')} alt="imgpreview" width="100%" height="100%" />) :
                                <img  className={banner_id} src={require('../../../public/img/placeholder_4_375x375.png')} alt="imgpreview" width="100%" height="100%" />
                        }
                    </div>
                    : <div className={banner_id+ " image_control"}>
                        {
                            GetCheckingConditionValue(selectedTavalue, banner_id) ?
                                (DisplayFileLengthCheck(selectedTavalue, banner_id) ?
                                    <img className={banner_id} src={bannerMap[selectedTavalue][banner_id]['displayfile']} alt="imgpreview" width="100%" height="100%" /> :
                                    <img className={banner_id} src={require('../../../public/img/placeholder_1700x420.png')} alt="imgpreview" width="100%" height="100%" />) :
                                <img className={banner_id} src={require('../../../public/img/placeholder_1700x420.png')} alt="imgpreview" width="100%" height="100%" />
                        }
                    </div>}
                <div className={banner_id+" link_control"}>
                    <FormControl component="fieldset" className={banner_id}>
                        <FormLabel component="label" className={banner_id} >Target(check for new window):</FormLabel>
                        <FormGroup row={true} className={banner_id}>
                            <Grid container className={banner_id}>
                                <Grid item xs={10} lg={8} className={banner_id}>
                                    <TextField disabled={!GetCheckingConditionValue(selectedTavalue, banner_id)} label="" size="small" variant="outlined" className={banner_id +' link_url'} InputLabelProps={{ shrink: false }}
                                        onChange={(e) =>{ UploadImage('url', e.target.value) } } 
                                        value={GetCheckingConditionValue(selectedTavalue, banner_id) ? bannerMap[selectedTavalue][banner_id]['url'] : ''} />
                                </Grid>
                                <Grid item xs={2} lg={4} className={banner_id}>
                                    <Checkbox className={banner_id+' link_target'} color="primary" 
                                        onChange={(e) => UploadImage('target', e.target.checked)} 
                                        checked={GetCheckingConditionValue(selectedTavalue, banner_id) ? bannerMap[selectedTavalue][banner_id]['target'] : false} />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="upload_control">
                    <input accept="image/*" type="file" id={UID}
                        onChange={(e) => {
                            UploadImage('file', e.target.files[0])
                            e.target.value = null;
                        }
                        }
                    />
                    <label htmlFor={UID}>
                        <Button variant="contained" color="primary" component="div">
                            <PublishIcon /> <Typography variant="caption">Upload</Typography>
                        </Button>
                    </label>
                    <span>
                        {urlValidation}
                    </span>
                    {
                        (GetCheckingConditionValue(selectedTavalue, banner_id)) ?
                            <Button variant="contained" color="secondary" component="div" onClick={(e) => DeleteImage(selectedTavalue, banner_id)}>
                                <Typography variant="caption">Delete</Typography>
                            </Button>
                            : ""
                    }
                </div>
            </Box>
        </>
    )
}

export default UploadBox