import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const CustomCheckbox = withStyles({
    root: {
        color: 'white',
        '& .MuiIconButton-label': {
            border: '1px solid #D0D5DD',
            borderRadius: '4px',
        },
        '&$checked': {
            color: 'white',
            '& .MuiIconButton-label': {
                position: "relative",
                zIndex: 0,
                border: '1px solid #D92D20',
                borderRadius: '4px',
            },
            '& .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: 'absolute',
                backgroundColor: '#D92D20',
                zIndex: -1
            }
        },
        '&.Mui-disabled': {
            color: ' rgba(0, 0, 0, 0)',
            backgroundColor: 'transparent',
            '&$checked': {
                color: 'white',
                '& .MuiIconButton-label': {
                    position: "relative",
                    zIndex: 0,
                    border: '1px solid #D92D20',
                    borderRadius: '4px',
                },
                '& .MuiIconButton-label:after': {
                    content: '""',
                    left: 4,
                    top: 4,
                    height: 15,
                    width: 15,
                    position: 'absolute',
                    backgroundColor: '#D92D20',
                    zIndex: -1
                }
            },
        }
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ContentAudience = (props) => {
    const [professionList, setProfessionList] = React.useState(["Physician Non Speaker", "HCP Speaker", "Pharmacist", "Nurse", "Others"]);
    const handlerCheckbox = (event, affiliate_id) => {
        if (event.target.checked) {
            props.SetAudience([...props.audience, { country_code: affiliate_id, value: event.target.value }])
        } else {
            var array = [...props.audience];
            const filteredArray = array.filter((item) => {
                if (item.country_code === affiliate_id && item.value === event.target.value) {
                    return false;
                }
                return true;
            });
            props.SetAudience(filteredArray);
        }
    }
    const isChecked = (affiliate_id, value) => {
        let check = Array.isArray(props.audience) ? props.audience : props.audience ? JSON.parse(props.audience) : []
        if (typeof check == 'string') {
            check = JSON.parse(check)
        }
        return check.some(el => el.country_code === affiliate_id && el.value === value)
    }
    return (
        <div className='restriction-section'>
            <Grid container>
                <Grid className='content-field' item md={12} sm={12}>
                    <FormControl component='fieldset'>
                        <label className='input-label'>
                            Restricted to audience (Public by default)
                        </label>
                    </FormControl>
                </Grid>
            </Grid>
            <div className='box-section'>
                {props.affiliates.length > 0 ? props.affiliates.map((item, item_index) => (
                    <>
                        <div className='box-header'>{item.country_name}</div>
                        <div className='box-body'>
                            {professionList.length > 0 ? professionList.map((audience, index) => (
                                <FormControlLabel disabled={!props.enableFields}
                                    control={<CustomCheckbox
                                        className='checkbox'
                                        checked={isChecked(item.country_code, audience)}
                                        onChange={(event) => { handlerCheckbox(event, item.country_code); }}
                                        value={audience}
                                    // name={'one'} 
                                    />}
                                    label={audience}
                                />
                            )) : ""}
                        </div>
                    </>
                )) : ""}
                {/* <div className='box-header'>Country Name</div> */}
            </div>
        </div>
    )
}
export default ContentAudience