import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ModalDialog = (props) => {
    const speakerData = props.speakerData;

    const handleClose = (event, reason) => {
        props.setPopupState(false);
        setSelectedSpeaker({});
    };
    const handleBackdropClick = (event) => {
        event.stopPropagation();
        props.setPopupState(false);
        return false;
    };

    const [selectedSpeaker, setSelectedSpeaker] = React.useState({});
    const handleOk = (event) => {
        if(selectedSpeaker.speaker_id) {
            const row = {
                id: selectedSpeaker.speaker_id,
                name: selectedSpeaker.name,
                title: selectedSpeaker.name,
                description: selectedSpeaker.curriculum
            }
            props.setPopupState(false);
            setSelectedSpeaker({});
            let newArr = [];
            if (props.lillyplayspeakers) {
                newArr = [...props.lillyplayspeakers]
            }
            newArr.push(row)
            props.SetLillyplaySpeakers(newArr);
        } else {
            alert("Please select any speaker first.")
        }
    };

    return (
        <Dialog open={props.popupState} scroll='body' maxWidth={false} TransitionComponent={Transition} onClose={handleClose} onBackdropClick={handleBackdropClick} disableEscapeKeyDown className='speaker-modal'>
            <DialogTitle className='modal-title'>
                {props.title}
                <button className='closeModal' onClick={handleClose}></button>
            </DialogTitle>
            <DialogContent className='modal-body'>
                <FormControl component="fieldset" className='formControl'>
                    <Autocomplete
                        options={speakerData}
                        getOptionLabel={(option) => option.name}
                        value={selectedSpeaker}
                        onChange={(e, value) => {
                            setSelectedSpeaker(value);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Search by name of speaker" name="speaker" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions className='modal-footer'>
                <button type="button" className="modalBtn" onClick={handleClose}>Cancel</button>
                <button type="button" className="modalBtn" onClick={handleOk}>OK</button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalDialog