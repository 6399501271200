/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import Breadcrumb from '../../../layout/breadcrumb';
import Modal from '@material-ui/core/Modal';
import CreateUser from '../user-create/user-create';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import editImg from '../../../public/img/new-img/GreyEdit@3x.png';
import editImgBlue from '../../../public/img/new-img/BlueEdit@3x.png';
import deleteImg from '../../../public/img/CloseGrey@3x.png';
import deleteImgBlue from '../../../public/img/new-img/RedClose@3x.png';
import { userManagementService } from '../Services/user-management-service';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import '../user-management/user-management.css';
import { ListboxComponent, filterOptions } from '../../shared/SearchOptimize';

/**Function defines all attribute required on the table */
function createData(id, firstname, lastname, title, country, roles, action, updated_on, user_id, user_name, lilly_id, okta_id) {
  return { id, firstname, lastname, title, country, roles, action, updated_on, user_id, user_name, lilly_id, okta_id };
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  /**First page click event on pagination */
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  /**Back click event on pagination */
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  /**Handle next click event on pagination */
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  /**Last page click event on pagination */
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>

    </div>
  );
}

/**Decending comparator on header */
function descendingComparator(a, b, orderBy) {
  // if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string' && a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent' }) < 0) {
  //   return a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent', numeric: true });
  // }
  // if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string' && a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent' }) > 0) {
  //   return a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent', numeric: true });
  // }
  // return 0;
}
/**Fetches comparator of order on header */
function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**Sort function on header */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**Variable that contains all header details  */
const headCells = [
  { id: 'user_id', numeric: true, disablePadding: false, label: 'ID', sorting: true },
  { id: 'firstname', numeric: true, disablePadding: false, label: 'First Name', sorting: true },
  { id: 'lastname', numeric: true, disablePadding: false, label: 'Last Name', sorting: true },
  { id: 'title', numeric: true, disablePadding: false, label: 'Title', sorting: true },
  { id: 'country', numeric: true, disablePadding: false, label: 'Country', sorting: false },
  { id: 'roles', numeric: true, disablePadding: false, label: 'Roles', sorting: false },
  { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

/**Function provides shorting of header section  */
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              class={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  videobuttonedit: {
    backgroundImage: `url(${editImg})`,
    height: '20px',
    width: '20px',
    border: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0 1px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${editImgBlue})`
    }
  },
  videobuttonremove: {
    backgroundImage: `url(${deleteImg})`,
    height: '20px',
    width: '20px',
    border: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0px 0px',
    backgroundColor: 'transparent',
    marginLeft: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${deleteImgBlue})`
    }
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [createUserPopup, SetCreateUserPopup] = React.useState(false);
  const [rows, SetRows] = React.useState([]);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false)
  let UserManagementService = new userManagementService();
  const [rolesDict, SetRolesDict] = React.useState({});
  const [totaldata, Settotaldata] = React.useState([]);
  const [totallength, Settotallength] = React.useState('');

  const [limit, setLimit] = React.useState(50);
  const [offSet, setoffSet] = React.useState(0);
  const [deleteRow, setdeleteRow] = React.useState(0);
  const [searchRow, setsearchRow] = React.useState([]);
  const [activeSearch, setactiveSearch] = React.useState(false);
  const [sortByDynamic, setsortByDynamic] = React.useState(['updated_on', 'desc']);

  /**Function to handle shorting as per order required  */

  const handleRequestSort = (event, property) => {
    // alert(property)
    if (property === 'user_id' || property === 'firstname' || property === 'lastname' || property === 'title') {
      setPage(0)
      const isAsc = orderBy === property && order === 'asc';

      setOrder(isAsc ? 'desc' : 'asc');

      let sort = property
      let orderby = isAsc ? 'desc' : 'asc'
      setOrderBy(orderby)
      // alert(isAsc ? 'desc' : 'asc')
      let sortThing = []
      if (property == 'user_id') {
        sortThing = ['lilly_id', isAsc ? 'desc' : 'asc']
      } else if (property == 'firstname') {
        sortThing = ['first_name', isAsc ? 'desc' : 'asc']
      } else if (property == 'lastname') {
        sortThing = ['last_name', isAsc ? 'desc' : 'asc']
      } else if (property == 'title') {
        sortThing = ['tite', isAsc ? 'desc' : 'asc']
      }

      setsortByDynamic(sortThing)
      GetAllRolesFunc({ affiliate_code: selectedCountry, role_id: selectedRole, searchKeyword: searchTerm, sorting: sortThing }, 0)
      setOrderBy(property);

    }


  };
  const CreateViewListFromUserData = (users, userRolesState) => {
    let list = []
    if (users.length > 0) {
      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        let roleNames = '';
        let allRole = [];
        if (user.role_ids == null) {
          user.role_ids = [2];
        }
        for (let k = 0; k < user.role_ids.length; k++) {
          let tepROleName = userRolesState[user.role_ids[k]];
          if (tepROleName != null) {
            roleNames = roleNames + ',' + tepROleName.role_name;
            allRole.push(tepROleName.role_name)
          }
        }
        let value = createData(user.lilly_id, user.first_name, user.last_name, user.title,
          Array.isArray(user.country_code) ? user.country_code.toString() : '',
          allRole.toLocaleString(), '', new Date(user.updated_on), user.id, user.user_name, user.lilly_id, user.octa_id);
        
        list.push(value);
      }
    }
    return list;
  }
  /**Function to fetch all users data from server */
  function CreateDataFromServer(userRolesState, params, start) {
    UserManagementService.GetAllUsers(params, start, limit).then((data) => {
      setFetchDataStatus(true)

      let users = data.data;
      let list = []
      if (users.length > 0) {
        for (let i = 0; i < users.length; i++) {
          let user = users[i];
          let roleNames = '';
          let allRole = [];
          if (user.role_ids == null) {
            user.role_ids = [2];
          }
          for (let k = 0; k < user.role_ids.length; k++) {
            let tepROleName = userRolesState[user.role_ids[k]];
            if (tepROleName != null) {
              roleNames = roleNames + ',' + tepROleName.role_name;
              allRole.push(tepROleName.role_name)
            }
          }
          if ((user.okta_id && user.okta_id != '') || (user.lilly_id && user.lilly_id != '')) {
            let value = createData(user.lilly_id, user.first_name, user.last_name, user.tite, Array.isArray(user.country_code) ? user.country_code.toString() : '',
              allRole.toLocaleString(), '', new Date(user.updated_on), user.id, user.user_name, user.lilly_id, user.okta_id);
            
            list.push(value);
          }

        }
      }
      // list.sort((a, b) => b.user_id - a.user_id);

      let newarr = rows
      list.sort((a, b) => ((b.updated_on ? new Date(b.updated_on) : new Date(8640000000000000)) - (a.updated_on ? new Date(a.updated_on) : new Date(8640000000000000))));
      newarr = newarr.concat(list)
      SetRows(newarr);
      if (start == 0) {
        SetRows(list);

      }
      setoffSet(start)
    }).catch((err) => {})
  }

  function GetAllUsersFunc() {
    UserManagementService.GetAllUsers('').then((data) => {
      setUserData(data.data);
    }).catch((err) => {});
  }
  function GetAllUsersFuncCount(params) {
    UserManagementService.GetUsersByQueryCount(params).then((data) => {
      
      Settotaldata(data.data)
      setUserData(data.data);

      Settotallength(data.data.length)
      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].first_name && data.data[i].first_name != '') {
          arr.push({ name: data.data[i].first_name + " " + data.data[i].last_name })

        }
      }
      setsearchRow(arr)

    }).catch((err) => {});
  }

  function GetAllAffiliatesFunc() {
    UserManagementService.GetAllAffiliates().then((data) => {
      let cdata = data.data.sort((a, b) => a.country_name.localeCompare(b.country_name))
      setCountries(cdata);
    }).catch((err) => {});
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    GetAllRolesFunc({ sorting: sortByDynamic }, 0);
    //GetAllUsersFunc();
    GetAllAffiliatesFunc();
    GetAllUsersFuncCount({})
  }, []);

  /**Function to fetch all roles created for users */
  function GetAllRolesFunc(params = {}, offset) {
    UserManagementService.GetAllRoles().then((data) => {
      let roles = data.data;
      setRoles(roles);
      let dict = {};
      if (roles.length > 0) {
        for (let i = 0; i < roles.length; i++) {
          dict[roles[i].role_id] = roles[i];
        }
        SetRolesDict(dict);
        CreateDataFromServer(dict, params, offset);
      }
    }).catch((err) => {});
  }

  /**Function to open create user modal */
  const CreateUserButton = () => {
    SetCreateUserPopup(true);
  }

  /**Function invokes click event on table row */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  /**Function changes the page number on pagination*/
  const handleChangePage = (event, newPage) => {
    GetAllRolesFunc({ affiliate_code: selectedCountry, role_id: selectedRole, searchKeyword: searchTerm, sorting: sortByDynamic }, offSet + 50)
    setPage(newPage);
  };

  /**Function to handle reload button*/
  const handleReloadBtn = () => {
    setPage(0);
    setSearchTerm('');
    setSelectedCountry("");
    setSelectedRole("");
    GetAllRolesFunc({ sorting: sortByDynamic }, 0);
    GetAllUsersFuncCount({ sorting: sortByDynamic })
  };

  /**Function to handle filter button*/
  const handleFilterBtn = () => {
    setOpenFilterModal(true)
  };
  const handleFilterClose = () => {
    setOpenFilterModal(false)
    setSelectedCountry("");
    setSelectedRole("");
  };
  const handleFilterReset = () => {
    setSelectedCountry("");
    setSelectedRole("");
    // GetAllRolesFunc({}, 0);
    // GetAllUsersFuncCount({})
    // setactiveSearch(false)
    // setPage(0);


  }
  const handleFilterApply = () => {
    setPage(0);

    GetAllRolesFunc({ affiliate_code: selectedCountry, role_id: selectedRole, sorting: sortByDynamic }, 0);
    GetAllUsersFuncCount({ affiliate_code: selectedCountry, role_id: selectedRole, sorting: sortByDynamic })
    setactiveSearch(true)
    setOpenFilterModal(false)
  }


  const [open, setOpen] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [newUserTerm, setNewUserTerm] = React.useState('');

  const handleSearchTerm = (e, value) => {
    if (e.keyCode == 13) {
      let term = e.target.value;
      if (value != null) {
        term = value.user_name;
      }
      setSearchTerm(term);
      setoffSet(0)

      GetAllRolesFunc({ searchKeyword: term, sorting: sortByDynamic }, 0);
      GetAllUsersFuncCount({ searchKeyword: term })

      setPage(0);
      setactiveSearch(true)

      // alert("o")
    }
  }

  const searchItem = event => {
    setoffSet(0)

    GetAllRolesFunc({ searchKeyword: searchTerm, sorting: sortByDynamic }, 0);
    GetAllUsersFuncCount({ searchKeyword: searchTerm })

    setPage(0);
    setactiveSearch(false)


  };

  /**Function changes page on rows per page*/
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**Variable shows selected index for page*/
  const isSelected = (name) => selected.indexOf(name) !== -1;

  /**Empty rows details*/
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  /**Breadcrumb for user management*/
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'User Management', path: '/user-management' }
  ];

  const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
  const [rowID, setrowID] = React.useState(0);
  const [displayId, SetdisplayId] = React.useState('');
  const [userName, SetuserName] = React.useState('');

  const handleDelete = () => {
    UserManagementService.DeleteUser(rowID).then((data) => {
      GetAllRolesFunc({ sorting: sortByDynamic }, 0);

      rows.splice(deleteRow, 1);
      setopenDelConfirmModal(false);
    }).catch((err) => {});
    GetAllUsersFuncCount()

  }
  const openDelConfirm = (id, index, name) => {
    // const [deleteRow, setdeleteRow] = React.useState(0);
    setdeleteRow(index)
    setrowID(id);
    SetuserName(name.firstname == '' || name.firstname == null || name.firstname == 'null' ? id : name.firstname + " " + name.lastname)
    setopenDelConfirmModal(true);
  };
  const handleDelConfirmClose = () => {
    setopenDelConfirmModal(false);
  };

  const [validationPopup, setvalidationPopup] = React.useState(false);
  const [validationName, setvalidationName] = React.useState('User Name');
  const [savemessagePopup, handlesavemessagePopup] = React.useState(false);


  const handleValidationPopup = () => {
    setvalidationPopup(false);
  }
  React.useEffect(() => {
    if (props.updateBool) {
      handlesavemessagePopup(true)
    }
    props.setupdateBool(false)
  }, [props.updateBool]);


  return (
    <>
      <Modal open={savemessagePopup} onClose={() => handlesavemessagePopup(false)}>
        <Grid className='paper modalstyle uservalidationPopup'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handlesavemessagePopup(false)}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="success">Your settings have been successfully saved!</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer ">
            <button type="button" onClick={() => handlesavemessagePopup(false)} className="modalBtn validationpopupFooter">Close</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={validationPopup} onClose={handleValidationPopup}>
        <Grid className='paper modalstyle uservalidationPopup'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleValidationPopup()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">{validationName} cannot be left empty.</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer ">
            <button type="button" onClick={() => handleValidationPopup(false)} className="modalBtn validationpopupFooter">Close</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={createUserPopup}>
        <CreateUser validation={setvalidationPopup} setValiadationName={setvalidationName} closePopUp={SetCreateUserPopup} reloadData={handleReloadBtn} />
      </Modal>
      <Modal open={openFilterModal} onClose={handleFilterClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            Choose Filter
            <button className='closeModal' onClick={() => handleFilterClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <form className='filter_form'>
              <FormControl variant="outlined" className='formControl' size="small">
                <InputLabel id="demo-simple-select-outlined-label">Select Country</InputLabel>
                <Select label="Country" value={selectedCountry} name="country" onChange={(e) => { setSelectedCountry(e.target.value) }}>
                  {countries.map((country, i) => <MenuItem key={i} value={country.country_code}>{country.country_name}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className='formControl' size="small">
                <InputLabel id="demo-simple-select-outlined-label">Select Role</InputLabel>
                <Select label="Role" name="role" value={selectedRole} onChange={(e) => { setSelectedRole(e.target.value) }}>
                  {roles.map((role, i) => <MenuItem key={i} value={role.role_id}>{role.role_name}</MenuItem>)}
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid className="modal1FoBtn modal-footer text-right">
            <button type="button" onClick={() => handleFilterClose()} className="modalBtn closeBtn">Close</button>
            <button type="button" onClick={() => handleFilterReset()} className="modalBtn resetBtn">Reset Filter</button>
            <button type="button" onClick={() => handleFilterApply()} className="modalBtn applyBtn">Apply Filter</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">User <b>{displayId}</b> will be deleted, are you sure?</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
            <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
          </Grid>
        </Grid>
      </Modal>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={6} md={6} sm={5} xs={12}>
                <label className="label-hd">

                  User Management <span className='user'> {totallength} users</span>
                </label>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={12}>
                <div className="filter_icons text-right">
                  <button className='singleBtn' onClick={handleFilterBtn}><FilterListIcon /></button>
                  <button className='singleBtn' onClick={handleReloadBtn}><AutorenewIcon /></button>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={12}>
                <div className='search_bar searchBx'>
                  <Autocomplete
                    // ListboxComponent={ListboxComponent}
                    filterOptions={filterOptions}
                    open={open}
                    onOpen={() => { if (searchTerm) { setOpen(true); } }}
                    onClose={() => setOpen(false)}
                    inputValue={searchTerm}
                    onInputChange={(e, value) => {
                      setSearchTerm(value);
                      if (value == null) {
                        setFilteredUsers([]);
                      } else {
                        let newUserData = searchRow.filter(function (item) {
                          if (item.name != null) {
                            let username = item.name.toLowerCase();
                            return username.includes(value.toLowerCase());
                          } else {
                            item.user_name = ""
                            return false;
                          }
                        });
                        setFilteredUsers(newUserData);
                        // SetRows(CreateViewListFromUserData(newUserData, rolesDict));
                        const found = searchRow.some(el =>
                          el.name.toLowerCase() === value.toLowerCase()
                        );
                        if (!found) { setNewUserTerm(value); } else { setNewUserTerm(''); }
                      }
                      if (!value) { setOpen(false); }
                    }}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onKeyDown={handleSearchTerm}
                    freeSolo
                    options={filteredUsers.length > 0 || newUserTerm != '' ? filteredUsers : searchRow}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Search by name" name="search_name" size="small"
                        InputLabelProps={{ shrink: false }}
                        {...params.InputProps.disableUnderline = true}
                        {...params.InputProps.endAdornment = <InputAdornment onClick={() => searchItem()} position="end"><IconButton className="mp0"><SearchIcon /></IconButton></InputAdornment>}
                      />}
                  />
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={3} xs={12}>
                <div className="action_buttons text-right">
                  <Button className='createBtn' onClick={() => CreateUserButton()}>Create New User</Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}>
                        <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                          {row.okta_id ? row.okta_id : row.lilly_id}
                        </TableCell>
                        <TableCell align="center">{row.firstname}</TableCell>
                        <TableCell align="center">{row.lastname}</TableCell>
                        <TableCell align="center">{row.title}</TableCell>
                        <TableCell align="center" className="breakingbed">{row.country}</TableCell>
                        <TableCell align="center">{row.roles}</TableCell>
                        <TableCell align="center">
                          <Link to={"/user-profile/" + row.user_id}>
                            <button type="button" className={classes.videobuttonedit}></button>
                          </Link>
                          <button onClick={() => { openDelConfirm(row.user_id, index, row); SetdisplayId(row.okta_id ? row.okta_id : row.lilly_id) }} type="button" className={classes.videobuttonremove}></button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    {rows.length <= 0 ?
                      <TableCell align="center" colSpan={7}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                      <TableCell colSpan={7} />
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totallength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </div>
    </>
  );
}
