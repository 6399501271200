import React from 'react';
import { Grid, FormControlLabel } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { userCreateService } from '../Services/user-create-service';


const useStyles = makeStyles((theme) => ({
    ulist: {
        listStyle: 'none',
        paddingLeft: '0px',
        marginLeft: '20px',
        width: '100%'

    },
    ulistInner: {
        display: 'inline-block',
        width: '25%'
    }
}));

const UserCreate = (props) => {
    const classes = useStyles();
    const [userId, SetUserId] = React.useState('');
    const [roles, SetRoles] = React.useState([]);
    const [selectedRoles, SetSelectedROles] = React.useState([]);
    let Usercreateservice = new userCreateService();

    React.useEffect(() => {
        /**Function will fetch all the roles that could be associated with user */
        Usercreateservice.GetRolesByQuery().then((data) => {
            SetRoles(data.data);
        }).catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**Function submits all the data for the created user */
    const handleSubmit = () => {        
        let model = {
            affiliate_code: [],
            octa_id: '',
            customer_id: '',
            lilly_id: userId,
            user_name: '',
            role_ids: selectedRoles,
            user_email: userId,
            global_id: 0,
            specialty: {},
            therapeutic_area: [],
            profession: {},
            city: '',
            avatar_attachment_id: '',
            identifiers: '',
            status_flag: 'Active',
            created_on: new Date(),
            created_by_user_id: 0,
            updated_on: new Date(),
            updated_by_user_id: 0
        }

        //check user name is not empty validation
        if(model.lilly_id === ""){
            props.validation(true);
            props.setValiadationName("User Name")
            return;
        }
        else if(model.role_ids.length === 0){
            props.validation(true);
            props.setValiadationName("Role")
            return;
        }
        Usercreateservice.CreateNewUser(model).then((data) => {
            props.closePopUp(false);
            props.reloadData();
        }).catch((err) =>{})

        
    }

    /**Function Alter Roles */
 /**Function Alter Roles */
const AlterRolesState = (role) => {
    if(selectedRoles.includes(role)){
        SetSelectedROles(selectedRoles.filter(item => item !== role))
    }else{
        SetSelectedROles([...selectedRoles, role]);
        
    }
}

    return (roles.length > 0 ?
        <>
            <Grid className='paper modalstyle'>
                <Grid className="modal-title">
                    Create New User:
                  <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </Grid>
                {/**Create User Profile Section */}
                <Grid class="modal-body">
                    <label className='modalLabel'>Lilly System Id :</label>
                    <TextField variant="outlined" className='modalTxt' onChange={(event) => {
                        SetUserId(event.target.value)
                    }} />
                    <label className='modalLabel'>Role</label>
                    <ul className={classes.ulist}>
                        {roles.length > 0 ? roles.slice(1,4).map((role) => {
                            return <li className={classes.ulistInner + ' ulistInner'}>
                                <FormControlLabel control={<Checkbox onChange={(event) => {
                                    AlterRolesState(role.role_id);
                                }} />} label={role.role_name} />
                            </li>
                        }) : ('')
                        }
                    </ul>
                </Grid>
                <Grid className="modal1FoBtn modal-footer">
                    <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
                    <button type="button" className="modalBtn" onClick={() => { 
                        handleSubmit(); 
                        // props.closePopUp(false); 
                        }}>OK</button>
                </Grid>
                {/**End Create User Profile Section */}
            </Grid>
        </> : (''));
}
export default UserCreate;






