/* eslint-disable linebreak-style */
import React from 'react';
import AppMain from '../layout/layout';
import UserRoute from '../modules/user/user-route';
import SpeakerRoute from '../modules/content/speaker/speaker-route';
import EventRoute from '../modules/event/event-route';
import ContentRoute from '../modules/content/content-route';
import PlaylistRoute from '../modules/playlist/playlist-route';
import CoutryRoute from '../modules/country/country-route';
import CategoryRoute from '../modules/category/category-route';
import HomeRoute from '../modules/home/home-route';
import ContentWatchRoute from '../modules/content-watch/content-watch-route';
import ContentShowRoute from '../modules/content-show/content-show-route';
import VideoRoute from '../modules/Video/video-route';
import VideoPlayer from '../modules/video-player/video-player';
import LoginRoute from '../modules/login/login-route';
import LandingRoute from '../modules/landing/landingRoute';
import ForbiddenRoute from '../modules/forbidden/forbidden-route';
import PrescribeInfoRoute from '../modules/prescribing-Information/prescribing-Information-route';
import StaticPagesRoute from '../modules/static-pages/static-pages-route';
import FederateRedirectRoute from '../modules/FederaetRedirect/FederateRoute';
import AuthVerifyComponent from './AuthVerify';
import '../../global.css';
import useRouteChangeHook from './RouteChangeHook';
import translationEn from '../language-translation/locales/en-US/translation.json';
import TranslationRoute from '../modules/translation/translation-route';
import EmbedRoute from '../modules/Embed/embed-route';
import QuizRoute from '../modules/quiz/quiz-route';
// import { Provider  } from 'react-redux';
// import store from '../Session/store';
import '../Session/Interceptor';
import {GoogleAnalyticsService} from '../GA/GoogleAnalyticsService';
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import {AuthVerifyService} from '../modules/login/Services/AuthVerifyService';
import { useSelector  } from 'react-redux';


// Importing Components
import EmbedWatch from '../modules/Embed/embed-watch';
import Landing from '../modules/landing/landing';
import FederateRedirect from '../modules/FederaetRedirect/FederateRedirect';
import ProtectedRoute from './protectedRoutes';
import Home from '../modules/home/home';
import Search from '../modules/home/Components/Search';
import SpeakerDetails from '../modules/home/Components/speaker-details';
import ShowMore from '../modules/home/Components/ShowMore';
import UserProfile from '../modules/user/user-profile/user-profile';
import UserManagement from '../modules/user/user-management/user-management'
import Forbidden from '../modules/forbidden/forbidden';
import IntUserProfile from '../modules/user/internal-user-profile/internal-user-profile';
import SpeakerManagement from '../modules/content/speaker/speaker-management/speaker-management';
import ContentManagement from '../modules/content/content-management/content-management';
import ContentUpdateStepper from '../modules/content/content-update/content-update-stepper';
import PlaylistManagement from '../modules/playlist/playlist-management/playlist-management';
import PlaylistUpdate from '../modules/playlist/playlist-update/playlist-update';
import CountryManagement from '../modules/country/country-management/country-management';
import CountryProfile from '../modules/country/country-profile/country-profile';
import CategoryManagement from '../modules/category/category-management/category-management';
import ContentWatchOldNonHCPDirect from '../modules/content-watch/content-watch-simple-old';
import ContentWatchOld from '../modules/content-watch/content-watch-old';
import ContentWatch from '../modules/content-watch/content-watch';
import Video from '../modules/Video/Video';
import ContentShow from '../modules/content-show/content-show';
import EventManagement from '../modules/event/event-management/event-management';
import LiveEvent from '../modules/event/live-event/live-event';
import EventEdit from '../modules/event/event-edit/event-edit';
import TranslationManagement from '../modules/translation/translation-management/translation-management';
import QuizManagement from '../modules/quiz/quiz-management/quiz-management';
import QuizDetail from '../modules/quiz/quiz-detail/quiz-detail';
import PrescirbingInfo from '../modules/prescribing-Information/prescribing-Information';
import AudienceAge from '../modules/static-pages/AudienceAge';
import ContactUs from '../modules/static-pages/ContactUs';
import Login from '../modules/login/login';
import Logout from '../modules/login/logout';

const MainRoutes = () => {
  localStorage.setItem('userID', 1);
  const navigate = useNavigate();
  let gaSerVice = new GoogleAnalyticsService();
  
  const [filter, SetFilterName] = React.useState('All');
  const [filterId, SetFilterId] = React.useState('');
  const [play, Setplay] = React.useState({ state: false, startTime: '', kalturaID: '' });
  const [isPlaylist, SetIsPlaylist] = React.useState(false);
  const [search, SetSearchName] = React.useState('');
  const [translations, SetTranslation] = React.useState(translationEn);
  const [country, SelectedCountry] = React.useState(localStorage.getItem('lang'));
  const [isRoleHCP, setIsRoleHCP] = React.useState(false);
  const [isRoleInternal, setIsRoleInternal] = React.useState(false);
  const [isRolePublisher, setIsRolePublisher] = React.useState(false);
  const [isRoleAdmin, setIsRoleAdmin] = React.useState(false);
  const [mainTranslation, setmainTranslation] = React.useState({});
  const [updateBool, setupdateBool] = React.useState(false);
  const [countryCode,setCountryCode] = React.useState(0);
  const [hcpId, setHcpId] = React.useState('NA');
  const [areRolesSet, SetAreRolesSet] = React.useState(false);
  const [openLibraryModal, SetopenLibraryModal] = React.useState(false);
  const [libraryData, SetlibraryData] = React.useState([]);
  const [selectedLibrary, SetselectedLibrary] = React.useState('');
  const [selectedCountry, SetselectedCountry] = React.useState('');
  const [selectedLibraryid, SetselectedLibraryid] = React.useState('');
  const customPathHook = useRouteChangeHook();
  const [previousRoute, SetPreviousRoute] = React.useState('');
  const [mainPlayerId, SetMainPlayerId] = React.useState('');
  const [miniPlayerId, SetMiniPlayerId] = React.useState('');
  const [contentId, SetContentId] = React.useState('');
  const [positionMainPlayer, SetPositionMainPlayer] = React.useState(0);
  const [renderCheckMiniPlayer, SetRenderCheckMiniPlayer] = React.useState(false);
  const [playerShow, SetPlayerShow] = React.useState(false);
  const [allThArea, SetallThArea] = React.useState([]);
  const [allcategory, Setallcategory] = React.useState([]);
  const[autoPlay, SetAutoPlay] = React.useState(false);
  const[access_token, Setaccess_token] = React.useState("");
  const [durationUrlRead , SetDurationUrlRead] = React.useState(false);
  const [lastContentWatchURL, SetlastContentWatchURL] = React.useState('');
  const[virtualPageViewTriggerCheck, SetvirtualPageViewTriggerCheck] = React.useState(false);
  const [percentageElapsed, SetPercentageElapsed] = React.useState(0);
  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [quizMandatoryStatus, SetQuizMandatoryStatus] = React.useState(false);

  React.useEffect(()=> {
    let virtualPageViewRows = window.dataLayer.filter(v=> { if(v){ return v.event == 'virtualPageView'} });
    if(Array.isArray(virtualPageViewRows)){
      if(virtualPageViewRows.length==0){
        let gaService = new GoogleAnalyticsService();
        let dataLayerPushModel = gaService.CreateVirtualPageViewEventModel();
        gaService.PushObjectInDatalayer(dataLayerPushModel);
      }
    }
    
  }, []);
  React.useEffect(()=> {
    async function checkToken() {
      let _authVerifyService = new AuthVerifyService();
      //await _authVerifyService.checkAuthTokenUser(navigate);
    }
    checkToken();
  }, [navigate]);

  React.useEffect(()=> {
  }, [positionMainPlayer]);

  React.useEffect(() => {
    SetTranslation(mainTranslation);
  }, [country,filterId,mainTranslation]);

  React.useEffect(()=> {
    if(customPathHook.includes('content-watch')){
      if(document.getElementById('miniPlayerIdOuter')){
        document.getElementById('miniPlayerIdOuter').style.display = "none";
        SetPlayerShow(false);
      }
      if(lastContentWatchURL != customPathHook){
        SetPositionMainPlayer(0);
      }
      SetlastContentWatchURL(customPathHook);
     
    } else {
      if(!renderCheckMiniPlayer){
        SetRenderCheckMiniPlayer(true);
      }  
    }
    if(previousRoute.includes('content-watch')){
      gaSerVice.PushSingleVariables('videoCurrentTime', positionMainPlayer, positionMainPlayer);
      if(!customPathHook.includes('content-watch')){
        SetPlayerShow(true);
        SetRenderCheckMiniPlayer(true);
      }
      
    }
    if(previousRoute!= customPathHook){
      let gaService = new GoogleAnalyticsService();
         let dataLayerPushModel = gaService.CreateVirtualPageViewEventModel();
         gaService.PushObjectInDatalayer(dataLayerPushModel);
   }else{
     if(customPathHook.includes('/home') || customPathHook.includes('content-watch') || window.location.pathname.includes('home') || window.location.pathname.includes('content-watch')){
       let gaService = new GoogleAnalyticsService();
         let dataLayerPushModel = gaService.CreateVirtualPageViewEventModel();
         gaService.PushObjectInDatalayer(dataLayerPushModel);
     }
   }
    if(localStorage.getItem("jwtToken") === null) {
      SetPlayerShow(false);
    }
    if(previousRoute.includes('search') && customPathHook.includes('content-watch')){
      let gaService = new GoogleAnalyticsService();
      gaSerVice.NavigatedToVideoFromSearchPage(filter);
    }
    SetPreviousRoute(customPathHook);
    SetDurationUrlRead(true);

    Setaccess_token(localStorage.getItem('access_token'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customPathHook]);
  
  return (
    <>
      <React.Suspense fallback={<loaderNewGif />}>
        {
          // <Provider store={store} >

            <AppMain Setallcategory={Setallcategory} allcategory={allcategory} filterId={filterId} SetallThArea={SetallThArea} Setplay={Setplay}  SetPlayerShow={SetPlayerShow} SetselectedLibraryid={SetselectedLibraryid} selectedLibraryid={selectedLibraryid} SetselectedCountry={SetselectedCountry} selectedCountry={selectedCountry} selectedLibrary={selectedLibrary} SetselectedLibrary={SetselectedLibrary} libraryData={libraryData} SetlibraryData={SetlibraryData}  openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} translations={translations} SetFilterId={SetFilterId} filter={filter} setFilter={SetFilterName} search={search} setSearch={SetSearchName} pathname={window.location.pathname} setIsRoleHCP={setIsRoleHCP} setIsRoleAdmin={setIsRoleAdmin} setIsRoleInternal={setIsRoleInternal} setIsRolePublisher={setIsRolePublisher} isRoleAdmin={isRoleAdmin} isRoleHCP={isRoleHCP} isRoleInternal={isRoleInternal} isRolePublisher={isRolePublisher} country={country} setmainTranslation={setmainTranslation} SetAreRolesSet={SetAreRolesSet}>
              

            <Routes>
            {/* 1. EmbedRoute Migration */}
              <Route path="/embed" element={<EmbedWatch />}></Route>

            {/* 2. LandingRoute Migration */}
              <Route exact path="/landing/:country" element={<Landing />}/>

            {/* 3. FederateRedirectRoute Migration */}
              <Route path="/login/startlogin" element={<FederateRedirect />}></Route>

            {/* 4. HomeRoute Migration */}
            
              <Route exact element={<ProtectedRoute />}>

                <Route exact path="/home"  element={<Home SetselectedLibraryid={SetselectedLibraryid} 
                selectedLibraryid={selectedLibraryid} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                  filterId={filterId} filter={filter} 
                country={country} translations={translations} selectorValueSession={selectorValue}
                search={search} SetSearchName={SetSearchName} setFilter={SetFilterName}  />} />
                <Route  exact path="/search" element={<Search allThArea={allThArea} translations={translations}  filterId={filterId}  setFilter={SetFilterName} search={search} selectedCountry={selectedCountry} selectedLibraryid={selectedLibraryid} SetSearchName={SetSearchName} selectorValueSession={selectorValue}/>} />
                <Route exact path="/search/:term" element={<Search allThArea={allThArea} translations={translations}  setFilter={SetFilterName} filterId={filterId} selectedCountry={selectedCountry} selectedLibraryid={selectedLibraryid} search={search} SetSearchName={SetSearchName} selectorValueSession={selectorValue}/>} />
                <Route exact path="/speaker-detail/:term" element={<SpeakerDetails allThArea={allThArea} translations={translations}  setFilter={SetFilterName} filterId={filterId} selectedCountry={selectedCountry} selectedLibraryid={selectedLibraryid} search={search} SetSearchName={SetSearchName} selectorValueSession={selectorValue}/>} />
                <Route exact path="/search/category_id=:term" element={<Search allThArea={allThArea} translations={translations}  filterId={filterId} setFilter={SetFilterName} search={search} SetSearchName={SetSearchName} selectorValueSession={selectorValue} selectedCountry={selectedCountry} selectedLibraryid={selectedLibraryid}/>} />
                <Route exact path="/home/favorite" element={<ShowMore selectedLibraryid={selectedLibraryid} translations={translations}  filterId={filterId} setFilter={SetFilterName} title='My Favorites' type="favorite" filter={filter} selectorValueSession={selectorValue} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                  
                country={country} 
                search={search} SetSearchName={SetSearchName}  />} />
                <Route exact path="/home/popularspeaker" element={<ShowMore selectedLibraryid={selectedLibraryid} translations={translations}  filterId={filterId} setFilter={SetFilterName} title='Popular Speakers' type="popularspeaker" filter={filter} selectorValueSession={selectorValue} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                  
                country={country}  
                search={search} SetSearchName={SetSearchName} />} />
                <Route exact path="/home/popular" element={<ShowMore selectedLibraryid={selectedLibraryid} translations={translations}  filterId={filterId} setFilter={SetFilterName} title='Popular Content' type="popular" filter={filter} selectorValueSession={selectorValue} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                  
                country={country} 
                search={search} SetSearchName={SetSearchName}  />} />
                <Route exact path="/home/keepwatching" element={<ShowMore selectedLibraryid={selectedLibraryid} translations={translations}  filterId={filterId} setFilter={SetFilterName} title='Keep Watching' type="keepwatching" filter={filter} selectorValueSession={selectorValue} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                
                country={country}
                search={search} SetSearchName={SetSearchName} />} />
                <Route exact path="/home/watched" element={<ShowMore selectedLibraryid={selectedLibraryid} translations={translations}  setFilter={SetFilterName} filterId={filterId} title='Watched Contents' type="watched" filter={filter} selectorValueSession={selectorValue} SetselectedCountry={SetselectedCountry} 
                selectedCountry={selectedCountry} SetselectedLibrary={SetselectedLibrary} 
                libraryData={libraryData} SetlibraryData={SetlibraryData} 
                openLibraryModal={openLibraryModal} SetopenLibraryModal={SetopenLibraryModal} 
                  
                country={country} 
                search={search} SetSearchName={SetSearchName}  />} />
              </Route> 

            {/* 5. UserRoute Migration */}
            
              {areRolesSet && (isRoleAdmin ? <Route  exact path="/user-profile/:userid" element={<UserProfile setupdateBool={setupdateBool} updateBool={updateBool} translations={translations} setFilter={SetFilterName}/> }/> : 
              <Route  exact path="/user-profile/:userid" element={<Forbidden translations={translations}/>}/>)}
              
              {areRolesSet && (isRoleAdmin ? <Route exact path="/user-management" element={<UserManagement updateBool={updateBool} setupdateBool={setupdateBool}  translations={translations} setFilter={SetFilterName}/>}/> : 
              <Route exact path="/user-management" element={<Forbidden  translations={translations} />}/>)}
              
              {areRolesSet && (<Route exact path="/my-profile" element={<IntUserProfile  translations={translations} setFilter={SetFilterName}/>}/>)}
              
            {/* 6. SpeakerRoute Migration */}

              {areRolesSet && (isRoleAdmin || isRolePublisher ? <Route exact path="/speaker-management" element={<SpeakerManagement translations={translations} setFilter={SetFilterName}/>}/>: 
              <Route exact path="/speaker-management" element={<Forbidden translations={translations} />}/>)}             

            {/* 7. ContentRoute Migration */}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/content-management" element={<ContentManagement translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/content-management" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/content-update" element={<ContentUpdateStepper allcategory={allcategory} translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/content-update" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
               <Route path="/content-update/reload" element={<Navigate to="/content-update" replace />} /> :
              <Route exact path="/content-update/reload" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/content-update/:id" element={<ContentUpdateStepper allcategory={allcategory} translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/content-update/:id" element={<Forbidden translations={translations} />} />)}

              {/* <Route exact path="/content-redirect/:kaltura_id" element={<Navigate  to='/content-watch/1_8tiqioyp' replace/>} /> */}

            {/* 8. PlaylistRoute Migration */}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/playlist-management" element={<PlaylistManagement translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/playlist-management" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/playlist-update" element={<PlaylistUpdate allcategory={allcategory} translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/playlist-update" element={<Forbidden allcategory={allcategory} translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route path="/playlist-update/reload" element={<Navigate to="/playlist-update" replace />} /> :
              <Route exact path="/playlist-update/reload" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/playlist-update/:id" element={<PlaylistUpdate allcategory={allcategory} translations={translations} setFilter={SetFilterName} />} /> :
              <Route exact path="/playlist-update/:id" element={<Forbidden allcategory={allcategory} translations={translations} />} />)}
              
            {/* 9. CoutryRoute Migration */}
              
              {areRolesSet && (isRolePublisher ? 
              <Route exact path="/country-management" element={<CountryManagement  translations={translations} setFilter={SetFilterName}/>}/> 
              :
              <Route exact path="/country-management" element={<Forbidden translations={translations} />}/>)}

              {areRolesSet && (isRolePublisher ? 
              <Route exact path="/country-profile/:id" element={<CountryProfile  translations={translations} setFilter={SetFilterName}/>}/> 
              :
              <Route exact path="/country-profile/:id" element={<Forbidden translations={translations}/>}/>)}
              
            {/* 10. CategoryRoute Migration */}
              
              {areRolesSet && (isRoleAdmin ? 
              <Route exact path="/category-management" element={<CategoryManagement  translations={translations} setFilter={SetFilterName}/>}/> 
              : 
              <Route exact path="/category-management" element={<Forbidden translations={translations} />}/>)}

              {/* {areRolesSet&& <ContentListRoute translations={translations} filterId={filterId} filter={filter}
              search={search} setFilter={SetFilterName} isRoleAdmin={isRoleAdmin} isRoleHCP={isRoleHCP}
              isRoleInternal={isRoleInternal} isRolePublisher={isRolePublisher} />} */}

            {/* 11. ContentWatchRoute Migration */}

              <Route exact path="/home/watch" element={<ContentWatchOldNonHCPDirect  translations={translations} setFilter={SetFilterName} play={play} Setplay={Setplay} setCountryCode={setCountryCode} setHcpId = {setHcpId} SetIsPlaylist={SetIsPlaylist}
              SetMiniPlayerId={SetMiniPlayerId} miniPlayerId={miniPlayerId}
              SetMainPlayerId={SetMainPlayerId} mainPlayerId={mainPlayerId} filter={filter}
              SetContentId={SetContentId} positionMainPlayer={positionMainPlayer}
              SetPositionMainPlayer={SetPositionMainPlayer} SetAutoPlay={SetAutoPlay}
              SetselectedLibrary={SetselectedLibrary}
              selectedLibraryid={selectedLibraryid} SetselectedLibraryid={SetselectedLibraryid}/>} />

              <Route exact path="/callback/:countryCode" element={<ContentWatchOld  translations={translations} setFilter={SetFilterName} play={play} Setplay={Setplay} setCountryCode={setCountryCode} setHcpId = {setHcpId} SetIsPlaylist={SetIsPlaylist}
              SetMiniPlayerId={SetMiniPlayerId} miniPlayerId={miniPlayerId}
              SetMainPlayerId={SetMainPlayerId} mainPlayerId={mainPlayerId} filter={filter}
              SetContentId={SetContentId} positionMainPlayer={positionMainPlayer}
              SetPositionMainPlayer={SetPositionMainPlayer} SetAutoPlay={SetAutoPlay}
              SetselectedLibrary={SetselectedLibrary}
              selectedLibraryid={selectedLibraryid} SetselectedLibraryid={SetselectedLibraryid}/>} />

              <Route exact path="/content-watch/:kaltura_id/:content_id" element={<ContentWatch SetPercentageElapsed={SetPercentageElapsed} SetQuizMandatoryStatus={SetQuizMandatoryStatus} translations={translations} setFilter={SetFilterName} play={play} Setplay={Setplay} setCountryCode={setCountryCode} setHcpId = {setHcpId} SetIsPlaylist={SetIsPlaylist}
              SetMiniPlayerId={SetMiniPlayerId} miniPlayerId={miniPlayerId} filter={filter}
              SetMainPlayerId={SetMainPlayerId} mainPlayerId={mainPlayerId}
              filterId={filterId} lastContentWatchURL={lastContentWatchURL} customPathHook={customPathHook}
              SetContentId={SetContentId} positionMainPlayer={positionMainPlayer}
              durationUrlRead={durationUrlRead} SetDurationUrlRead={SetDurationUrlRead}
              SetPositionMainPlayer={SetPositionMainPlayer} SetAutoPlay={SetAutoPlay}
              SetselectedLibrary={SetselectedLibrary}
              selectedLibraryid={selectedLibraryid} SetselectedLibraryid={SetselectedLibraryid}/>} />
            {/* 12. VideoRoute Migration */}
              <Route exact path="/Video" element={<Video  translations={translations} setFilter={SetFilterName} filter={filter}/>}/>
            
            {/* 13. ContentShowRoute Migration */}
              {areRolesSet && (<Route exact path="/content-show"  element={<ContentShow  translations={translations} filter={filter} filterId={filterId} setFilter={SetFilterName}  selectedLibraryid={selectedLibraryid} selectedCountry={selectedCountry}/>}/>)}

            {/* 14. EventRoute Migration */}

              {areRolesSet && (isRolePublisher ?
              <Route exact path="/event-management" element={<EventManagement translations={translations} setFilter={SetFilterName} selectorValueSession={selectorValue} />} /> :
              <Route exact path="/event-management" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (isRolePublisher ? <Route exact path="/live-event" element={<LiveEvent translations={translations} setFilter={SetFilterName} selectorValueSession={selectorValue} />} /> :
              <Route exact path="/live-event" element={<Forbidden translations={translations} selectorValueSession={selectorValue} />} />)}

              {areRolesSet && (isRolePublisher ? <Route exact path="/event-edit/:id" element={<EventEdit translations={translations} setFilter={SetFilterName} selectorValueSession={selectorValue} />} /> :
              <Route exact path="/event-edit/:id" element={<Forbidden translations={translations} selectorValueSession={selectorValue} />} />)}

            {/* 15. TranslationRoute Migration */}
              {areRolesSet && (isRoleAdmin ?
              <Route exact path="/translation-management" element={<TranslationManagement  translations={translations} setFilter={SetFilterName}/>}/> :
              <Route exact path="/translation-management" element={<Forbidden translations={translations} />} />)} 

            {/* 16. QuizRoute Migration */}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/quiz-management" element={<QuizManagement  translations={translations} setFilter={SetFilterName}/>}/> :
              <Route exact path="/quiz-management" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/quiz-management/quiz-detail/:id" element={<QuizDetail  translations={translations} setFilter={SetFilterName}/>}/> :
              <Route exact path="/quiz-management/quiz-detail/:id" element={<Forbidden translations={translations} />} />)}

              {areRolesSet && (!isRoleHCP ?
              <Route exact path="/quiz-management/quiz-detail" element={<QuizDetail  translations={translations} setFilter={SetFilterName}/>}/> :
              <Route exact path="/quiz-management/quiz-detail" element={<Forbidden translations={translations} />} />)}

            {/* 17. PrescribeInfoRoute Migration */}
              <Route exact path="/informacion-para-prescribir" element={<PrescirbingInfo translations={translations} setFilter={SetFilterName}/>}/>
              
            {/* 18. ForbiddenRoute Migration */}
              <Route exact path="/forbidden" element={<Forbidden translations={translations} />}/>

              {/* <VideoPlayer SetPlayerShow={SetPlayerShow} playerShow={playerShow} translations={translations} play={play} Setplay={Setplay} isPlaylist={isPlaylist} countryCode={countryCode} hcpId={hcpId} renderCheckMiniPlayer={renderCheckMiniPlayer} contentId={contentId}
              SetMiniPlayerId={SetMiniPlayerId} miniPlayerId={miniPlayerId} positionMainPlayer={positionMainPlayer} SetPositionMainPlayer={SetPositionMainPlayer}
              SetMainPlayerId={SetMainPlayerId} mainPlayerId={mainPlayerId}/> */}

            {/* 19. StaticPagesRoute Migration */}
              <Route exact path="/audience-age" element={<AudienceAge setFilter={SetFilterName} translations={translations} />} />
              <Route exact path="/contact-us" element={<ContactUs setFilter={SetFilterName} translations={translations} />} />
    

            {/* 20. LoginRoute Migration */}
              {(!access_token || access_token == '' || access_token == undefined )&& (<Route path='/' element={<Login redirect={false} SelectedCountry={SelectedCountry} />} />)}

              {(!access_token || access_token == '' || access_token == undefined )&& (<Route exact path="/:country" element={<Login redirect={true} SelectedCountry={SelectedCountry} />}/>)}

              {(!access_token || access_token == '' || access_token == undefined )&& (<Route exact path='/logout' element={<Logout />} />)}

            {/* 21. Handling Undefined Routes */}
             {areRolesSet && <Route path="*" element={<Navigate to ='/home' replace/>}/>}
            </Routes>

            
              <AuthVerifyComponent />
                           
              {play.state === true && playerShow ? <VideoPlayer percentageElapsed={percentageElapsed} SetPlayerShow={SetPlayerShow} playerShow={playerShow} translations={translations} play={play} Setplay={Setplay} isPlaylist={isPlaylist} countryCode={countryCode} hcpId={hcpId} renderCheckMiniPlayer={renderCheckMiniPlayer} contentId={contentId}
              SetMiniPlayerId={SetMiniPlayerId} miniPlayerId={miniPlayerId} positionMainPlayer={positionMainPlayer} SetPositionMainPlayer={SetPositionMainPlayer} autoPlay={autoPlay}
              SetMainPlayerId={SetMainPlayerId} mainPlayerId={mainPlayerId} quizMandatoryStatus={quizMandatoryStatus} /> : null}
             
            </AppMain>
          // </Provider>
        }
      </React.Suspense>
    </>

  );
}
export default MainRoutes;
