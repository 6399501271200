import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AddProducts = (props) => {
    const [productCharactersticsModal, setProductCharactersticsModal] = useState(false);
    const [bool, SetBool] = React.useState(true);
    const [fileName, setFileName] = React.useState('');
    const [link, setLink] = React.useState('');
    const [isUpdate, setisUpdate] = React.useState(false);
    const [currentIndex, setcurrentIndex] = React.useState(0);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('')

    const isValidUrl = urlString => {
        // if ((urlString.trim()).length > 0) {
        //     try {
        //         new URL(urlString);
        //         return true;
        //     }
        //     catch (err) {
        //         return false;
        //     }
        // } else {
        //     return false;
        // }
        const urlPattern = /(?:https|http?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        return urlPattern.test(urlString);
    }

    React.useEffect(() => {
        if (bool) {
            // if (props.materials.length > 0) {
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const AddProduct = () => {
        let err = '';
        if (!isValidUrl(link)) {
            err += ' Invalid Link;';
        }
        if (fileName.trim() == '') {
            err += ' Filname Empty;';
        }
        if (err !== '') {
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg(err)
            return
        }
        props.setcontentlost(true)
        let productArr = [...props.productCharacteristics];
        productArr.push({
            displayText: fileName, url: link, brand_id: "", disabled: false, errorMessage: ""
        })
        setFileName('')
        setLink('')
        setProductCharactersticsModal(false)
        props.SetProductCharacteristics(productArr);
    }
    const updateProduct = (currentIndex) => {
        let err = '';
        if (!isValidUrl(link)) {
            err += ' Invalid Link;';
        }
        if (fileName.trim() == '') {
            err += ' Filname Empty;';
        }
        if (err !== '') {
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg(err)
            return
        }
        if (err) {
            return
        }
        props.setcontentlost(true)
        let productArr = [...props.productCharacteristics];
        productArr[currentIndex]['displayText'] = fileName
        productArr[currentIndex]['url'] = link
        setFileName('')
        setLink('')
        setProductCharactersticsModal(false)
        props.SetProductCharacteristics(productArr);
    }
    const RemoveProduct = (index) => {
        props.setcontentlost(true)
        let productArr = [...props.productCharacteristics];
        const filteredArray = productArr.filter((item, i) => {
            if (i === index) {
                return false;
            }
            return true;
        });
        props.SetProductCharacteristics(filteredArray);
    }
    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={() => { setSnackbarOpen(false) }}>
                <Alert
                    className={`snackbar ${snackbarType}`}
                    onClose={() => { setSnackbarOpen(false) }}
                    severity={`${snackbarType}`}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid className='content-field' item md={6} sm={12}>
                <FormControl component="fieldset">
                    <label className='input-label'>
                        Product Characteristics
                    </label>
                </FormControl>
                <div className='product-characterstics-section'>
                    <div className='table-head'>
                        <div>File Name</div>
                        <div>File Link</div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='table-body'>
                        {props.productCharacteristics.length > 0 ? (
                            props.productCharacteristics.map((characteristic, index) => (
                                <div className='table-row'>
                                    <div>{characteristic.displayText}</div>
                                    <div>{characteristic.url}</div>
                                    <div>
                                        {props.enableFields ?
                                            <EditOutlinedIcon style={{ color: '#475467' }}
                                                onClick={() => {
                                                    setFileName(characteristic.displayText)
                                                    setLink(characteristic.url)
                                                    setProductCharactersticsModal(true)
                                                    setisUpdate(true)
                                                    setcurrentIndex(index)
                                                }} /> :
                                            <EditOutlinedIcon style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                        }
                                    </div>
                                    <div>
                                        {props.enableFields ?
                                            <RemoveCircleOutlineOutlinedIcon
                                                onClick={() => { RemoveProduct(index) }}
                                                style={{ color: '#475467' }} />
                                            :
                                            <RemoveCircleOutlineOutlinedIcon style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                        }
                                    </div>
                                </div>
                            ))) : ""}
                    </div>
                    <div className='table-footer'>
                        <div className={props.enableFields ? 'more-lines' : 'more-lines disabled'}
                            onClick={() => {
                                if (props.enableFields) {
                                    setisUpdate(false)
                                    setProductCharactersticsModal(true)
                                    setFileName('')
                                    setLink('')
                                }
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M10 7.46672V14.1334M6.66667 10.8H13.3333M6.5 18.3H13.5C14.9001 18.3 15.6002 18.3 16.135 18.0276C16.6054 17.7879 16.9878 17.4054 17.2275 16.935C17.5 16.4002 17.5 15.7002 17.5 14.3V7.30005C17.5 5.89992 17.5 5.19985 17.2275 4.66507C16.9878 4.19467 16.6054 3.81222 16.135 3.57253C15.6002 3.30005 14.9001 3.30005 13.5 3.30005H6.5C5.09987 3.30005 4.3998 3.30005 3.86502 3.57253C3.39462 3.81222 3.01217 4.19467 2.77248 4.66507C2.5 5.19985 2.5 5.89992 2.5 7.30005V14.3C2.5 15.7002 2.5 16.4002 2.77248 16.935C3.01217 17.4054 3.39462 17.7879 3.86502 18.0276C4.3998 18.3 5.09987 18.3 6.5 18.3Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Link
                        </div>
                    </div>
                </div>
            </Grid>
            {/* product characterstics modal */}
            <Dialog
                className='product-charastestics-dialog'
                open={productCharactersticsModal}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                        },
                    },
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setProductCharactersticsModal(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.0002 13C10.4297 13.5741 10.9776 14.0491 11.6067 14.3929C12.2359 14.7367 12.9317 14.9411 13.6468 14.9923C14.362 15.0435 15.0798 14.9403 15.7515 14.6897C16.4233 14.4392 17.0333 14.047 17.5402 13.54L20.5402 10.54C21.451 9.59695 21.955 8.33394 21.9436 7.02296C21.9322 5.71198 21.4063 4.45791 20.4793 3.53087C19.5523 2.60383 18.2982 2.07799 16.9872 2.0666C15.6762 2.0552 14.4132 2.55918 13.4702 3.46997L11.7502 5.17997M14.0002 11C13.5707 10.4258 13.0228 9.95078 12.3936 9.60703C11.7645 9.26327 11.0687 9.05885 10.3535 9.00763C9.63841 8.95641 8.92061 9.0596 8.24885 9.31018C7.5771 9.56077 6.96709 9.9529 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.542 3.52111 20.4691C4.44815 21.3961 5.70221 21.9219 7.01319 21.9333C8.32418 21.9447 9.58719 21.4408 10.5302 20.53L12.2402 18.82" stroke="#E02F2B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='modal-heading-text'>
                        <div>Product Charactheristics</div>
                        <div className='modal-heading-subtext'>Add a link to the file.</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => setProductCharactersticsModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent sx={{
                    "& .MuiDialogContent-root": {
                        padding: '0px 0px'
                    },
                }} >
                    <Grid container className="modal-body">
                        <Grid className='content-field' item md={5} sm={5}>
                            <label className='input-label '>
                                File Name
                            </label>
                            <TextField
                                className='text-input'
                                variant="outlined"
                                placeholder="Enter File Name"
                                size="small"
                                onChange={(event) => {
                                    setFileName(event.target.value)
                                }}
                                InputLabelProps={{ shrink: false }}
                                value={fileName} />
                        </Grid>
                        <Grid className='content-field' item md={2} sm={2}>
                            <div className='line'></div>
                        </Grid>
                        <Grid className='content-field' item md={5} sm={5}>
                            <label className='input-label '>
                                Link
                            </label>
                            <TextField
                                className='text-input'
                                variant="outlined"
                                placeholder="http://"
                                size="small"
                                onChange={(event) => {
                                    setLink(event.target.value)
                                }}
                                InputLabelProps={{ shrink: false }}
                                value={link} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <div className='modal-actions'>
                        <button className="modal-cancel" onClick={() => { setProductCharactersticsModal(false) }}>Cancel</button>
                        <button className="modal-download" onClick={() => { !isUpdate ? AddProduct() : updateProduct(currentIndex) }}>
                            {!isUpdate ? "Add" : "Update"}
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AddProducts