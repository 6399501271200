import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EventService } from '../Services/event-service';

const GetAllCategoryTree = (therapeuticAreas, category1, category2) => {
  let mainObj = [];
  if (therapeuticAreas.length > 0) {
    for (let t = 0; t < therapeuticAreas.length; t++) {
      let obj = { id: "", name: "", children: [] };
      let ta_id = therapeuticAreas[t].therapeutic_area_id;
      let ta_name = therapeuticAreas[t].therapeutic_area_name;
      obj.id = ta_id;
      obj.name = ta_name;
      obj.children = [];
      if (category1.length > 0) {
        for (let b = 0; b < category1.length; b++) {
          let cat1_ta_id = category1[b].therapeutic_area_id;
          let cat1_id = category1[b].category_1_id;
          let cat1_name = category1[b].category_1_name;
          if (cat1_ta_id === ta_id) {
            obj.children.push({ id: cat1_id, name: cat1_name, children: [] });
          }
          if (category2.length > 0) {
            for (let i = 0; i < category2.length; i++) {
              let cat2_ta_id = cat1_ta_id;
              let cat2_cat1_id = category2[i].category_1_id;
              let cat2_id = category2[i].category_2_id;
              let cat2_name = category2[i].category_2_name;
              if (cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                if (obj.children.length > 0) {
                  for (let k = 0; k < obj.children.length; k++) {
                    if (obj.children[k] && obj.children[k].id === cat2_cat1_id &&
                      cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                      obj.children[k].children.push({ id: cat2_id, name: cat2_name });
                    }
                  }
                }
              }
            }
          }
        }
      }
      mainObj.push(obj);
    }
  }
  return mainObj;
}
const ContentTherapeuticArea = (props) => {
  // Get All Country TA, Cat1 & Cat2 
  const [categories, SetCategoryTree] = React.useState([]);
  React.useEffect(() => {
    let country_code = []
    for (let i = 0; i < props.affiliates.length; i++) {
      country_code.push(props.affiliates[i].country_code)
    }
    let service = new EventService();
    service.GetCountryCategories(country_code).then((data) => {
      let tree = GetAllCategoryTree(data.ta, data.cat1, data.cat2)
      SetCategoryTree(tree);
    }).catch((err) => {})
  }, [props.affiliates])

  return (
    <Grid container className="content white_row">
      <Grid container className="content_inner_box">
        <Grid item lg={12} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Therapeutic Areas, Category 1 and Category 2 (Category 1 is mandatory field)</FormLabel>
            <div className='ml20'>
              <>
                {categories.length > 0 ?
                  <TreeView className='contentTreeview' defaultCollapseIcon={<ExpandMoreIcon className='iconSvg' />} defaultExpandIcon={<ExpandMoreIcon className='iconSvg' />}>
                    {categories.map((treeItemData, index) => {
                      return (
                        <>
                          <TreeItem className={index} nodeId={treeItemData?.id?.toString()} key={index} label={
                            <>
                              <div className='childDrop'>{treeItemData?.name ? treeItemData.name : null}</div>
                            </>
                          }>
                            {treeItemData.children.length > 0 ? treeItemData.children.map((treeItemChildren, innerIndex) => {
                              return (
                                <TreeItem nodeId={"treeItemChildren"+treeItemChildren.id.toString()} key={innerIndex} label={
                                  <>
                                    <div className='childDrop'>
                                      <FormControlLabel control={
                                        <Checkbox value={treeItemChildren.name} name="categories"
                                          checked={props.selectedBrands.includes(treeItemChildren.id)}
                                          onChange={(event) => {
                                            if (event.target.checked) {
                                              if(props.selectedBrands == null){
                                                props.SetselectedBrands([ treeItemChildren.id]);
                                              }else{
                                                props.SetselectedBrands([...props.selectedBrands, treeItemChildren.id]);
                                              }
                                              if(props.selectedTherapeuticAreas == null){
                                                props.SetselectedTherapeuticAreas([treeItemData.id]);
                                              }else{
                                                props.SetselectedTherapeuticAreas([...props.selectedTherapeuticAreas, treeItemData.id]);
                                              }
                                            } else {
                                              props.SetselectedBrands(props.selectedBrands.filter(item => item !== treeItemChildren.id));
                                              props.SetselectedTherapeuticAreas(props.selectedTherapeuticAreas.filter(item => item !== treeItemData.id));
                                            }
                                          }}
                                        />
                                      } />
                                      {treeItemChildren?.name ? treeItemChildren.name : null}
                                    </div>
                                  </>
                                }
                                >
                                  {treeItemChildren.children.length > 0 ? treeItemChildren.children.map((treeItemSubchildren, innerIndex2) => {
                                    return (
                                      <TreeItem nodeId={"treeItemSubchildren"+treeItemSubchildren?.id} key={innerIndex2} label={
                                        <>
                                          <div className='subchildDrop'>
                                            <FormControlLabel control={
                                              <Checkbox value={treeItemSubchildren.name} name="categories"
                                                checked={props.selectedIndications.includes(treeItemSubchildren.id)}
                                                onChange={(event) => {
                                                  if (event.target.checked) {
                                                    if(props.selectedIndications == null){
                                                      props.SetselectedIndications([ treeItemSubchildren.id]);
                                                    }else{
                                                      props.SetselectedIndications([...props.selectedIndications, treeItemSubchildren.id]);
                                                    }
                                                    if(props.selectedTherapeuticAreas == null){
                                                      props.SetselectedTherapeuticAreas([treeItemData.id]);
                                                    }else{
                                                      props.SetselectedTherapeuticAreas([...props.selectedTherapeuticAreas, treeItemData.id]);
                                                    }
                                                  } else {
                                                    props.SetselectedIndications(props.selectedIndications.filter(item => item !== treeItemSubchildren.id));
                                                    props.SetselectedTherapeuticAreas(props.selectedTherapeuticAreas.filter(item => item !== treeItemData.id));
                                                  }
                                                }}
                                              />
                                            } />
                                            {treeItemSubchildren?.name ? treeItemSubchildren.name : null}
                                          </div>
                                        </>
                                      }
                                      />
                                    )
                                  }) : null}
                                </TreeItem>
                              )
                            }) : null}
                          </TreeItem>
                        </>
                      );
                    })}
                  </TreeView>
                : <Grid>No Data Found</Grid>}
              </>
            </div>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ContentTherapeuticArea