/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { SpeakerService } from '../Services/speaker-service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {HomeService} from '../../../home/Service/homeService'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import { ContentService } from '../../Service/content-service';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const SpeakerCreate = (props) => {
    let speakerService = new SpeakerService();
    let contentService = new ContentService();
    const [s3UploadCheck, SetS3UploadCheck] = React.useState(false);
    const [imageSizeCheck, SetImageSizeCheck] = React.useState(false);
    const [imageTypeCheck, SetImageTypeCheck] = React.useState(false);
    const [s3UploadErrorCheck, SetS3UploadErrorCheck] = React.useState(false);
    const [imageDimensionCheck, SetImageDimensionCheck] = React.useState(false);
    const [speakerPhoto, SetSpeakerPhoto] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    
    const [formData, setFormData] = React.useState({
        speaker_id: 0,
        speaker_photo: '',
        speaker_name: '',
        speaker_curriculum: '',
        speaker_hospital: '',
        speaker_url: ''
    });

    React.useEffect(() => {
        if (props.speakerID) {
            speakerService.GetSpeakerByQueryCreateEdit({ speakerID: props.speakerID }).then((data) => {
                let speakers = data.data;
                if (speakers.length > 0) {
                    let speaker = speakers[0];
                    (new HomeService()).GetImageFromURL(speaker.avatar_attachment_id).then((data)=> {
                        setImgPreview(data);
                    });
                    speakerService.GetS3File(speaker.avatar_attachment_id).then((data) => {
                        
                    });
                    setFormData({
                        speaker_id: props.speakerID,
                        speaker_name: speaker.name,
                        speaker_curriculum: speaker.curriculum,
                        speaker_hospital: speaker.hospital,
                        speaker_url: speaker.url
                    });
                }
            }).catch((err) => { 
            })
        }
    }, []);

    const handleSubmit = () => {
        if (formData.speaker_name == "") {
            props.validation(true);
            props.setValiadationName("Speaker Name")
            return;
        } else if (formData.speaker_curriculum == "") {
            props.validation(true);
            props.setValiadationName("Curriculum")
            return;
        }
        setIsDisabled(true)
        setFormData({ ...formData, ['speaker_photo']: speakerPhoto });
        speakerService.UpdateSpeaker(formData).then((data) => {
            props.closePopUp(false);
            props.reloadData();
        }).catch((err) => {})
    }

    const handleFormDataChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name == "speaker_photo") {
            let file = event.target.files[0];
            if (file) {
                value = "";
            }
        }
        setFormData({ ...formData, [name]: value });
    };

    // Image Upload Preview 
    const [imgPreview, setImgPreview] = React.useState('');
    const imgPreviewHandler = (file) => {

        SetS3UploadCheck(false);
        SetImageDimensionCheck(false);
        SetImageSizeCheck(false);
        SetImageTypeCheck(false);
        SetS3UploadErrorCheck(false);

        if (file) {
            //Check for file size - 100KB 
            let imageByteSize = file.size;
            if(imageByteSize > 102400){ //100KB Limit
                SetS3UploadCheck(true);
                SetImageSizeCheck(true);
                return;
            } else {
                SetImageSizeCheck(false);
            }
            
            //Check for file type
            let imageType = file.type;
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(imageType)) {
              SetS3UploadCheck(true);
              SetImageTypeCheck(true);
              return;
            } else {
              SetImageTypeCheck(false);
            }
    
            //speakerService.LoadFile(file, file.name);
            const reader = new FileReader();
            let key = 'Country/Banner/'+ (new Date()).getTime().toString()+ '-' + file.name ;
            reader.onload = () => {
                let image = new Image();
                image.onload = function () {
                    let width = this.width;
                    let height = this.height;
                    if (!(width === 1280 && height === 720)) {
                        SetS3UploadCheck(true);
                        SetImageDimensionCheck(true);
                    } else {
                        speakerService.UploadFileToS3(key, reader.result).then((data)=> {
                            SetSpeakerPhoto(key);
                            setFormData({ ...formData, ['speaker_photo']: key });
                            setImgPreview(reader.result);
                        }).catch(async (err)=> {
                            let errorObj = {
                                user_id: props.created_by,
                                thumbnail_name: key, 
                                log_event_type:"Speaker", 
                                thumbnail_size: imageByteSize, 
                                thumbnail_height: height, 
                                thumbnail_width: width, 
                                thumbnail_type: imageType, 
                                record_id: props.id,
                                error_code: err.status?err.status:'',
                                error_message: err.message
                              }
                              try {
                                  await contentService.UpdateS3ImagesErrorLogs(errorObj);
                              } catch (saveerr) {  }
                              SetS3UploadCheck(true);
                              SetS3UploadErrorCheck(true);
                          });
                    }
                }
                image.src = reader.result;
            }
            reader.readAsDataURL(file);
        }
    }

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            
        } else { 
            setOpen(false); props.closePopUp(false); 
        }
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
        return false;
    };

    return (
        <>
            <Modal open={s3UploadCheck} onClose={()=> {SetS3UploadCheck(false)}}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={()=> {SetS3UploadCheck(false)}}></button>
          </Grid>
          <Grid className='box'>
            <Grid className='attenSec attenSecvalidationImage'>
              <span className='attenIcnSec'>
                <ReportProblemIcon className='attenIcn' />
                Error
              </span>
              <div className="m20">
                {imageSizeCheck ? (
                    <Alert severity="warning">The thumbnail you selected does not meet the standard of file size. The file is too large. Please, select another thumbnail which size is less than 100KB</Alert>
                  ) : ""}
                {imageTypeCheck ? (
                  <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail format. The file format is not allowed by system. Please, select another thumbnail which formats are: .jpg, .jpeg, .png.</Alert>
                ) : ""}
                {imageDimensionCheck ? (
                  <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail dimensions. The file is not of correct dimension. Please, select another thumbnail which dimensions are 1280x720px</Alert>
                ) : ""}
                {s3UploadErrorCheck ? (
                  <Alert severity="warning">The thumbnail you selected could not be uploaded. Please, try again later. If you submit the video now, Lilly Play will use the default thumbnail.</Alert>
                ) : ""}
              </div>
            </Grid>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" className="modalBtn" onClick={()=> {SetS3UploadCheck(false)}}>Ok</button>
          </Grid>
        </Grid>
      </Modal>
            <Dialog open={open}
                scroll='body'
                maxWidth='sm'
                TransitionComponent={Transition}
                className='speaker-modal'
                onClose={handleClose}
                onBackdropClick={handleBackdropClick}
                disableEscapeKeyDown
            >
                <DialogTitle className='modal-title'>
                    {props.speakerID ? 'Speaker Modify:' : 'Create New Speaker:'}
                    <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </DialogTitle>
                <DialogContent className='modal-body'>
                    <FormControl component="fieldset" className='formControl'>
                        <input
                            accept="image/*"
                            className='hidden'
                            id="contained-button-file"
                            name="speaker_photo"
                            type="file"
                            onChange={(event) => { handleFormDataChange(event); imgPreviewHandler(event.target.files[0]) }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button className="speaker_upload_btn" variant="contained" color="primary" component="span">
                                {(imgPreview) ? <div className="text-center p-3"><img src={imgPreview} alt="imgpreview" style={{minHeight:"210px", maxWidth:"200px"}}/></div> : 'Upload Speaker Photo'}
                            </Button>
                        </label>
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel component="legend">Speaker Name <span className='red'>*</span></FormLabel>
                        <TextField variant="outlined" placeholder="Speaker Name" name="speaker_name" size="small" InputLabelProps={{ shrink: false }} onChange={handleFormDataChange} value={formData.speaker_name} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel component="legend">Curriculum vitae <span className='red'>*</span></FormLabel>
                        <TextField variant="outlined" placeholder="Speaker curriculum" name="speaker_curriculum" size="small" multiline minRows={3} InputLabelProps={{ shrink: false }} onChange={handleFormDataChange} value={formData.speaker_curriculum} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel component="legend">Workplace</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker workplace" name="speaker_hospital" size="small" InputLabelProps={{ shrink: false }} onChange={handleFormDataChange} value={formData.speaker_hospital} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel component="legend">Website</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker url" name="speaker_url" size="small" InputLabelProps={{ shrink: false }} onChange={handleFormDataChange} value={formData.speaker_url} />
                    </FormControl>
                </DialogContent>
                <DialogActions className='modal-footer'>
                    <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
                    <button type="button" className="modalBtn" disabled={isDisabled} onClick={() => { handleSubmit(); }}>OK</button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default SpeakerCreate;






