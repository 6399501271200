import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ContentService } from '../Service/content-service';
import { VVPMModel } from '../ServiceModels/KalturaServiceModels';
import TableContainer from '@material-ui/core/TableContainer';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Radio from '@material-ui/core/Radio';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const headCells = [
    { id: 'action', numeric: true, disablePadding: false, label: '' },
    { id: 'document_number__v', numeric: false, disablePadding: false, label: 'Document Number' },
    { id: 'name__v', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'subtype__v', numeric: false, disablePadding: false, label: 'Subtype' },
    { id: 'classification__v', numeric: false, disablePadding: false, label: 'Classification' },
    { id: 'expiration_date__vs', numeric: false, disablePadding: false, label: 'Expiration Date' },
    { id: 'parimary_category', numeric: false, disablePadding: false, label: 'Primary Category' },
    { id: 'parimary_sub_category', numeric: false, disablePadding: false, label: 'Primary Sub Category' },
    { id: 'secondary_category', numeric: false, disablePadding: false, label: 'Secondary Category' },
    { id: 'secondary_sub_category', numeric: false, disablePadding: false, label: 'Secondary Sub Category' },
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomSelect = withStyles({
    root: {
        padding: '6px',
        minHeight: '1.8rem',
        display: 'flex',
        alignItems: 'center',
        '& .placeholder': {
            color: '#A4A2A2',
            display: 'flex',
            height: '26px',
            alignItems: 'center'
        }
    }
})(Select)
// Functions for Apply Sorting of Data Table
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <div className='table-head'>
            <div className='table-row'>
                {headCells.map((headCell) => (
                    <div className='table-cell'
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className='visuallyHidden'>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </div>
                ))}
            </div>
        </div>
    );
}
const CustomCheckbox = withStyles({
    root: {
        color: 'white',
        '& .MuiIconButton-label': {
            border: '1px solid #D0D5DD',
            borderRadius: '4px',
        },
        '&$checked': {
            color: 'white',
            '& .MuiIconButton-label': {
                position: "relative",
                zIndex: 0,
                border: '1px solid #D92D20',
                borderRadius: '4px',
            },
            '& .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: 'absolute',
                backgroundColor: '#D92D20',
                zIndex: -1
            }
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function VVPMDetailsModal({ modalFunc, affiliate_name, ...props }) {
    const [openSecondaryModal, setOpenSecondaryModal] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [subType, SetSubType] = React.useState([]);
    const [classificationCode, SetClassificationCode] = React.useState([]);
    const [rows, SetRows] = React.useState([]);
    const [selectedRow, SetselectedRow] = React.useState({});
    const [keywordLookupList, SetKeyWordLookupList] = React.useState([]);
    const [apiLoaded, SetapiLoaded] = React.useState('');
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    let contentService = new ContentService();
    const SearchVVPM = () => {
        contentService.GetVVPMDetails(new VVPMModel(props.nameSearch ?? '', props.selectedsubType[0] ?? '', props.vvpmCode ?? '', props.selectedclassificationCode[0] ?? '')).then((data) => {
            if (Array.isArray(data.data.data)) {
                let list = [];
                for (let i = 0; i < data.data.data.length; i++) {
                    let item = data.data.data[i];
                    if (item.expiration_date__vs) {
                        if (item.expiration_date__vs.length > 0) {
                            let date = new Date(item.expiration_date__vs);
                            if (!(date.toString() == 'Invalid Date')) {
                                list.push(item);
                            }
                        }
                    }
                }
                SetRows(list);
                SetKeyWordLookupList(list);
                SetapiLoaded(true)
            }

        }).catch((err) => {
            SetapiLoaded(false);
        });
    }
    const handlePaginationPrevious = () => {
        if (page >= 1) {
            setPage(page - 1)
        }
    }
    const handlePaginationNext = () => {
        const totalpages = Math.floor(rows.length / rowsPerPage);
        if (page < totalpages) {
            setPage(page + 1)
        }
    }
    const isSelected = (name) => {
        if (Array.isArray(props.VVPMDetails)) {
            return props.VVPMDetails.indexOf(name) !== -1;
        }
        else {
            return false;
        }
    };
    const handleClose = () => {
        SetapiLoaded('');
        props.SetOpenDialog(false);
    };
    const handleRequestSort = (event, property) => {
        
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    React.useEffect(() => {
        let _subTypeList = Object.assign([], props.subType)
        let _classificationCodeList = Object.assign([], props.classificationCode)
        
        SetSubType(_subTypeList);
        SetClassificationCode(_classificationCodeList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subType, props.classificationCode])
    React.useEffect(() => {
        resetSearch()
    }, [])
    const CheckVVPM = (documentNumber, value) => {
        let list = Object.assign([], props.vvpmIds);
        let VVPMDetailsObj = Object.assign({}, props.affiliateObjModel);
        let vvpmList = [];// Object.assign([], VVPMDetailsObj[props.affiliateSelected.country_code].rows);//Object.assign([],props.VVPMDetails);
        let subVVPMList = []
        for (let i = 0; i < vvpmList.length; i++) {
            if (vvpmList[i].document_number__v) {
                subVVPMList.push(vvpmList[i]);
            }
        }
        if (!subVVPMList.includes(value)) {
            vvpmList.push(value);
        }
        let mapVVPM = Object.assign({}, props.vvpmAffiliateMap);
        mapVVPM[props.countryCode] = vvpmList
        props.SetVVPMAffiliateMap(mapVVPM);
        props.SetVVPMDetails(vvpmList);
        if (!list.includes(documentNumber)) {
            list = []; // changes for radio button, for removing previous vvpm ids
            list.push(documentNumber);
        }

        props.SetVVPMIds(list);

        // if (Object.keys(VVPMDetailsObj)) {
        let _VVPMDetailsObjFrom = Object.assign({}, props.affiliateObjModel);
        
        VVPMDetailsObj[props.affiliateSelected.country_code] = {
            from: _VVPMDetailsObjFrom[props.affiliateSelected.country_code] && _VVPMDetailsObjFrom[props.affiliateSelected.country_code]?.from != '' ? _VVPMDetailsObjFrom[props.affiliateSelected.country_code].from : new Date(),
            to: GetMostRestrictiveDate(vvpmList),
            rows: vvpmList
        }
        props.SetAffiliateObjModel(VVPMDetailsObj);
        // SetInterAffiliateObjModel(VVPMDetailsObj);
    }
    const handleChk = (event, data) => {
        if (!event.target.checked) {
            let VVPMDetailsObj = Object.assign([], props.vvpmIds);
            let list = [];
            for (let i = 0; i < VVPMDetailsObj.length; i++) {
                if (!(VVPMDetailsObj[i] == data)) {
                    list.push(VVPMDetailsObj[i])
                }
            }
            // VVPMDetailsObj[props.affiliateSelected.country_code].rows = list;
            props.SetVVPMIds(list);
            let _VVPMDetailsObj = Object.assign({}, props.affiliateObjModel);
            let vvpmList = _VVPMDetailsObj[props.affiliateSelected.country_code] ? Object.assign([], _VVPMDetailsObj[props.affiliateSelected.country_code].rows) : [];
            for (let i = 0; i < vvpmList.length; i++) {
                if (vvpmList[i].document_number__v == data) {
                    props.removeVVPMDetails(event, vvpmList[i], props.affiliateSelected.country_code);
                }
            }

        }

    };
    const GetMostRestrictiveDate = (vvpmIds) => {
        if (Array.isArray(vvpmIds)) {
            let list = vvpmIds.map(v => new Date(v.expiration_date__vs));
            
            let sortedList = list.sort((a, b) => -new Date(b) + new Date(a));
            

            for (let i = 0; i < sortedList.length; i++) {
                if (sortedList[i].toString() != 'Invalid Date') {
                    return sortedList[i]
                }
            }
        }
        return new Date();

    }
    const VVPMIdCheckbox = (vvpmCode) => {
        return (props?.affiliateObjModel[props?.affiliateSelected?.country_code]?.rows?.filter(v => v.document_number__v == vvpmCode))?.length > 0
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getSearchValue = (value) => {
        // function to search ketyword and return rows from table while typing
        let list = [];
        let dummyList = Object.assign([], keywordLookupList);
        list = dummyList.filter(v => {
            if (v.document_number__v.toLowerCase().includes(value.toLowerCase())
                || v.name__v.toLowerCase().includes(value.toLowerCase())
                || v.subtype__v.toLowerCase().includes(value.toLowerCase())
                || v?.classification__v?.toLowerCase().includes(value.toLowerCase())
            ) {
                return v;
            }
        });
        if (value) {
            if (value.length > 0) {
                SetRows(list)
            } else {
                SetRows(dummyList)
            }
        } else {
            SetRows(dummyList)
        }
    };
    const resetSearch = () => {
        props.setnameSearch('');
        props.setvvpmCode('');
        SetapiLoaded(false)
        props.setSelectedsubType([]);
        props.SetSelectedClassificationCode([])
    }
    return (
        <div>
            <Dialog
                className='search-vvpm-dialog'
                open={props.openDialog}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px"
                        },
                    },
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3346 22.0013C13.7013 22.0008 15.9997 21.2085 17.864 19.7506L23.7253 25.612L25.6106 23.7266L19.7493 17.8653C21.208 16.0009 22.0007 13.7019 22.0013 11.3346C22.0013 5.4533 17.216 0.667969 11.3346 0.667969C5.4533 0.667969 0.667969 5.4533 0.667969 11.3346C0.667969 17.216 5.4533 22.0013 11.3346 22.0013ZM11.3346 3.33464C15.7466 3.33464 19.3346 6.92264 19.3346 11.3346C19.3346 15.7466 15.7466 19.3346 11.3346 19.3346C6.92264 19.3346 3.33464 15.7466 3.33464 11.3346C3.33464 6.92264 6.92264 3.33464 11.3346 3.33464Z" fill="#E02F2B" />
                        </svg>
                    </div>
                    <div className='modal-heading-text'>
                        <div>Search VVPM Code</div>
                        <div className='modal-heading-subtext'>Type your search and find the VVPM Document that suits best.</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent className='model-body-container' sx={{
                    "& .MuiDialogContent-root": {
                        padding: '0px 0px'
                    },
                }} >
                    <Grid className='modal-body'>
                        <Grid container className='content-input'>
                            <Grid className='content-field' item md={5} sm={5}>
                                <FormControl component="fieldset">
                                    <label className='input-label'>
                                        Name
                                    </label>
                                    <TextField
                                        className='text-input'
                                        variant="outlined"
                                        placeholder="Enter Name"
                                        size="small"
                                        onChange={(event) => {
                                            props.setnameSearch(event.target.value)
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                        value={props.nameSearch} />
                                </FormControl>
                            </Grid>
                            <Grid className='content-field' item md={2} sm={2}>
                                <div className='line' />
                            </Grid>
                            <Grid className='content-field' item md={5} sm={5}>
                                <FormControl component="fieldset">
                                    <label className='input-label'>
                                        VVPM Code
                                    </label>
                                    <TextField
                                        className='text-input'
                                        variant="outlined"
                                        placeholder="Enter VVPM Code"
                                        size="small"
                                        onChange={(event) => {
                                            props.setvvpmCode(event.target.value)
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                        value={props.vvpmCode} />
                                </FormControl>
                            </Grid>
                            <Grid className='content-field' item md={5} sm={5}>
                                <FormControl component="fieldset">
                                    <label htmlFor="vvpmsubtype-native-simple"
                                        className='input-label'>
                                        VVPM Subtype
                                    </label>
                                    <CustomSelect
                                        variant='outlined'
                                        value={props.selectedsubType[0] ? props.selectedsubType[0] : ""}
                                        displayEmpty
                                        onChange={(event) => {
                                            props.setSelectedsubType([event.target.value])
                                        }}
                                        renderValue={() => {
                                            if (!props.selectedsubType[0]) {
                                                return <span className='placeholder'>Select VVPM Subtype</span>;
                                            }
                                            return props.selectedsubType[0];
                                        }}
                                        inputProps={{
                                            name: 'VVPM Subtype',
                                            id: 'vvpmsubtype-native-simple',
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        {subType.map(x => (
                                            <MenuItem value={x}>{x}</MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>
                            </Grid>
                            <Grid className='content-field' item md={2} sm={2}>
                                <div className='line' />
                            </Grid>
                            <Grid className='content-field' item md={5} sm={5}>
                                <FormControl component="fieldset">
                                    <label htmlFor="vvpmsubtype-select"
                                        className='input-label'>
                                        Classification
                                    </label>
                                    <CustomSelect
                                        variant='outlined'
                                        value={props.selectedclassificationCode[0] ? props.selectedclassificationCode[0] : ""}
                                        displayEmpty
                                        onChange={(event) => { props.SetSelectedClassificationCode([event.target.value]) }}
                                        renderValue={() => {
                                            if (!props.selectedclassificationCode[0]) {
                                                return <span className='placeholder'>Select Classification</span>;
                                            }
                                            return props.selectedclassificationCode[0];
                                        }}
                                        inputProps={{
                                            name: 'Classification',
                                            id: 'classification-native-simple',
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        {/* <MenuItem value='' disabled>Select Classification</MenuItem> */}

                                        {classificationCode.map(x => (
                                            <MenuItem value={x}>{x}</MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className='modal-actions'>
                        <button className="modal-cancel" onClick={() => {
                            props.SetSelectedClassificationCode([])
                            props.setSelectedsubType([])
                            handleClose()
                        }}>Cancel</button>
                        <button className="modal-download" onClick={() => {
                            setOpenSecondaryModal(true)
                            SetapiLoaded('');
                            SetRows([])
                            props.SetVVPMIds([])
                            SearchVVPM()
                            SetselectedRow({})
                            setPage(0)
                            props.setlookupSearch('')
                        }}>
                            Search
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog
                className='search-vvpm-secondary-dialog'
                open={openSecondaryModal}
                fullWidth={'xl'}
                maxWidth={'xl'}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                        },
                    },
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { setOpenSecondaryModal(false) }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3346 22.0013C13.7013 22.0008 15.9997 21.2085 17.864 19.7506L23.7253 25.612L25.6106 23.7266L19.7493 17.8653C21.208 16.0009 22.0007 13.7019 22.0013 11.3346C22.0013 5.4533 17.216 0.667969 11.3346 0.667969C5.4533 0.667969 0.667969 5.4533 0.667969 11.3346C0.667969 17.216 5.4533 22.0013 11.3346 22.0013ZM11.3346 3.33464C15.7466 3.33464 19.3346 6.92264 19.3346 11.3346C19.3346 15.7466 15.7466 19.3346 11.3346 19.3346C6.92264 19.3346 3.33464 15.7466 3.33464 11.3346C3.33464 6.92264 6.92264 3.33464 11.3346 3.33464Z" fill="#E02F2B" />
                        </svg>
                    </div>
                    <div className='modal-heading-text'>
                        <div>Search Results</div>
                        <div className='modal-heading-subtext'>Select only one VVPM Document from the list.</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                props.setnameSearch('')
                                props.setvvpmCode('')
                                props.SetSelectedClassificationCode([])
                                props.setSelectedsubType([])
                                setOpenSecondaryModal(false);
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent >
                    <Grid className='modal-body'>
                        <div className='content-input'>
                            <div className='vvpm-search-section'>
                                <div className='search-selection'>
                                    <label>Open Search</label>
                                    <div>{props.nameSearch != '' ? props.nameSearch : '-'}</div>
                                </div>
                                <div className='search-selection'>
                                    <label>Country</label>
                                    <div>{props?.activeCountry?.country_name}</div>
                                </div>
                                <div className='search-selection'>
                                    <label>VVPM Code</label>
                                    <div>{props.vvpmCode != '' ? props.vvpmCode : '-'}</div>
                                </div>
                                <div className='search-selection'>
                                    <label>VVPM Subtype</label>
                                    <div>{props.selectedsubType.length ? props.selectedsubType[0] : '-'}</div>
                                </div>
                                <div className='search-selection'>
                                    <label>Classification</label>
                                    <div>{props.selectedclassificationCode.length ? props.selectedclassificationCode[0] : '-'}</div>
                                </div>
                                <div className='select-docs search-selection'>
                                    <label>Selected Document</label>
                                    <div>
                                        <FormControl component="fieldset">
                                            {selectedRow?.documentNumber ? <FormControlLabel
                                                value={'checkbox'}
                                                size="small"
                                                className='selected-document'
                                                checked={true}
                                                control={<CustomCheckbox onChange={
                                                    (e) => {
                                                        
                                                    }
                                                }
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                                label={selectedRow?.documentNumber}
                                                labelPlacement="end"
                                            /> : ""}
                                            {/* )) : ""} */}


                                        </FormControl>
                                        <div className='helper-text'>
                                            The selected document will appear here.
                                        </div>
                                    </div>
                                </div>
                                <div className='new-search' onClick={() => { resetSearch(); SetselectedRow({}); SetRows([]); setOpenSecondaryModal(false) }}>
                                    <button> New Search </button>
                                </div>
                            </div>
                            <div className='table-section'>
                                <TableContainer>
                                    <EnhancedTableHead numSelected={rows.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
                                    <div className='table-body'>

                                        {apiLoaded ? rows.length ? stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <div hover
                                                    className='table-row'
                                                    onClick={(event) => {}}
                                                    role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row}
                                                    selected={console.log(row.document_number__v)}
                                                >
                                                    <div className='table-cell' align="center">
                                                        <FormControlLabel
                                                            className='labelTxt-user'
                                                            control={<Radio
                                                                name="radio"
                                                                checked={selectedRow.documentNumber == row.document_number__v}
                                                                onChange={(e) => {
                                                                    SetselectedRow({ documentNumber: row.document_number__v, row: row })

                                                                }}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }} />}

                                                            labelPlacement="end"
                                                        />
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{`${row?.document_number__v}`}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{`${row?.name__v}`}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{`${row?.subtype__v}`}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{`${row?.classification__v}`}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{row?.expiration_date__vs}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{''}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{''}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{''}</div>
                                                    </div>
                                                    <div className='table-cell' align="left">
                                                        <div>{''}</div>
                                                    </div>
                                                </div>
                                            )) :
                                            <div
                                                className='table-row loader'
                                            >
                                                <div align="center">
                                                    <p>No data found!</p>
                                                </div>
                                            </div>
                                            :
                                            <div
                                                className='table-row loader'
                                            >
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                                <div align="center">
                                                    <Skeleton animation="wave" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </TableContainer>
                            </div>
                        </div >
                    </Grid >
                </DialogContent >
                <DialogActions>
                    <div className='modal-actions'>
                        <div className='modal-search'>
                            <div>
                                <TextField
                                    id="filled-disabled"
                                    className='vvpmlookup'
                                    // disabled
                                    value={props.lookupSearch}
                                    size="small"
                                    variant="outlined"
                                    placeholder='Keyword Lookup'
                                    margin="normal"
                                    onKeyUp={(e) => {
                                        getSearchValue(e.target.value)

                                    }}
                                    onChange={(e) => {
                                        props.setlookupSearch(e.target.value)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className='pagination'>
                                <div>Showing <strong>{(page * 10) + 1}</strong> to <strong>{(page + 1) * 10}</strong> of {rows.length} entries</div>
                                <div onClick={handlePaginationPrevious} className='navigation-btn '> <ArrowBackIosIcon /> </div>
                                <div onClick={handlePaginationNext} className='navigation-btn'> <ArrowForwardIosIcon /> </div>
                            </div>
                        </div>
                        <div className='modal-buttons'>
                            <button className="modal-cancel" onClick={() => {
                                props.setnameSearch('')
                                props.setvvpmCode('')
                                props.SetSelectedClassificationCode([])
                                props.setSelectedsubType([])
                                setOpenSecondaryModal(false)
                            }}>Cancel</button>
                            <button className={selectedRow?.documentNumber ? "modal-download" : "modal-download disabled"} onClick={() => {
                                props.SetSelectedClassificationCode([])
                                props.setSelectedsubType([])
                                CheckVVPM(selectedRow.documentNumber, selectedRow.row)
                                setOpenSecondaryModal(false)
                                props.SetOpenDialog(false)
                            }}>
                                Select Document
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog >
        </div >
    );
}