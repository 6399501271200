import React from 'react';
import { Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { TranslationService } from '../services/translation-service';

const TranslationEdit = (props) => {
    const [names, setName] = React.useState('');
    const activeUser = localStorage.getItem('userID');
 
    React.useEffect(() => {
        setName(props.data.text)
    }, [props]);

    /**Function submits all the data for the created user */
    const handleSubmit = () => {
        let text = props.data.text
        if (names != null){
            text = names;
        }
        let data = {
            locale:props.data.locale,
            text: text,
            key: props.data.key,
            updated_at: new Date(),
            updated_by:  activeUser,
            translation_id:props.data.id
        }
        let service = new TranslationService();
        service.translationEdit(data).then((data) => {
            props.closePopUp(false);    
        })
        
    }

    const handleFormDataChange = (event) => {
        let value = event.target.value;
        setName(value)
    };

    return (
        <>
            <Grid className='paper modalstyle'>
                <Grid className="modal-title">
                    Translation Edit:
                    <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </Grid>
                
                <Grid className="modal-body">
                <FormControl component="fieldset" className='formControl'>
                <label className='modalLabel lableTranslationEdit'>Select Locale :</label>
                        <TextField disabled={true} variant="outlined" placeholder="Locale Name" name="Locale_name" size="small" InputLabelProps={{ shrink: false }} value={props.data.locale}/>
                    </FormControl>
 
                    <FormControl component="fieldset" className='formControl'>
                    <label className='modalLabel lableTranslationEdit'>Translation Key :</label>
                        <TextField  disabled={true} variant="outlined" placeholder="Key" name="key" size="small" InputLabelProps={{ shrink: false }}  value={props.data.key}/>
                    </FormControl>
                    <label className='modalLabel lableTranslationEdit'>Translation Text :</label>
                    <FormControl component="fieldset" className='formControl'>
                    
                        <TextField  variant="outlined" placeholder="Text" name="text" size="small" multiline minRows={3} onChange={handleFormDataChange} value={names}/>
                    </FormControl>
                    </Grid>
                    <Grid className="modal-footer">
                    <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
                    <button type="button" className="modalBtn" onClick={() => { handleSubmit();  }}>OK</button>
                
                    </Grid>
                {/**End Create User Profile Section */}
            </Grid>
        </>
    )
}
export default TranslationEdit;






