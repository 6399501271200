import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { PlaylistService } from '../Service/playlist-service';
import Modal from '@material-ui/core/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from '@material-ui/core/Switch';
import addIMG from '../../../public/img/Add@3x.png';
import editIMG from '../../../public/img/GreyEdit@3x.png';
import { ContentService } from '../../content/Service/content-service';

const PreviewAndType = (props) => {
  let playlistService = new PlaylistService();
  let contentService = new ContentService();
  const [userData, setUserData] = React.useState([]);
  const [ownerName, setOwnerName] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openOwnerModal, setOpenOwnerModal] = React.useState(false);
  const [secondOwner, setSecondOwner] = React.useState('');
  const [owner, setOwner] = React.useState('');
  const [secondOwnerName, setSecondOwnerName] = React.useState('');

  const imgPreviewHandler = (file) => {
    
    props.SetS3UploadCheck(false);
    props.SetImageDimensionCheck(false);
    props.SetImageSizeCheck(false);
    props.SetImageTypeCheck(false);
    props.SetS3UploadErrorCheck(false);

    if (file) {

      //Check for file size - 2 MB 
      let imageByteSize = file.size;
      if(imageByteSize > 2097152){ //2MB Limit
        props.SetS3UploadCheck(true);
        props.SetImageSizeCheck(true);
        return;
      } else {
        props.SetImageSizeCheck(false);
      }

      //Check for file type
      let imageType = file.type;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(imageType)) {
        props.SetS3UploadCheck(true);
        props.SetImageTypeCheck(true);
        return;
      } else {
        props.SetImageTypeCheck(false);
      }
      
      const reader = new FileReader();
      // reader.readAsDataURL(file);
      // let key = props.isEditMode != 0? 'Playlist/'+ props.isEditMode+'/'+ file.name : 'Playlist/'+ (new Date()).getTime().toString()+ '-'+ file.name // s3FileChange
      let key = 'Country/Banner/'+ (new Date()).getTime().toString()+ '-' + file.name ;
      reader.onload = () => {

        let image = new Image();
        image.onload = function () {
            let width = this.width;
            let height = this.height;
            if (!(width === 1280 && height === 720)) {
              props.SetS3UploadCheck(true);
              props.SetImageDimensionCheck(true);
            } else {

              playlistService.UploadFileToS3(key, reader.result).then((data)=> {
                props.SetThumbnailUrl(key);
                props.setImgPreview(reader.result);
                props.setIsCustomImage(true);
              }).catch(async (err)=> {
                let errorObj = {
                  user_id: props.created_by,
                  thumbnail_name: key, 
                  log_event_type:"Playlist", 
                  thumbnail_size: imageByteSize, 
                  thumbnail_height: height, 
                  thumbnail_width: width, 
                  thumbnail_type: imageType, 
                  record_id: props.id,
                  error_code: err.status?err.status:'',
                  error_message: err.message
                }
                try {
                    await contentService.UpdateS3ImagesErrorLogs(errorObj);
                } catch (saveerr) {  }
                  props.SetS3UploadCheck(true);
                  props.SetS3UploadErrorCheck(true);
              });

            }
        }
        image.src = reader.result;
      }
      // reader.readAsArrayBuffer(file);
      reader.readAsDataURL(file);
    }
  }


  React.useEffect(() => {
    GetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.owner, props.secondOwner]);
  React.useEffect(() => {
    GetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const GetUsers = () => {
    playlistService.GetAllUsers().then((data) => {
      let jsondata = data.data;
      let arr = []
      for (let i = 0; i < jsondata.length; i++) {
        if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
        && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
          jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
          jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
          arr.push(jsondata[i])
        }
      }
      setUserData(arr);
      if (props.id !== 0) {
        let ownerId = props.owner;
        let secondOwnerId = props.secondOwner;
        for (let i = 0; i < jsondata.length; i++) {
          if (jsondata[i].id === ownerId) {
            setOwner(jsondata[i]);
            setOwnerName(`${jsondata[i].first_name} ${jsondata[i].last_name}`)
          }
          if (jsondata[i].id === secondOwnerId) {
            setSecondOwner(jsondata[i]);
            setSecondOwnerName(`${jsondata[i].first_name} ${jsondata[i].last_name}`)
          }
        }
      }
    }).catch((err) => {});
  }

  const handleChangeSwitch = (event) => {
    if (event.target.checked)
      props.setIsSeries(1);
    else
      props.setIsSeries(0);
  };

  const saveSecondOwner = () => {
    setSecondOwnerName(secondOwner.user_name);
    setSecondOwner(secondOwner);
    props.SetSecondOwner(secondOwner.id)
    setOpenModal(false);
  }
  const saveOwner = () => {
    setOwnerName(owner.user_name);
    props.setOwner(owner.id)
    props.Setcreated_by(owner.id)
    setOpenOwnerModal(false);
  }
  const handleSecondOwner = () => {
    setOpenModal(true);
  }
  const handleOwner = () => {
    setOpenOwnerModal(true);
  }
  const handleModalClose = () => {
    setOpenModal(false);
  };  
  const handleOwnerModalClose = () => {
    setOpenOwnerModal(false);
  };

  return (
    <>
      <Modal open={openOwnerModal} onClose={handleOwnerModalClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            Create Owner
            <button className='closeModal' onClick={() => handleOwnerModalClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="text-center row_inner_box">
              <FormControl component="fieldset">
                <FormLabel component="legend">Select the Owner</FormLabel>
                <Autocomplete
                  style={{ width: 300 }}
                  options={userData}
                  value={owner}
                  onChange={(event, newValue) => {
                    setOwner(newValue);
                  }}
                  getOptionLabel={(option) => option.fullName || ""}
                  renderInput={(params) => <TextField {...params} placeholder="Search by name of user" name="owner" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid className="modal-footer">
            <button type="button" onClick={() => handleOwnerModalClose()} className="modalBtn closeBtn">Close</button>
            <button className='modalBtn' onClick={saveOwner}>Save changes</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openModal} onClose={handleModalClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            Create Second Owner
            <button className='closeModal' onClick={() => handleModalClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="text-center row_inner_box">
              <FormControl component="fieldset">
                <FormLabel component="legend">Select the Owner</FormLabel>
                <Autocomplete
                  style={{ width: 300 }}
                  options={userData}
                  value={secondOwner}
                  onChange={(event, newValue) => {
                    setSecondOwner(newValue);
                  }}
                  getOptionLabel={(option) => option.fullName || ""}
                  renderInput={(params) => <TextField {...params} placeholder="Search by name of user" name="second_owner" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid className="modal-footer">
            <button type="button" onClick={() => handleModalClose()} className="modalBtn closeBtn">Close</button>
            <button className='modalBtn' onClick={saveSecondOwner}>Save changes</button>
          </Grid>
        </Grid>
      </Modal>
      <Grid container className="content grey_row">
        <Grid item lg={9} xs={12}>
          <Typography className='ds_title'>
            Enter your Playlist Details
          </Typography>
        </Grid>
        <Grid item lg={3} xs={12}>
          <div className="switch-hd">
            <Switch checked = {props.isSeries == 1 ? true : false} onChange={(e) => handleChangeSwitch(e)} name="checkedA" inputProps={{ 'aria-label': 'secondary checkbox' }} />
            <span className='hideTxt'>This playlist is a series</span>
          </div>
        </Grid>
      </Grid>
      <Grid container className="content grey_row">
        <Grid item lg={8} md={8} sm={7} xs={12} className="ds_form padRight2">
          <FormControl component="fieldset">
            <FormLabel component="legend">Playlist Title (For multiple countries, it is recommended to have the content title and description in English.)<sup>*</sup></FormLabel>
            <TextField variant="outlined" placeholder="Playlist Title" name="content_title" className='plistField' size="small"
              onChange={(event) => { props.SetTitle(event.target.value) }}
              InputLabelProps={{ shrink: false }}
              value={props.title}
              inputProps={{ maxLength: 60 }}
            />
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Description </FormLabel>
            <TextField variant="outlined" placeholder="Playlist Description" name="content_desc" className='plistField' size="small" multiline minRows={3}
              onChange={(event) => { props.SetDescription(event.target.value) }}
              InputLabelProps={{ shrink: false }} value={props.description} inputProps={{ maxLength: 5000 }} />
          </FormControl>
          {props.isEditMode !== 0 ?
            <Grid>
              <FormControl component="fieldset" className="reducePadding">
                <FormLabel component="legend" className='flex'>
                  <span className='font14'>Status :</span>&nbsp;<span className="normalFont">{props.status}</span>
                </FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="reducePadding">
                <FormLabel component="legend" className='flex'>
                  <span className='font14'>Owner :</span>&nbsp;<span className="normalFont">{ownerName}</span>&nbsp;
                  <img src={editIMG} className="editImage" width='18px' alt="Create Owner" onClick={()=> handleOwner()} />
                </FormLabel>
              </FormControl>
            </Grid>
            : null}
          <FormControl  className="reducePadding">
            <FormLabel  className='flex'>
              <span className='font14'>Create Second Owner :</span>&nbsp;{secondOwnerName ? <span className="normalFont">{secondOwnerName}</span> : ""}&nbsp;
              <img src={editIMG} className="editImage" width='18px' alt="Create Second Owner" onClick={()=> handleSecondOwner() } />
            </FormLabel>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={5} xs={12}>
          <FormControl component="fieldset">
            <input accept="image/*" name="thumbnail" className='hidden' id="thumbnail-button-file" multiple type="file" onChange={(event) => { imgPreviewHandler(event.target.files[0])}} />
            <label htmlFor="thumbnail-button-file">
            {(props.imgPreview) ? <div className="text-center p-3"><img src={props.imgPreview} alt="imgpreview" width="100%"/></div> :null}
              <Button className='addIconBtn' component="span">
                <img src={addIMG} alt="Upload Video or Podcast Thumbnail" />
                Upload Playlist Thumbnail
              </Button>
            </label>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
export default PreviewAndType