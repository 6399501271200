import React from 'react';
import { Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { TranslationService } from '../services/translation-service';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

const TranslationCreate = (props) => {
    const [uniqueLocale, setuniqueLocale] = React.useState([]);
    const [uniqueKey, setuniqueKey] = React.useState([]);
    const [transLocale, settransLocale] = React.useState('');
    const [transKey, settransKey] = React.useState('');
    const [transText, settransText] = React.useState('');
    const [validationPopup, setvalidationPopup] = React.useState(false);
    const activeUser = localStorage.getItem('userID');

    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    React.useEffect(() => {
        let uLocale = [];
        let uText = [];
        for (let i = 0; i < props.data.length; i++) {
            if (!uLocale.includes(props.data[i].locale)) {
                uLocale.push(props.data[i].locale)
            }
            if (!uText.includes(props.data[i].key)) {
                uText.push(props.data[i].key)
            }
        }
        setuniqueLocale(uLocale)
        setuniqueKey(uText)
    }, [props.data]);

    /**Function submits all the data for the created user */
    const handleSubmit = () => {
        if (transLocale == '' || transKey == '' || transText == '') {
            setvalidationPopup(true);
            return;
        }
        let data = {
            locale: transLocale,
            key: transKey,
            text: transText,
            status: 1,
            updated_by: activeUser,
            created_by: activeUser,
            created_at: new Date(),
            updated_at: new Date()
        }
        let service = new TranslationService();
        service.translationCreate(data).then((data) => {
            props.closePopUp(false);
        })

    }

    // const handleFormDataChange = (event) => {
    //     let name = event.target.name;
    //     let value = event.target.value;
    //     setName(value)
    // };

    const handleSelect = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'transLocale') {
            settransLocale(value)
        }
        if (name === 'transKey') {
            settransKey(value)
        }
        if (name === 'transText') {
            settransText(value)
        }
    }

    const handlvalidationPopup = () => {
        setvalidationPopup(false)
    }

    return (
        <>
            <Modal open={validationPopup} onClose={handlvalidationPopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handlvalidationPopup()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            {/* /        if(transLocale== '' || transKey== '' || transText==''){ */}
                            {transLocale == '' &&
                                <Alert severity="warning">Translation Locale: Translation Locale cannot be left empty.</Alert>
                            }
                            {transKey == '' &&
                                <Alert severity="warning">Translation Key: Translation Key cannot be left empty.</Alert>
                            }
                            {transText == '' &&
                                <Alert severity="warning">Translation Text: Translation Text cannot be left empty.</Alert>
                            }
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        {/* <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button> */}
                        <button type="button" onClick={() => handlvalidationPopup()} className="modalBtn">Close</button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid className='paper modalstyle'>
                <Grid className="modal-title">
                    Translation Create:
                    <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </Grid>
                <Grid class="modal-body">

                    <Grid item xs={9} lg={12}>
                        <label className='modalLabel lableTranslationEdit'>Translation Locale :</label>
                        <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            className="formControl selectClas"
                            name="transLocale"
                            onChange={handleSelect}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {uniqueLocale.map((locale, i) => (
                                <MenuItem key={i} value={locale} style={getStyles(locale, locale, theme)}>
                                    {locale}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={9} lg={12}>
                        <label className='modalLabel lableTranslationEdit'>Translation Key :</label>
                        <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            className="formControl selectClas"
                            name="transKey"
                            onChange={handleSelect}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {uniqueKey.map((locale, i) => (
                                <MenuItem key={i} value={locale} style={getStyles(locale, locale, theme)}>
                                    {locale}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <FormControl component="fieldset" className='formControl'>
                        <label className='modalLabel lableTranslationEdit'>Translation Text :</label>
                        <TextField name="transText" className="textAreaStyle" variant="outlined" multiline minRows={3} size="small" onChange={handleSelect} />
                    </FormControl>
                </Grid>
                <Grid className="modal1FoBtn modal-footer">
                    <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
                    <button type="button" className="modalBtn" onClick={() => { handleSubmit(); }}>OK</button>
                </Grid>
            </Grid>
        </>
    )
}
export default TranslationCreate;






