import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import removeVideo from '../../../public/img/rejectEnabled.png';
import dndIcon from '../../../public/img/dndIcon.svg';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const FixedMenu = (props) => {
    const handleSelect = (value, index) => {
        let fixedMenusArr = [...props.fixedMenus];
        fixedMenusArr[index] = value;
        props.SetFixedMenus(fixedMenusArr);
    }
    const handleDragEnd = (results) => {
        if (!results.destination) return;
        let tempDetail = [...props.fixedMenus];
        let [selectedRow] = tempDetail.splice(results.source.index, 1);
        tempDetail.splice(results.destination.index, 0, selectedRow);
        props.SetFixedMenus(tempDetail);
    }
    const addFixedMenu = () => {
        props.SetFixedMenus([...props.fixedMenus, ""]);
    }
    const removeFixedMenu = (fixedMenuID) => {
        var array = [...props.fixedMenus];
        var filtered = array.filter((item) => { return item !== fixedMenuID; });
        props.SetFixedMenus(filtered);
    }
    
    return (
        <>
            <Grid container className='content'>
                <Grid container item xs={12} lg={12} spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="label">Edit Fixed Menu</FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                            <table className="fixedMenuTbl">
                                <Droppable droppableId="table-body">
                                    {(provided) => (
                                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                            {props.fixedMenus.length > 0 ? props.fixedMenus.map((fixedMenu, index) => {
                                                return (
                                                    <Draggable draggableId={fixedMenu} key={fixedMenu}
                                                        index={index}>
                                                        {(provided) => (
                                                            <tr ref={provided.innerRef} {...provided.draggableProps}>
                                                                <td width="80%">
                                                                    <Select value={fixedMenu} className='selectBx' onChange={(e) => handleSelect(e.target.value, index)} label="Select Therapeutic Areas" placeholder="Select Therapeutic Areas" disableUnderline='true'>
                                                                        {props.therapeuticAreas.length > 0 ? props.therapeuticAreas.map((therapeuticArea, i) => {
                                                                            let disabledBool = props.fixedMenus.includes(therapeuticArea.therapeutic_area_id) ? true : false;
                                                                            let selectedBool = (fixedMenu === therapeuticArea.therapeutic_area_id) ? true : false;
                                                                            return (
                                                                                <MenuItem key={i} selected={selectedBool} disabled={disabledBool} value={therapeuticArea.therapeutic_area_id}>{therapeuticArea.therapeutic_area_name}</MenuItem>
                                                                            )
                                                                        }) : null}
                                                                    </Select>
                                                                </td>

                                                                <td width="20%">
                                                                    <Grid className='fixed-menu-div' container item lg={12}>
                                                                        <Grid item lg={6} xs={6} >
                                                                            <img src={removeVideo} className="approvedIcon" onClick={() => removeFixedMenu(fixedMenu)} alt="remove Video" />

                                                                        </Grid>
                                                                        <Grid item lg={6} xs={6} {...provided.dragHandleProps} >
                                                                            <img src={dndIcon} className="approvedIcon" alt="drag row" />

                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </Draggable>
                                                )
                                            }): null}
                                            {provided.placeholder}
                                        </tbody>
                                    )}

                                </Droppable>

                            </table>

                        </DragDropContext>

                        {props.fixedMenus.length <= 3 ?
                            <Grid className='itemAdd' onClick={() => addFixedMenu()}>
                                <img src={require('../../../public/img/Add@3x.png')} className='itemAddIcon' alt='img' />
                                <span>Add Item</span>
                            </Grid>
                            : null}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FixedMenu