
import axios from 'axios';
import { ContentService } from '../../content-watch/Service/ContentService';
import { setDimension } from '../../../Session/PlayerDimension';
import { KalturaService } from '../../content/Service/KalturaService';
import { GoogleAnalyticsService } from '../../../GA/GoogleAnalyticsService';
class VideoPlayerService extends KalturaService {

  constructor() {
    super();
    this.initialWidth = 0;
    this.initialHeight = 0;
    this._gaService = new GoogleAnalyticsService();
  }
  /** Get video details function */
  PushKDPEvents(model) {
    this._gaService.PushObjectInDatalayer(model);
  }
  async GetPlaylistData() { }
  async GetSpeakersById(ids) {
    const authHeader = {
      headers: {
        'Content-Type': 'application/json',
        'authorizationtoken': localStorage.getItem('jwtToken')
      }
    };
    let data = await axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersById', ids, authHeader);
    return data.data;
  };
  async GetContentData(kalturaId, contentId, SetNextKalturaId, SetPreviousKalturaId) {
    const authHeader = {
      headers: {
        'Content-Type': 'application/json',
        'authorizationtoken': localStorage.getItem('jwtToken')
      }
    };
    if (contentId.includes('CON')) {
      let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQueryRaw?content_id=' + contentId, {}, authHeader);
      if (Array.isArray(data.data)) {
        for (let i = 0; i < data.data.length; i++) {
          let video = data.data[i];
          if (video.content_id == contentId) {
            return video;
          }
        }
      }
      return data.data[0];
    }
    if (contentId.includes('PL')) {
      let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/playlist/GetAllPlaylistRaw?playlist_id=' + contentId, {}, authHeader);
      if (Array.isArray(data.data)) {
        for (let i = 0; i < data.data.length; i++) {
          let playlist = data.data[i];
          let videoPlaylistReturn = null;
          let previousData = null
          for (let k = 0; k < playlist.content_ids.length; k++) {
            let contentIdOfPlaylist = playlist.content_ids[k];
            let playlistContent = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQueryRaw?content_id=' + contentIdOfPlaylist, {}, authHeader);

            if (Array.isArray(playlistContent.data)) {
              for (let i = 0; i < playlistContent.data.length; i++) {
                let videoPlaylist = playlistContent.data[i];

                if (!videoPlaylistReturn) {
                  if (videoPlaylist.kaltura_id == kalturaId) {
                    videoPlaylistReturn = videoPlaylist;
                    if (previousData) {
                      SetPreviousKalturaId(previousData.kaltura_id);
                    }
                  } else {
                    previousData = videoPlaylist;
                  }
                } else {
                  SetNextKalturaId(videoPlaylist.kaltura_id);
                  return videoPlaylistReturn
                }

              }
            }
          }
          return videoPlaylistReturn;
        }
      }
    }
  }
  UpdateDimensions(dispatch, playerDimensions) {
    dispatch(setDimension(playerDimensions));
  }
  /** Initilize Kalutra Player and its configurations */
  NormalizedData(filteredData) {
    let multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map(n => n * multiplier);
  }
  CreateFilteredData() {
    let list = [];
    for (let i = 0; i < 100; i++) {
      list.push(Math.random() * 1000)
    }
    return list;
  }
  DrawSegment(ctx, x, y, width, isEven) {
    ctx.lineWidth = 1; // how thick the line is
    ctx.strokeStyle = "#fff"; // what color our line is
    ctx.beginPath();
    y = isEven ? y : -y;
    ctx.moveTo(x, 0);
    ctx.lineTo(x, y);
    ctx.arc(x + width / 2, y, width / 2, Math.PI, 0, isEven);
    ctx.lineTo(x + width, 0);
    ctx.stroke();
  }
  Draw(normalizedData) {
    let canvas = document.getElementById('myCanvas');
    if (canvas) {
      let dpr = window.devicePixelRatio || 1;
      let padding = 20;
      canvas.width = this.initialWidth == 0 ? canvas.offsetWidth * dpr : this.initialWidth;
      canvas.height = this.initialHeight == 0 ? (canvas.offsetHeight + padding * 2) * dpr : this.initialHeight;
      if (this.initialWidth == 0 && this.initialHeight == 0) {
        this.initialWidth = canvas.width;
        this.initialHeight = canvas.height;
      }

      let ctx = canvas.getContext('2d');
      ctx.scale(dpr, dpr);
      ctx.translate(0, canvas.offsetHeight / 2 + padding);
      let width = canvas.offsetWidth / normalizedData.length;
      for (let i = 0; i < normalizedData.length; i++) {
        let x = width * i;
        let height = normalizedData[i] * canvas.offsetHeight - padding;
        if (height < 0) {
          height = 0
        }
        else if (height > canvas.offsetHeight / 2) {
          height = canvas.offsetHeight / 2;
        }
        this.DrawSegment(ctx, x, height, width, (i + 1) % 2);
      }
    }

  }
  ResettingPosition(revertState) {
    let subDiv = document.getElementById('mini_player_lp');
    let mainCoordinates = revertState;
    subDiv.style.left = mainCoordinates.left + 'px'
    subDiv.style.top = mainCoordinates.top + 'px'
    subDiv.style.bottom = mainCoordinates.bottom + 'px'
    subDiv.style.width = mainCoordinates.width + 'px'
    subDiv.style.right = mainCoordinates.right + 'px'
    subDiv.style.height = mainCoordinates.height + 'px'
  }
  SettingPosition() {
    let subDiv = document.getElementById('mini_player_lp')
    let div = document.getElementById('palyer-widget');
    if (div) {
      let mainCoordinates = div.getBoundingClientRect();
      subDiv.style.left = mainCoordinates.left + 'px'
      subDiv.style.top = mainCoordinates.top + 'px'
      subDiv.style.bottom = mainCoordinates.bottom + 'px'
      subDiv.style.width = mainCoordinates.width + 'px'
      subDiv.style.right = mainCoordinates.right + 'px'
      subDiv.style.height = mainCoordinates.height + 'px'
    }

  }
  async GetVideoInformation(kalturaId, SetDuration) {
    if (!localStorage.getItem('ks')) {
      await this.GetKalturaSession()
    }
    let videoData = await this.CheckValidKalturaID(kalturaId, SetDuration);

  }
  MuteUnmuteVideo(playerId, value) {
    let kdp = document.getElementById(playerId);
    kdp.sendNotification("changeVolume", value);
  }
  SeekVideo(playerId, time) {
    let kdp = document.getElementById(playerId);
    kdp.sendNotification("doSeek", time);
    return true;
  }
  PausePlayVideo(playerId, command) {
    let kdp = document.getElementById(playerId);
    kdp.sendNotification(command);
    return true;
  }

  async InitializePlayer(kalturaId, time, setProgress, countryCode, hcp_id, SetPlayerId, setPosition,
    SetminiPlayerId, SetIsPlaying, contentId, SetNextKalturaId, SetPreviousKalturaId, playerEndFunciton, playerShow, autoplay, percentElapsed, userId, quizMandatoryStatus) {
    if (window.innerWidth <= 520) {
      console.log("-", autoplay);
      //New V7 Kaltura Player Implement and Remove Old Code from Here.
      let seekBarInit;
      if (quizMandatoryStatus) {
        if (localStorage.getItem('HCP') === 'false') {
          seekBarInit = {
            preventSeekForward: false,
            preventSeek: true
          };
        }
      } else {
        seekBarInit = {
          disable: true
        };
      }
      let playerId = 'miniutreplayer';
      let setupVars = {
        targetId: playerId,
        provider: {
          partnerId: 1759891,
          uiConfId: 53515872
        },
        // disableUserCache: false,
        playback: {
          pictureInPicture: false,
          autopause: false,
          autoplay: autoplay,
        },
        plugins: {
          "navigation": {
            disable: true
          },
          "playkit-js-info": {
            disable: true
          },
          "floating": {
            disable: true
          },
          "preventSeek": seekBarInit
        },
        'width': '100%',
        'height': '400px',
      }
      function getDateTime() {
        var d = new Date();
        let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
          d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        return datestring;
      }
      let service = new ContentService();
      let timeCurrent = 0;
      let sentPercentage = [];
      SetPlayerId(playerId);
      SetminiPlayerId(playerId);
      let kalturaPlayer = window.KalturaPlayer.setup(setupVars);
      window.KalturaPlayer.getPlayers()['miniutreplayer'].addEventListener(
        window.KalturaPlayer.getPlayers()['miniutreplayer'].Event.Core.TIME_UPDATE, async (event) => {
          const percent = parseFloat((kalturaPlayer.currentTime / kalturaPlayer.duration).toFixed(2));
          // let timeNew = (kalturaPlayer.currentTime) / 1000;
          setPosition(kalturaPlayer.currentTime);
          //Keep Watching Functionality Here.  -- V2 playerUpdatePlayhead & playerUpdatePlayhead.myPluginName
          // let time = 0;
          // let videoLength = kalturaPlayer.duration;
          // let currentPerce = percent;
          timeCurrent = parseInt(kalturaPlayer.currentTime);
          let totalDuration = kalturaPlayer.duration;
          let currentPercent = parseInt((parseInt(kalturaPlayer.currentTime) / parseInt(totalDuration)) * 100);
          let insertObj = {
            user_id: userId,
            content_id: contentId,
            playlist_id: null,
            watched_percent: currentPercent,
            watched_on: new Date(),
            country_code: countryCode
          };
          if ((currentPercent === 0 || currentPercent % 2 === 0) && !sentPercentage.includes(currentPercent)) {
            sentPercentage.push(currentPercent)
            service.PostWatchedContent(insertObj).then(data => {
            }).catch(err => { })
          }
          if (percent == 0.25 && !localStorage.getItem(contentId + '25')) {
            let obj = {
              log_action: "25%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '25%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '25', true);
            await service.UpdateLogViews(obj);
          }
          if (percent == 0.50 && !localStorage.getItem(contentId + '50')) {
            let obj = {
              log_action: "50%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '50%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '50', true);
            await service.UpdateLogViews(obj);
          }
          if (percent == 0.75 && !localStorage.getItem(contentId + '75')) {
            let obj = {
              log_action: "75%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '75%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '75', true);
            await service.UpdateLogViews(obj);
          }
          if (percent > 0.25 && localStorage.getItem(contentId + '25')) {
            localStorage.removeItem(contentId + '25')
          }
          if (percent > 0.50 && localStorage.getItem(contentId + '50')) {
            localStorage.removeItem(contentId + '50')
          }
          if (percent > 0.75 && localStorage.getItem(contentId + '75')) {
            localStorage.removeItem(contentId + '75')
          }
        })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.MEDIA_LOADED, async (event) => {
        SetIsPlaying(true);
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PLAY, async (event) => {
        let obj = {
          log_action: "Play",
          content_id: contentId,
          country_code: countryCode,
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        let gaObject = {
          event: 'video',
          eventCategory: 'Video',
          eventAction: 'Play',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaObject);
        await service.UpdateLogViews(obj);
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PAUSE, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Pause',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "playerPaused",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENTER_FULLSCREEN, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Open Full Screen',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "openFullScreen",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.EXIT_FULLSCREEN, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Close Full Screen',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "closeFullScreen",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      //MEDIA & PLAYER ERROR - GA Entry
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ERROR, event => {
        console.info("Error: category =", event.payload.category);
        console.info("Error: code =", event.payload.code);
        console.info("Error: severity =", event.payload.severity);
        console.info("Error: name =", event.payload.data.name);
        //event.payload.category == 3 - MEDIA ERROR 
        //event.payload.category == 7 - PLAYER ERROR 
        let gaModel = {
          event: 'video',
          eventCategory: 'Video',
          eventAction: 'Kaltura Error',
          videoId: contentId
        }
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "playerError",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      });
      //Mute & Unmute - GA & LB Entry
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.MUTE_CHANGE, (event) => {
        if (event.payload.mute) {
          console.info("Muted");
          let gaModel = {
            event: 'video', eventCategory: 'Video',
            eventAction: 'Video Muted',
            videoId: contentId,
          }
          service.PushKDPEvents(gaModel);
          let obj = {
            log_action: "mute",
            content_id: contentId,//data.content_id,
            country_code: countryCode,//countryData ? countryData.country_code : '',
            okta_id: hcp_id,
            log_event_label: document.baseURI,
            log_event_date_time: getDateTime()
          };
          try {
            service.UpdateLogViews(obj);
          } catch (err) { }
        } else {
          console.info("Unmuted");
          let gaModel = {
            event: 'video',
            eventCategory: 'Video',
            eventAction: 'Video Un-Muted',
            videoId: contentId,
          };
          service.PushKDPEvents(gaModel);
          let obj = {
            log_action: "unmute",
            content_id: contentId,//data.content_id,
            country_code: countryCode,//countryData ? countryData.country_code : '',
            okta_id: hcp_id,
            log_event_label: document.baseURI,
            log_event_date_time: getDateTime()
          };
          try {
            service.UpdateLogViews(obj);
          } catch (err) { }
        }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENDED, async (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Complete',
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoId: contentId,
          videoVolume: kalturaPlayer.volume * 100
        };
        service.PushKDPEvents(gaModel);
        setPosition(0);
        let obj = {
          log_action: "Video Complete",
          content_id: contentId,
          country_code: countryCode,
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        let insertObj = {
          user_id: userId,
          content_id: contentId,
          watched_percent: 100,
          watched_on: new Date(),
          playlist_id: null,
          country_code: countryCode
        };
        try {
          await service.UpdateLogViews(obj);
          service.PostWatchedContent(insertObj).then(data => {
          }).catch(err => { })
        } catch (err) { }
      })
      kalturaPlayer.loadMedia({ entryId: kalturaId }, { startTime: time }).then((success) => {

      }).catch((error) => {

      });
    } else {
      console.log("-", autoplay);
      //New V7 Kaltura Player Implement and Remove Old Code from Here.
      let seekBarInit;
      if (quizMandatoryStatus) {
        if (localStorage.getItem('HCP') === 'false') {
          seekBarInit = {
            preventSeekForward: false,
            preventSeek: true
          };
        }
      } else {
        seekBarInit = {
          disable: true
        };
      }
      let playerId = 'miniutreplayer';
      let setupVars = {
        targetId: playerId,
        provider: {
          partnerId: 1759891,
          uiConfId: 53515872
        },
        // disableUserCache: false,
        playback: {
          pictureInPicture: false,
          autopause: false,
          autoplay: autoplay,
        },
        plugins: {
          "navigation": {
            disable: true
          },
          "playkit-js-info": {
            disable: true
          },
          "floating": {
            disable: true
          },
          "preventSeek": seekBarInit
        },
        'width': '100%',
        'height': '400px',
      }
      function getDateTime() {
        var d = new Date();
        let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
          d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        return datestring;
      }
      let service = new ContentService();
      let timeCurrent = 0;
      let sentPercentage = [];
      SetPlayerId(playerId);
      SetminiPlayerId(playerId);
      let kalturaPlayer = window.KalturaPlayer.setup(setupVars);
      window.KalturaPlayer.getPlayers()['miniutreplayer'].addEventListener(
        window.KalturaPlayer.getPlayers()['miniutreplayer'].Event.Core.TIME_UPDATE, async (event) => {
          const percent = parseFloat((kalturaPlayer.currentTime / kalturaPlayer.duration).toFixed(2));
          // let timeNew = (kalturaPlayer.currentTime) / 1000;
          setPosition(kalturaPlayer.currentTime);
          //Keep Watching Functionality Here.  -- V2 playerUpdatePlayhead & playerUpdatePlayhead.myPluginName
          // let time = 0;
          // let videoLength = kalturaPlayer.duration;
          // let currentPerce = percent;
          timeCurrent = parseInt(kalturaPlayer.currentTime);
          let totalDuration = kalturaPlayer.duration;
          let currentPercent = parseInt((parseInt(kalturaPlayer.currentTime) / parseInt(totalDuration)) * 100);
          let insertObj = {
            user_id: userId,
            content_id: contentId,
            playlist_id: null,
            watched_percent: currentPercent,
            watched_on: new Date(),
            country_code: countryCode
          };
          if ((currentPercent === 0 || currentPercent % 2 === 0) && !sentPercentage.includes(currentPercent)) {
            sentPercentage.push(currentPercent)
            service.PostWatchedContent(insertObj).then(data => {
            }).catch(err => { })
          }
          if (percent == 0.25 && !localStorage.getItem(contentId + '25')) {
            let obj = {
              log_action: "25%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '25%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '25', true);
            await service.UpdateLogViews(obj);
          }
          if (percent == 0.50 && !localStorage.getItem(contentId + '50')) {
            let obj = {
              log_action: "50%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '50%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '50', true);
            await service.UpdateLogViews(obj);
          }
          if (percent == 0.75 && !localStorage.getItem(contentId + '75')) {
            let obj = {
              log_action: "75%",
              content_id: contentId,
              country_code: countryCode,
              okta_id: hcp_id,
              log_event_label: document.baseURI,
              log_event_date_time: getDateTime()
            };
            let gaModel = {
              event: 'video',
              eventCategory: 'Video',
              eventAction: '75%',
              videoId: contentId,
              videoLength: kalturaPlayer.duration,
              videoCurrentTime: kalturaPlayer.currentTime,
              videoVolume: kalturaPlayer.volume * 100,
            };
            service.PushKDPEvents(gaModel);
            localStorage.setItem(contentId + '75', true);
            await service.UpdateLogViews(obj);
          }
          if (percent > 0.25 && localStorage.getItem(contentId + '25')) {
            localStorage.removeItem(contentId + '25')
          }
          if (percent > 0.50 && localStorage.getItem(contentId + '50')) {
            localStorage.removeItem(contentId + '50')
          }
          if (percent > 0.75 && localStorage.getItem(contentId + '75')) {
            localStorage.removeItem(contentId + '75')
          }
        })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.MEDIA_LOADED, async (event) => {
        SetIsPlaying(true);
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PLAY, async (event) => {
        let obj = {
          log_action: "Play",
          content_id: contentId,
          country_code: countryCode,
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        let gaObject = {
          event: 'video',
          eventCategory: 'Video',
          eventAction: 'Play',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaObject);
        await service.UpdateLogViews(obj);
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PAUSE, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Pause',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "playerPaused",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENTER_FULLSCREEN, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Open Full Screen',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "openFullScreen",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.EXIT_FULLSCREEN, (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Close Full Screen',
          videoId: contentId,
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoVolume: kalturaPlayer.volume * 100,
        };
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "closeFullScreen",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      })
      //MEDIA & PLAYER ERROR - GA Entry
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ERROR, event => {
        console.info("Error: category =", event.payload.category);
        console.info("Error: code =", event.payload.code);
        console.info("Error: severity =", event.payload.severity);
        console.info("Error: name =", event.payload.data.name);
        //event.payload.category == 3 - MEDIA ERROR 
        //event.payload.category == 7 - PLAYER ERROR 
        let gaModel = {
          event: 'video',
          eventCategory: 'Video',
          eventAction: 'Kaltura Error',
          videoId: contentId
        }
        service.PushKDPEvents(gaModel);
        let obj = {
          log_action: "playerError",
          content_id: contentId,//data.content_id,
          country_code: countryCode,//countryData ? countryData.country_code : '',
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        try {
          service.UpdateLogViews(obj);
        } catch (err) { }
      });
      //Mute & Unmute - GA & LB Entry
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.MUTE_CHANGE, (event) => {
        if (event.payload.mute) {
          console.info("Muted");
          let gaModel = {
            event: 'video', eventCategory: 'Video',
            eventAction: 'Video Muted',
            videoId: contentId,
          }
          service.PushKDPEvents(gaModel);
          let obj = {
            log_action: "mute",
            content_id: contentId,//data.content_id,
            country_code: countryCode,//countryData ? countryData.country_code : '',
            okta_id: hcp_id,
            log_event_label: document.baseURI,
            log_event_date_time: getDateTime()
          };
          try {
            service.UpdateLogViews(obj);
          } catch (err) { }
        } else {
          console.info("Unmuted");
          let gaModel = {
            event: 'video',
            eventCategory: 'Video',
            eventAction: 'Video Un-Muted',
            videoId: contentId,
          };
          service.PushKDPEvents(gaModel);
          let obj = {
            log_action: "unmute",
            content_id: contentId,//data.content_id,
            country_code: countryCode,//countryData ? countryData.country_code : '',
            okta_id: hcp_id,
            log_event_label: document.baseURI,
            log_event_date_time: getDateTime()
          };
          try {
            service.UpdateLogViews(obj);
          } catch (err) { }
        }
      })
      kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENDED, async (event) => {
        let gaModel = {
          event: 'video', eventCategory: 'Video',
          eventAction: 'Complete',
          videoLength: kalturaPlayer.duration,
          videoCurrentTime: kalturaPlayer.currentTime,
          videoId: contentId,
          videoVolume: kalturaPlayer.volume * 100
        };
        service.PushKDPEvents(gaModel);
        setPosition(0);
        let obj = {
          log_action: "Video Complete",
          content_id: contentId,
          country_code: countryCode,
          okta_id: hcp_id,
          log_event_label: document.baseURI,
          log_event_date_time: getDateTime()
        };
        let insertObj = {
          user_id: userId,
          content_id: contentId,
          watched_percent: 100,
          watched_on: new Date(),
          playlist_id: null,
          country_code: countryCode
        };
        try {
          await service.UpdateLogViews(obj);
          service.PostWatchedContent(insertObj).then(data => {
          }).catch(err => { })
        } catch (err) { }
      })
      kalturaPlayer.loadMedia({ entryId: kalturaId }, { startTime: time }).then((success) => {

      }).catch((error) => {

      });
    }
  }

}
export { VideoPlayerService }