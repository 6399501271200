import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DatePicker from "react-datepicker";
import VVPMDetailsModal from "./VVPMDetailsModal";


const VVPMDetails = (props) => {
    const modalFunc = useRef(null);
    const [openDialog, SetOpenDialog] = useState(false);
    const [affiliateSelectedState, SetaffiliateSelectedState] = useState([]);
    const [activeCountry, setActiveCountry] = useState([])
    const [vvpmIds, SetVVPMIds] = React.useState([]);
    const [affiliateSelected, SetAffiliateSelected] = React.useState({});
    const [selectedclassificationCode, SetSelectedClassificationCode] = React.useState([]);
    const [selectedsubType, setSelectedsubType] = React.useState([]);
    const [vvpmCode, setvvpmCode] = React.useState('');
    const [nameSearch, setnameSearch] = React.useState('');
    const [lookupSearch, setlookupSearch] = React.useState('');

    React.useEffect(() => {
        if (props.affiliatesName.length > 0) {
            SetaffiliateSelectedState(props.affiliatesName)
        }
    }, [props.affiliatesName]);
    React.useEffect(() => {
    }, [props.affiliateObjModel]);
    const removeVVPMDetails = (event, data, countryCode) => {
        let previousList = props.affiliateObjModel[countryCode] ? props.affiliateObjModel[countryCode].rows : [];
        let list = [];
        for (let i = 0; i < previousList.length; i++) {
            if (!(data.document_number__v == previousList[i].document_number__v)) {
                list.push(previousList[i]);
            }
        }
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        newAffiliateObvjModel[countryCode].rows = list;
        props.SetAffiliateObjModel(newAffiliateObvjModel);
    }
    const GetDateValueFrom = (affiliate) => {
        if (affiliate) {
            let date = props.affiliateObjModel ? (props.affiliateObjModel[affiliate.country_code] ? ((new Date(props.affiliateObjModel[affiliate.country_code].from)).toString() == 'Invalid Date' ? '' : (new Date(props.affiliateObjModel[affiliate.country_code].from))) : '') : '';
            let _newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
            if (_newAffiliateObvjModel[affiliate.country_code]) {
                if (Array.isArray(_newAffiliateObvjModel[affiliate.country_code].rows)) {
                    if (_newAffiliateObvjModel[affiliate.country_code].rows.length == 0) {
                        return '';
                    }
                }
            }
            return date;
        }
        return new Date();
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        if (newAffiliateObvjModel[affiliate.country_code]) {
            if (Array.isArray(newAffiliateObvjModel[affiliate.country_code].rows)) {
                if (newAffiliateObvjModel[affiliate.country_code].rows.length == 0) {
                    return '';
                }
            }
            if (newAffiliateObvjModel[affiliate.country_code].from) {
                let newDate = new Date(newAffiliateObvjModel[affiliate.country_code].from);
                if (newDate.toString() != 'Invalid Date') {
                    return newDate;
                }
                else {
                    return new Date();
                }
            }
        }
        return new Date();
    }
    const GetDateValueTo = (affiliate) => {
        // return new Date(); props.affiliateObjModel
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        if (newAffiliateObvjModel[affiliate.country_code]) {
            if (Array.isArray(newAffiliateObvjModel[affiliate.country_code].rows)) {
                if (newAffiliateObvjModel[affiliate.country_code].rows.length == 0) {
                    return '';
                }
            }
            if (newAffiliateObvjModel[affiliate.country_code].to) {

                let newDate = new Date(newAffiliateObvjModel[affiliate.country_code].to);
               

                if (newDate.toString() != 'Invalid Date') {
                    let vvpmIds = newAffiliateObvjModel[affiliate.country_code].rows;
                    let restrictiveDate = new Date(GetMostRestrictiveDate(vvpmIds));
                    if (vvpmIds.length == 0) {
                        return newDate
                    }
                    if (restrictiveDate >= newDate) {
                        return newDate;
                    }
                    else {
                        return new Date()
                    }
                }
                else {
                    return new Date();
                }
            }
        }
    }
    const GetMostRestrictiveDate = (vvpmIds) => {
        if (Array.isArray(vvpmIds)) {
            let list = vvpmIds.map(v => new Date(v.expiration_date__vs));
            let sortedList = list.sort(function (b, a) { return (new Date(b)) - (new Date(a)) });
            for (let i = 0; i < sortedList.length; i++) {
                if (sortedList[i].toString() != 'Invalid Date') {
                    return sortedList[i]
                }
            }
        }
        return new Date();

    }
    const GetMaxDate = (affiliate) => {
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        if (newAffiliateObvjModel[affiliate.country_code]) {
            if (Array.isArray(newAffiliateObvjModel[affiliate.country_code].rows) && newAffiliateObvjModel[affiliate.country_code].rows.length > 0) {
                return GetMostRestrictiveDate(newAffiliateObvjModel[affiliate.country_code].rows);
            }
        }
        else {
            return new Date(8640000000000000);
        }
    }
    const ChangeExpiryDateForAffiliate = (affiliate, date) => {
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        if (newAffiliateObvjModel[affiliate.country_code]) {
            newAffiliateObvjModel[affiliate.country_code].to = new Date(date);
            props.SetAffiliateObjModel(newAffiliateObvjModel);
        }
        else {
            newAffiliateObvjModel[affiliate.country_code] = []
            newAffiliateObvjModel[affiliate.country_code]['to'] = new Date(date);
            newAffiliateObvjModel[affiliate.country_code]['from'] = ''
            newAffiliateObvjModel[affiliate.country_code]['rows'] = []
            props.SetAffiliateObjModel(newAffiliateObvjModel);

        }
    }
    const ChangeFromDateForAffiliate = (affiliate, date) => {
        let newAffiliateObvjModel = Object.assign({}, props.affiliateObjModel);
        if (newAffiliateObvjModel[affiliate.country_code]) {
            newAffiliateObvjModel[affiliate.country_code].from = new Date(date);
            newAffiliateObvjModel[affiliate.country_code].to = "";

            props.SetAffiliateObjModel(newAffiliateObvjModel);
             
        } else {
           
            newAffiliateObvjModel[affiliate.country_code] = []
            newAffiliateObvjModel[affiliate.country_code]['from'] = new Date(date);
            newAffiliateObvjModel[affiliate.country_code]['to'] = ''
            newAffiliateObvjModel[affiliate.country_code]['rows'] = []

            props.SetAffiliateObjModel(newAffiliateObvjModel);

        }
    }
    return (
        <Grid container className="vvpm-section">
            <Grid className='content-field' item md={12} sm={12}>
                <FormControl component="fieldset">
                    <label className='input-label'>
                        Restricted to time range (This field is mandatory for publish/submit content, not for saving as a draft)
                    </label>
                    <label className='helper-text'>
                        Please, choose one VVPM Code per country
                    </label>
                </FormControl>
                {Array.isArray(affiliateSelectedState) ? affiliateSelectedState.map((affiliate, index) => (
                    <div className='vvpm-box'>
                        <div className='vvpm-box-header'>
                            <label> {affiliate.country_name} </label>
                            <div className='vvpm-date-input'>
                                <label> From </label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    minDate={new Date()}
                                    disabled={props.affiliateObjModel[affiliate.country_code]?.rows[0] && props.enableFields?.document_number__v ? false : true}
                                    selected={GetDateValueFrom(affiliate)}
                                    onChange={(date) => { ChangeFromDateForAffiliate(affiliate, date) }}
                                />
                            </div>
                            <div className='vvpm-date-input'>
                                <label> To </label>
                                <DatePicker
                                    selected={GetDateValueTo(affiliate)}
                                    disabled={props.affiliateObjModel[affiliate.country_code]?.rows[0]?.document_number__v && props.enableFields ? false : true}
                                    onChange={(date) => { ChangeExpiryDateForAffiliate(affiliate, date) }}
                                    maxDate={GetMaxDate(affiliate)}
                                    minDate={props.affiliateObjModel ? (props.affiliateObjModel[affiliate.country_code] ? ((new Date(props.affiliateObjModel[affiliate.country_code].from)).toString() == 'Invalid Date' ? new Date() : (new Date(props.affiliateObjModel[affiliate.country_code].from))) : new Date()) : new Date()}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                        <div className='vvpm-box-content'>
                            {props.affiliateObjModel[affiliate.country_code]?.rows[0]?.document_number__v ? <div className='selected-content'>
                                <div className='table-header'>
                                    <div>Document Number</div>
                                    <div>Name</div>
                                    <div>Subtype</div>
                                    <div>Classification</div>
                                    <div>Expiration Date</div>
                                    <div>Primary Category</div>
                                    <div>Primary Sub Category</div>
                                    <div>Secondary Category</div>
                                    <div>Secondary Sub Category</div>
                                    <div></div>
                                </div>
                                <div className='table-body'>
                                    <div>{props.affiliateObjModel ? props.affiliateObjModel[affiliate.country_code]?.rows[0]?.document_number__v : ''}</div>
                                    <div>{props.affiliateObjModel ? props.affiliateObjModel[affiliate.country_code]?.rows[0]?.name__v : ''}</div>
                                    <div>{props.affiliateObjModel ? props.affiliateObjModel[affiliate.country_code]?.rows[0]?.subtype__v : ''}</div>
                                    <div>{props.affiliateObjModel ? props.affiliateObjModel[affiliate.country_code]?.rows[0]?.classification__v : ''}</div>
                                    <div>{props.affiliateObjModel ? props.affiliateObjModel[affiliate.country_code]?.rows[0]?.expiration_date__vs : ''}</div>
                                    <div>{''}</div>
                                    <div>{''}</div>
                                    <div>{''}</div>
                                    <div>{''}</div>
                                    {props.enableFields ? <div><DeleteOutlinedIcon onClick={(event) => {
                                        removeVVPMDetails(event, props.affiliateObjModel[affiliate.country_code]?.rows[0], affiliate.country_code)
                                    }} style={{ color: '#913841' }} /></div> : <div></div>}
                                </div>
                            </div> : null}
                            {props.enableFields ? <div className='search-code' onClick={() => {
                                
                                setnameSearch('')
                                setvvpmCode('')
                                // setnameSearch={setnameSearch} setvvpmCode={setvvpmCode}
                                SetSelectedClassificationCode([])
                                setSelectedsubType([])
                                SetAffiliateSelected(affiliate)
                                SetOpenDialog(true)
                                setActiveCountry(affiliate)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M10 7.46672V14.1334M6.66667 10.8H13.3333M6.5 18.3H13.5C14.9001 18.3 15.6002 18.3 16.135 18.0276C16.6054 17.7879 16.9878 17.4054 17.2275 16.935C17.5 16.4002 17.5 15.7002 17.5 14.3V7.30005C17.5 5.89992 17.5 5.19985 17.2275 4.66507C16.9878 4.19467 16.6054 3.81222 16.135 3.57253C15.6002 3.30005 14.9001 3.30005 13.5 3.30005H6.5C5.09987 3.30005 4.3998 3.30005 3.86502 3.57253C3.39462 3.81222 3.01217 4.19467 2.77248 4.66507C2.5 5.19985 2.5 5.89992 2.5 7.30005V14.3C2.5 15.7002 2.5 16.4002 2.77248 16.935C3.01217 17.4054 3.39462 17.7879 3.86502 18.0276C4.3998 18.3 5.09987 18.3 6.5 18.3Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Search VVPM Code
                            </div> : ""}
                            {/* } */}
                        </div>
                    </div>
                )) : ""}
            </Grid>
            <VVPMDetailsModal
                openDialog={openDialog}
                SetOpenDialog={SetOpenDialog}
                subType={props.subType} classificationCode={props.classificationCode} activeCountry={activeCountry} affiliateObjModel={props.affiliateObjModel} SetVVPMAffiliateMap={props.SetVVPMAffiliateMap} SetVVPMDetails={props.SetVVPMDetails} SetVVPMIds={SetVVPMIds} removeVVPMDetails={removeVVPMDetails} affiliateSelected={affiliateSelected} SetAffiliateObjModel={props.SetAffiliateObjModel} vvpmIds={vvpmIds}
                selectedclassificationCode={selectedclassificationCode} SetSelectedClassificationCode={SetSelectedClassificationCode} selectedsubType={selectedsubType} setSelectedsubType={setSelectedsubType} vvpmCode={vvpmCode} nameSearch={nameSearch} setnameSearch={setnameSearch} setvvpmCode={setvvpmCode} lookupSearch={lookupSearch} setlookupSearch={setlookupSearch}
            />
        </Grid>

    )
}
export default VVPMDetails