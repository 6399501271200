import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

const EditPopup = (props) => {
    let ModalTitle = "";
    if(props.editType === "Question") {
        ModalTitle = "Modify Question Title:";
    } else {
        ModalTitle = "Modify Answer Reveal:";
    }
    const [ansVal,SetansVal] = React.useState('');
    const [oldAns, SetoldAns] = React.useState(props.valueName);
    const changeValue = (newVal) => {
        SetansVal(newVal);
    }
    const setValue = () => {
        if(ansVal.length===0)
            alert("Please do not leave field empty");
        else{
        props.SetgetValue(ansVal);
        props.handleClose();
        }
    }
    React.useEffect(() => {
        SetansVal(props.valueName);
    }, [props.valueName]);
  return (
    <Modal open={props.openModal} onClose={props.handleClose}>
        <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                {ModalTitle}
                <button className='closeModal' onClick={() => {props.handleClose();props.SetgetValue(props.oldAns);SetansVal(props.oldAns)}}></button>
            </Grid>
            <Grid className="modal-body">
                <label className='modalLabel'>{ModalTitle}</label>
                
                <TextField variant="outlined" className='modalTxt' value={ansVal} onChange={(e) => changeValue(e.target.value)}/>
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" className="modalBtn" onClick={() => {props.handleClose();props.SetgetValue(props.oldAns);SetansVal(props.oldAns)}}>Cancel</button>
                <button type="button" className="modalBtn" onClick={() => setValue()}>OK</button>
            </Grid>
        </Grid>
    </Modal>
  )
}

export default EditPopup