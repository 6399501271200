import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Multiselect from './Multiselect';
import { ContentService } from '../Service/content-service';

const ContentSpecialties = (props) => {
    let contentService = new ContentService();

    const [specialities, SetSpecialities] = React.useState([]);
    const [specialtyAffiliateIdMap, SetSpecialtyAffiliateIdMap] = React.useState([]);
    const [displaySpecialties, SetDisplaySpecialties] = React.useState([]);
    const [countryCodes, SetCountryCodes] = React.useState([]);

    React.useEffect(() => {
        contentService.GetAllSpecialities().then((data) => {
            if (Array.isArray(data.data)) {
                let jsondata = data.data;
                let dict = {};
                for (let i = 0; i < jsondata.length; i++) {
                    let spec = jsondata[i];
                    dict[spec.specialty_id] = spec;
                }
                SetSpecialities(jsondata);
            }
        }).catch((err) => {});
    }, []);

    React.useEffect(() => {
        const groupByCountryCode = specialities.reduce((acc, obj) => {
            const { country_code, ...rest } = obj;
            acc[country_code] = acc[country_code] || [];
            acc[country_code].push(rest);
            return acc
        }, {})
        SetSpecialtyAffiliateIdMap(groupByCountryCode)

        if (Object.keys(groupByCountryCode).length > 0) {
            const groupSelectedSpecByCountryCode = props.specialties.reduce((acc, obj) => {
                const { country_code, ...rest } = obj;
                const dd = groupByCountryCode[country_code]?.filter((e) => (rest.value).includes(e.specialty_id) || (rest.value).includes(e.specialty_key))
                let filtered = []
                if (dd) {
                    filtered = dd.map((obj) => {
                        const addkey = {
                            label: obj.specialty_name,
                            key: obj.specialty_key
                        }
                        return {
                            ...obj, ...addkey
                        }
                    })
                } 
                acc[country_code] = acc[country_code] || [];
                acc[country_code] = filtered;
                return acc
            }, {})
            SetDisplaySpecialties(groupSelectedSpecByCountryCode)
        }
    }, [props.affiliates, props.specialties, specialities]);

    const handlerSelect = (values, affiliate_id) => {
        const specialityData = JSON.parse(JSON.stringify(props.specialties))
        const filted = specialityData.find((e) => e.country_code === affiliate_id)

        if (filted) {
            const index = specialityData.findIndex(obj => obj.country_code === affiliate_id)
            if (index !== -1) {
                specialityData[index].value = values.map((e) => e.specialty_key)
            }
        } else {
            specialityData.push({ value: values.map((e) => e.specialty_key), country_code: affiliate_id })
        }
        props.SetSpecialties(specialityData)
    }

    return (
        <div className='restriction-section'>
            <Grid container>
                <Grid className='content-field' item md={12} sm={12}>
                    <FormControl component='fieldset'>
                        <label className='input-label'>
                            Restricted to specialities (Public by default)
                        </label>
                    </FormControl>
                </Grid>
            </Grid>
            {props.affiliates.length > 0 ? props.affiliates.map((item, k) => (
                <div className='selection-section'>
                    <Multiselect
                        disabled={specialtyAffiliateIdMap[item.country_code]?.length && props.enableFields ? false : true}
                        title={item.country_name}
                        tags={displaySpecialties[item.country_code] ? displaySpecialties[item.country_code] : []}
                        open={true}
                        list={specialtyAffiliateIdMap[item.country_code]?.length > 0 ? specialtyAffiliateIdMap[item.country_code]?.map(x => ({ label: x.specialty_name, key: x.specialty_key, specialty_key: x.specialty_key, country_code: item.country_code, specialty_name: x.specialty_name, specialty_id: x.specialty_id })) : []}
                        handleSelected={(value) => { handlerSelect(value, item.country_code) }}
                    />
                </div>
            )) : ""}
        </div>
    )
}
export default ContentSpecialties