import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { CountryService } from '../Services/country-service';
import EditIcon from '../../../public/img/BlueEdit@3x.png';
import AddIcon from '../../../public/img/Add@3x.png';

const Category = (props) => {
    // Get & Set Category Tree
    const [categoryTree , SetCategoryTree] = React.useState([]);
    React.useEffect(() => {
        let countryService = new CountryService();
        countryService.GetAllCategory().then((data) => {
            let categories = data.data;
            if(categories.length > 0) {
                SetCategoryTree(categories);
            }
        }).catch((err) => {  })
        setInnerChk(props.chkOpen);
    }, [props.chkOpen])

    // Handle Propduct Popup
    const [openProductInfo, setopenProductInfo] = React.useState(false);
    const handleOpenProductInfo = (ta_id, brand_id) => {
        setopenProductInfo(true);
        setPopupTAID(ta_id);
        setPopupBrandID(brand_id);
        let selectedPIInfo = props.piInfo.filter((el) => el.therapeutic_area_id === ta_id && el.brand_id === brand_id);
        if(selectedPIInfo.length > 0) {
            setPopupPIURL(selectedPIInfo[0].pi_url);
            setPopupISWarning(selectedPIInfo[0].is_warning);
        } else {
            setPopupPIURL('');
            setPopupISWarning('');
        }
    };
    const handleCloseProductInfo = () => {
        setopenProductInfo(false);
    };
    const [popupTAID, setPopupTAID] = React.useState('');
    const [popupBrandID, setPopupBrandID] = React.useState('');
    const [popupPIURL, setPopupPIURL] = React.useState('');
    const [popupISWarning, setPopupISWarning] = React.useState(false);
    const handleProductInfoSave = () => {
        let piInfoArr = [...props.piInfo];
        let piInfoArrNew = piInfoArr.filter((el) => {
            return (
                (el.therapeutic_area_id === popupTAID && el.brand_id !== popupBrandID) || 
                (el.therapeutic_area_id !== popupTAID && el.brand_id === popupBrandID) ||
                (el.therapeutic_area_id !== popupTAID && el.brand_id !== popupBrandID)
            )
        });
        let popupPIURLTxt = popupPIURL;
        var _CHECK_REG = /^(http:\/\/|https:\/\/|mailto:)/i;
        if (!_CHECK_REG.test(popupPIURLTxt) && popupPIURLTxt) {
            popupPIURLTxt = 'http://' + popupPIURLTxt;
        }
        let newEl = { 
            therapeutic_area_id: popupTAID,
            brand_id: popupBrandID, 
            pi_url: popupPIURLTxt, 
            is_warning: popupISWarning
        }
        piInfoArrNew.push(newEl);
        props.SetPIInfo(piInfoArrNew);
        handleCloseProductInfo();
    }

    // Handle Show Inner 
    const [innerChk, setInnerChk] = React.useState([]);
    const showInner = (cat_id, value) => {
        let innerChkArr = [...innerChk];
        innerChkArr[cat_id] = value;
        setInnerChk( innerChkArr );
    }

    // Handle Selected Categories
    const handleChkCategories = (isCheked, ta_id, brand_id, ind_id) => {
        let TAIds = [];
        let Cat1Ids = [];
        let Cat2Ids = [];
        if (isCheked) {
            if(ta_id) {
                TAIds.push(ta_id);
                TAIds = props.selectedTAIds.concat(TAIds);
                props.SetSelectedTAIds(TAIds);
            }
            if(brand_id) {
                Cat1Ids.push(brand_id);
                Cat1Ids = props.selectedCat1Ids.concat(Cat1Ids);
                props.SetSelectedCat1Ids(Cat1Ids);
            }
            if(ind_id) {
                Cat2Ids.push(ind_id);
                Cat2Ids = props.selectedCat2Ids.concat(Cat2Ids);
                props.SetSelectedCat2Ids(Cat2Ids);
            }
        } else {
            if(ta_id) {
                TAIds = props.selectedTAIds.concat(TAIds);
                let TAindex = TAIds.indexOf(ta_id);
                if (TAindex !== -1) {
                    TAIds.splice(TAindex, 1);
                }
                props.SetSelectedTAIds(TAIds);
            }
            if(brand_id) {
                Cat1Ids = props.selectedCat1Ids.concat(TAIds);
                let Cat1index = Cat1Ids.indexOf(brand_id);
                if (Cat1index !== -1) {
                    Cat1Ids.splice(Cat1index, 1);
                }
                props.SetSelectedCat1Ids(Cat1Ids);
            }
            if(ind_id) {
                Cat2Ids = props.selectedCat2Ids.concat(TAIds);
                let Cat2index = Cat2Ids.indexOf(ind_id);
                if (Cat2index !== -1) {
                    Cat2Ids.splice(Cat2index, 1);
                }
                props.SetSelectedCat2Ids(Cat2Ids);
            }
        }
    }
  return (
    <>
    <Modal open={openProductInfo} onClose={handleCloseProductInfo}>
        <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                Product Information
                <button className='closeModal' onClick={() => handleCloseProductInfo()}></button>
            </Grid>
            <Grid class="modal-body">
                <label className='modalLabel'>PI URL :</label>
                <TextField variant="outlined" value={popupPIURL} className='modalTxt' onChange={(e) => setPopupPIURL(e.target.value)} />
                <label className='modalLabel'>Warning Message Required :</label>
                <FormControlLabel className='labelFont' control={<Checkbox name="upnext" checked={popupISWarning} color="primary" onChange={(e) => setPopupISWarning(e.target.checked)} />} />
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" onClick={() => handleCloseProductInfo()} className="modalBtn">Cancel</button>
                <button type="button" className="modalBtn" onClick={() => handleProductInfoSave()}>Save Changes</button>
            </Grid>
        </Grid>
    </Modal>
    <Grid container className='content'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} lg={12}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Select the Category 1 and Category 2 that will be available for your users</FormLabel>
                </FormControl>
            </Grid>
            {categoryTree.length > 0 ? categoryTree.map((category) => {
                return (
                    <>
                        {category?.children?.length > 0 ?
                            <Grid item xs={12} lg={3} key={category.id}>
                                <FormControl component="fieldset">
                                    {category.children.length != 0 ?
                                        <FormLabel component="label">{category.name}</FormLabel>
                                        : null}
                                    {category.children.length > 0 ? category.children.map((category1) => {
                                        let piURL = '';
                                        let selectedPIInfo = props.piInfo.filter((el) => el.therapeutic_area_id === category.id && el.brand_id === category1.id);
                                        if(selectedPIInfo.length > 0) {
                                            piURL = selectedPIInfo[0].pi_url;
                                        }
                                        return (
                                            <FormGroup key={category1.id}>
                                                <FormControlLabel className='labelFont'
                                                    control={
                                                        <Checkbox
                                                            name="upnext"
                                                            color="primary"
                                                            checked={props.selectedCat1Ids.includes(category1.id)}
                                                            onChange={(e) => {
                                                                showInner(category1.id, e.target.checked);
                                                                handleChkCategories(e.target.checked, category.id, category1.id, null);
                                                            }}
                                                        />
                                                    }
                                                    label={category1.name}
                                                />
                                                {typeof innerChk[category1.id] != "undefined" && innerChk[category1.id] === true ?
                                                    <Grid className='inner_bx'>
                                                        <Grid>
                                                            <div className="piInfo">
                                                            {piURL ? 
                                                                <>
                                                                <span>{piURL}</span>
                                                                <img onClick={() => handleOpenProductInfo(category.id, category1.id)} className='editIcon' src={EditIcon} title="Edit PI Info" alt='Edit PI Info' />
                                                                </> 
                                                            :  <img onClick={() => handleOpenProductInfo(category.id, category1.id)} className='addIcon' src={AddIcon} title="Add PI Info" alt='Add PI Info' />
                                                            }
                                                            </div>
                                                        </Grid>
                                                        {category1.children.length > 0 ? category1.children.map((category2) => {
                                                            return (
                                                                <FormControlLabel className='labelFont'
                                                                    control={
                                                                        <Checkbox
                                                                            name="upnext"
                                                                            color="primary"
                                                                            checked={props.selectedCat2Ids.includes(category2.id)}
                                                                            onChange={(e) => {
                                                                                handleChkCategories(e.target.checked, category.id, category1.id, category2.id);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={category2.name}
                                                                    key={category2.id}
                                                                />
                                                            )
                                                        }) : ""}
                                                    </Grid>
                                                    : null}
                                            </FormGroup>
                                        )
                                    }) : ""}
                                </FormControl>
                            </Grid>
                            : ""}
                    </>
                )
            }) : ""}
        </Grid>
    </Grid>
    </>
  )
}

export default Category