import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Accordion = withStyles({
    root: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        boxShadow: 'none',
        width: '100%',
        margin: '5px',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&.Mui-disabled': {
            backgroundColor: '#fff',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0',
            borderRadius: '4px',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        flexDirection: 'row-reverse',
        minHeight: '35px!important',
        backgroundColor: '#EAECF0',
        '&.disabled': {
            backgroundColor: '#fff!important',
            '& .MuiAccordionSummary-expandIcon': {
                display: 'none'
            }
        },
        '& .MuiAccordionSummary-expandIcon': {
            padding: '0!important',
            color: '#475467'
        },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        label: {
            margin: '0px',
        },
    },
    content: {
        margin: '0!important',
        padding: '0px 0px 0px 20px',
        fontSize: '14px',
        fontWeight: '600',
        color: '#475467',
    }
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
    root: {
        display: 'grid',
        gridTemplateRows: '0.5fr 1fr',
        '&.disabled': {
            display: 'none',
        },
        '& .subcategory': {
            marginTop: '20px',
        },
        '& .heading': {
            color: '#83786E',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%',
        },
        '& .accordian-content': {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
        }
    }
})(MuiAccordionDetails)

const CustomCheckbox = withStyles({
    root: {
        color: 'white',
        '& .MuiIconButton-label': {
            border: '1px solid #D0D5DD',
            borderRadius: '4px',
        },
        '&$checked': {
            color: 'white',
            '& .MuiIconButton-label': {
                position: "relative",
                zIndex: 0,
                border: '1px solid #D92D20',
                borderRadius: '4px',
            },
            '& .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: 'absolute',
                backgroundColor: '#D92D20',
                zIndex: -1
            }
        },
        '&.Mui-disabled': {
            color: ' rgba(0, 0, 0, 0)',
            backgroundColor: 'transparent',
            '&$checked': {
                color: 'white',
                '& .MuiIconButton-label': {
                    position: "relative",
                    zIndex: 0,
                    border: '1px solid #D92D20',
                    borderRadius: '4px',
                },
                '& .MuiIconButton-label:after': {
                    content: '""',
                    left: 4,
                    top: 4,
                    height: 15,
                    width: 15,
                    position: 'absolute',
                    backgroundColor: '#D92D20',
                    zIndex: -1
                }
            },
        }
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomAccordain = (props) => {
    const [expanded, setExpanded] = React.useState('');
    const [checkedItems, setCheckedItems] = React.useState([]);
    useEffect(() => {
        if (props?.selectedCat1?.length) {
            if (props?.children?.length) {
                const filteredData = props.children.filter((e) =>
                    props.selectedCat1.includes(e.id)
                )
                setCheckedItems(filteredData);
                if (filteredData.length) {
                    setExpanded('panel1')
                }

            }
        } else {
            setCheckedItems([])
        }
    }, [props.affiliates])
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handlecat1Change = (event, data, taId, childrenCat1) => {
        let list = JSON.parse(JSON.stringify(checkedItems));
        const category1_id = childrenCat1.map((e) => e.id)
        if (event.target.checked) {
            if (!list.find((e) => e.id === data.id)) {
                const taList = list
                list.push(data)
                setCheckedItems(list);
            }
            props.setSelectedCat1([...props.selectedCat1, data.id]);
            props.SetselectedTherapeuticAreas([...props.selectedTherapeuticAreas, taId])
        } else {
            const updatedArray = list.filter(item => item.id !== data.id);
            setCheckedItems(updatedArray);
            let cat1List = props.selectedCat1.filter(item => item !== data.id)
            props.setSelectedCat1(cat1List);
            if (!cat1List.some(item => category1_id.includes(item))) {
                props.SetselectedTherapeuticAreas(props.selectedTherapeuticAreas.filter((e) => e != taId))
            }
            const allCat2Id = data.children.map((e) => e.id);
            const newIndicationArray = props.selectedCat2.filter(item => !allCat2Id.includes(item))
            props.setSelectedCat2(newIndicationArray)
        }
    };
    return (
        <>
            <Accordion
                disabled={props.disabled}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}>
                <AccordionSummary
                    className={props.disabled ? 'disabled' : ''}
                    expandIcon={<KeyboardArrowRightIcon color='#475467' />}
                    aria-controls="panel1d-content" id="panel1d-header">
                    <label> {props.name} </label>
                </AccordionSummary>
                <AccordionDetails
                    className={props.disabled ? 'disabled' : ''}
                >
                    {props?.children && props.subcategory ?
                        <div className='heading'>
                            Category 1
                        </div> : ''}
                    <div className='accordian-content'>
                        {props?.children && props.children.map((data, id) => (
                            <FormControlLabel
                                control={<CustomCheckbox
                                    className='checkbox'
                                    checked={props?.selectedCat1?.includes(data.id)}
                                    onChange={(e) => {
                                        props.subcategory ?
                                            handlecat1Change(e, data, props?.id, props?.children) :
                                            props.handlecat1Change(e, data, props?.id, props?.children)
                                    }}
                                    disabled={props.disableCheckboxes}
                                    value={data.id}
                                    name={data.name} />}
                                label={data.name}
                            />
                        ))}
                    </div>
                    {props.subcategory && checkedItems?.length ? checkedItems.map(x =>
                        <div>
                            {x.children.length ?
                                <div className='subcategory'>
                                    <div className='heading'>
                                        {x.name} - Category 2
                                    </div>
                                    <div className='accordian-content'>
                                        {x.children.map((val, i) => (
                                            <FormControlLabel
                                                control={<CustomCheckbox
                                                    className='checkbox'
                                                    disabled={props.disableCheckboxes}
                                                    checked={props.selectedCat2.includes(val.id)}
                                                    onChange={(e) => {
                                                        props.handleCategory2(e, val.id)
                                                    }}
                                                    value={val.id}
                                                    name={val.name} />}
                                                label={val.name}
                                            />
                                        ))
                                        }

                                    </div>
                                </div> : ""}
                        </div>
                    ) : ""}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default CustomAccordain