import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import ChipInput from 'material-ui-chip-input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Multiselect from './Multiselect';
import Singleselect from '../Components/Singleselect';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Editor from 'react-medium-editor';
import { ContentService } from '../Service/content-service';
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');

const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#E2E5EA',
            color: '#99938D',
            '&:hover input': {
                cursor: 'not-allowed',
            }
        }
    }
})(TextField);

function cleanDescription(text) {
    return text.replace(/<[^>]*>/g, ' ')
        .replace(/\s{2,}/g, ' ').replace('&nbsp;', '')
        .trim();
}
const ContentInputFields = (props) => {
    const [secondOwner, setSecondOwner] = React.useState([]);
    const [selectedAffiliates, SetSelectedAffiliates] = React.useState([]);
    const [allAffiliates, setAllAffiliates] = React.useState([]);
    const [publisherCountry, setpublisherCountry] = React.useState([]);
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [affiliateLoad, SetaffiliateLoad] = React.useState(false);

    React.useEffect(() => {
        GetAllAffiliatesFunc()
        if (props.id == '') {
            const publishCOuntry = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_code)
            setpublisherCountry(publishCOuntry)
        }
    }, [])
    React.useEffect(() => {
        if (allAffiliates.length && !affiliateLoad) {
            setAllDataAff()
        }
    }, [props.affiliates, allAffiliates])

    React.useEffect(() => {
        const data = props.secondOwnerVal;
        data.label = props.secondOwnerVal?.first_name + " " + props.secondOwnerVal?.last_name + "(" + props.secondOwnerVal?.lilly_id + ")"
        data.key = props.secondOwnerVal.id
        if (data.key) {
            setSecondOwner([data])
        }
    }, [props.secondOwnerVal])

    React.useEffect(()=>{
        if(props.finalSelectedList){
            SetSelectedAffiliates(props.finalSelectedList)
            
        let list = []
        for (let i = 0; i < props.finalSelectedList.length; i++) {
            list.push(props.finalSelectedList[i].key)
        }
        props.SetAffiliates(list)
        }
       
    },[props.finalSelectedList])

    const handleTagsChange = (tags) => {
        let newarr = [...props.tags]
        newarr.push(tags)
        props.SetTags(newarr)
        props.setcontentlost(true)
    }

    const handleSelectedAffiliates = (values) => {
        props.setdetectChange(true)
        props.oldSelectedAffiliates(selectedAffiliates)
        props.SetAffiliatesName(values)
        props.setAlternate(!props.alternate)
    }

    const setAllDataAff = () => {
        let list = [];
        const data = JSON.parse(JSON.stringify(allAffiliates));
        const country_codes = []
        for (let i = 0; i < data?.length; i++) {
            if (props.affiliates && props.affiliates.length && props.affiliates.includes(data[i].country_code)) {
                data[i].key = data[i].country_code
                data[i].label = data[i].country_name
                list.push(data[i])
            }
            // publisherCountry
            // alert("sd")
            const publishCOuntry = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_code)

            if (publishCOuntry.includes(data[i].country_code) && !props.id && !affiliateLoad) {
                const listContains = list.map((e) => e.key)
                if (!listContains.includes(data[i].country_code)) {
                    data[i].key = data[i].country_code
                    data[i].label = data[i].country_name
                    list.push(data[i])
                    country_codes.push(data[i].country_code)
                }

            }
        }
        SetSelectedAffiliates(list);
        if (country_codes.length) {
            props.SetAffiliates(country_codes)
        }
        props.SetAffiliatesName(list)
        SetaffiliateLoad(true)
    }

    const GetAllAffiliatesFunc = () => {
        let contentService = new ContentService();
        contentService.GetAllAffiliates().then((data) => {
            setAllAffiliates(data.data)

        }).catch((err) => {});
    }
    return (
        <Grid container className="content-input radio">
            <Grid className='content-field' item md={6} sm={12}>
                <label className='input-label mandatory'>
                    Title
                </label>
                <CustomTextField
                    disabled={!props.enableFields}
                    className='text-input'
                    variant="outlined"
                    placeholder="Video or Podcast title"
                    size="small"
                    error={(props.title.trim()).length > 60}
                    onChange={(event) => {
                        props.SetTitle(event.target.value)
                        props.setcontentlost(true)
                    }}
                    InputLabelProps={{ shrink: false }}
                    value={props.title} />
                <label className='helper-text'>
                    <div>
                        {(props.title.trim()).length}/60
                    </div>
                    {(props.title.trim()).length > 60 ?
                        <div className='error'>
                            Length exceeds 60 characters
                        </div>
                        : ''}

                </label>
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                <FormControl disabled={!props.enableFields} component="fieldset">
                    <label className='input-label mandatory'>
                        What type of content are you uploading?
                    </label>
                    <RadioGroup className='radio-input' name="content_type" value={props.contentType} onChange={(event) => {
                        props.SetContentType(event.currentTarget.value);
                        props.setcontentlost(true)

                    }} >
                        <FormControlLabel className='radio-options' value="video" control={<Radio />} label="Video" />
                        <FormControlLabel className='radio-options' value="podcast" control={<Radio />} label="Podcast" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                <label className='input-label mandatory'>
                    Description
                </label>
                {!props.enableFields ? <div className='disabled-editor'>{cleanDescription(props.description)}</div> :
                    (props.description && props.description !== '<p><br></p>' ?
                        <Editor id="editor-without-placeholder"
                            className={cleanDescription(props.description).length > 5000 ? "richeditor error" : "richeditor"}
                            text={props.description}
                            onChange={(text, medium) => {
                                let x = text.replace(/&nbsp;/g, ' ')
                                props.SetDescription(x);
                                props.setcontentlost(true);
                            }}
                            options={{
                                toolbar: { buttons: ['anchor'] }, placeholder: false, anchor: {
                                    customClassOption: null,
                                    customClassOptionText: 'Button',
                                    linkValidation: true,
                                    checkLinkFormat: function (inputValue) {
                                        var _CHECK_REG = /^(http:\/\/|https:\/\/|mailto:)/i;
                                        if (!_CHECK_REG.test(inputValue) && inputValue) {
                                            return 'http://' + inputValue;
                                        }
                                        return inputValue;
                                    },
                                    getFormOpts: function () {
                                        var targetCheckbox = this.getAnchorTargetCheckbox(),
                                            buttonCheckbox = this.getAnchorButtonCheckbox(),
                                            opts = { value: this.getInput().value.trim(), buttonClass: "" };
                                        if (this.linkValidation) {
                                            opts.value = this.checkLinkFormat(opts.value);
                                        }
                                        opts.target = '_self';
                                        if (targetCheckbox && targetCheckbox.checked) {
                                            opts.target = '_blank';
                                            opts.buttonClass = "openNewWinDow";
                                        }
                                        if (buttonCheckbox && buttonCheckbox.checked) {
                                            opts.buttonClass = "openNewWinDow";
                                        }
                                        return opts;
                                    },
                                    // placeholderText: 'Type URL with http://, https:// or mailto: prefix',
                                    targetCheckbox: true,
                                    buttonCheckbox: true,
                                    buttonCheckboxText: 'Warning Message Required',
                                    targetCheckboxText: 'Warning Message Required'
                                }
                            }}
                        /> : <Editor id="editor-with-placeholder"
                            className={cleanDescription(props.description.trim()).length > 5000 ? "richeditor editor-placeholder error" : "richeditor editor-placeholder"}
                            text={props.description}
                            onChange={(text, medium) => {
                                let x = text.replace(/&nbsp;/g, ' ');
                                props.SetDescription(x);
                                props.setcontentlost(true);
                            }}
                            options={{
                                toolbar: { buttons: ['anchor'] }, placeholder: { text: 'Enter a description...' }, anchor: {
                                    customClassOption: null,
                                    customClassOptionText: 'Button',
                                    linkValidation: true,
                                    checkLinkFormat: function (inputValue) {
                                        var _CHECK_REG = /^(http:\/\/|https:\/\/|mailto:)/i;
                                        if (!_CHECK_REG.test(inputValue) && inputValue) {
                                            return 'http://' + inputValue;
                                        }
                                        return inputValue;
                                    },
                                    getFormOpts: function () {
                                        var targetCheckbox = this.getAnchorTargetCheckbox(),
                                            buttonCheckbox = this.getAnchorButtonCheckbox(),
                                            opts = { value: this.getInput().value.trim(), buttonClass: "" };
                                        if (this.linkValidation) {
                                            opts.value = this.checkLinkFormat(opts.value);
                                        }
                                        opts.target = '_self';
                                        if (targetCheckbox && targetCheckbox.checked) {
                                            opts.target = '_blank';
                                            opts.buttonClass = "openNewWinDow";
                                        }
                                        if (buttonCheckbox && buttonCheckbox.checked) {
                                            opts.buttonClass = "openNewWinDow";
                                        }
                                        return opts;
                                    },
                                    targetCheckbox: true,
                                    buttonCheckbox: true,
                                    buttonCheckboxText: 'Warning Message Required',
                                    targetCheckboxText: 'Warning Message Required'
                                }
                            }}
                        />)}
                <label className='helper-text'>
                    <div>
                        {cleanDescription(props.description.trim()).length}/5000
                    </div>
                    {cleanDescription(props.description.trim()).length > 5000 ?
                        <div className='error'>
                            Length exceeds 5000 characters
                        </div> :
                        <div>
                            To create a hyperlink, select text as needed, click on the "#" icon and enter the destination URL
                        </div>}
                </label>
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                {allAffiliates.length > 0 && <Multiselect
                    title={'Country Availability'}
                    mandatory
                    tags={selectedAffiliates}
                    open={true}
                    list={allAffiliates.length > 0 ? allAffiliates.map(x => ({ label: x.country_name, key: x.country_code, country_name: x.country_name, country_code: x.country_code, therapeutic_area_ids: x.therapeutic_area_ids, category_1_ids: x.category_1_ids, category_2_ids: x.category_2_ids, library_active: x.library_active })) : []}
                    handleSelected={(value) => { handleSelectedAffiliates(value) }}
                    disabled={!props.enableFields}
                />}
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                <Singleselect
                    title={'Second Owner'}
                    mandatory
                    tags={secondOwner}
                    open={true}
                    list={props.secondOwnerArry.length > 0 ? props.secondOwnerArry.map(x => ({ label: x.first_name + " " + x.last_name + "(" + x.lilly_id + ")", key: x.id })) : []}
                    handleSelected={(value) => {
                        setSecondOwner(value);
                        props.SetSecondOwner(value[0]?.key);
                    }}
                    disabled={!props.enableFields}
                />
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                <label className='input-label'>
                    Tags
                </label>
                <ChipInput
                    className='chipset'
                    variant='outlined'
                    placeholder="Add a tag"
                    name="content_tags"
                    onAdd={(chips) => handleTagsChange(chips)} onDelete={(ind) => {
                        let newArr = [...props.tags]
                        let index = newArr.indexOf(ind, 0);
                        props.setcontentlost(true)
                        newArr.splice(index, 1);
                        props.SetTags(newArr)
                    }}
                    disabled={!props.enableFields}
                    value={props.tags} />
                <label className='helper-text'>
                    <div />
                    <div>
                        Press enter to submit the tag
                    </div>
                </label>
            </Grid>
            <Grid className='content-field' item md={6} sm={12}>
                {props.speakerData.length > 0 && <Multiselect
                    title={'Speaker'}
                    tags={props.selectedSpeakers}
                    open={true}
                    list={props.speakerData.length > 0 ? props.speakerData.map(x => ({ label: x.name, key: x.speaker_id, name: x.name, speaker_id: x.speaker_id, id: x.speaker_id, created_at: x.created_at })) : []}
                    disabled={!props.enableFields}
                    handleSelected={(value) => { props.setSelectedSpeakers(value) }}
                />}
            </Grid>
            <Grid className='content-field radio' item md={6} sm={12}>
                <label className='input-label mandatory'>
                    Functional Area
                </label>
                <FormControl disabled={!props.enableFields} component="fieldset">
                    <RadioGroup className="radio-input" value={props.functionalArea} onChange={(e) => props.SetFunctionalArea(e.target.value)}>
                        <FormControlLabel className='radio-options' value="Marketing" control={<Radio />} label="Marketing" />
                        <FormControlLabel className='radio-options' value="Medical" control={<Radio />} label="Medical" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default ContentInputFields;