import axios from 'axios';

class TAModel{
    constructor(_ta, _cat1, _cat2){
        this.ta = _ta;
        this.cat1 = _cat1;
        this.cat2 = _cat2
    }
}
class PlaylistService {

    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.key = '';
        this.auth_Header = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }
    async GetPlaylistViews(playlistIds){
        let data = await  axios.post(this.baseUrl +`/content/api/contentAudit/GetViewsForPlaylists`, playlistIds, this.auth_Header);
        let dict = {}
        for(let i =0;i<data.data.length;i++){
            let playlist = data.data[i];
            if(!isNaN(parseInt(playlist.total_views))){
                dict[playlist.playlist_id] = playlist.total_views;
            }else{
                dict[playlist.playlist_id] =0;// playlist.total_views;
            }
            
        }
        return dict;
    }
    async GetAllQuizAttempts() {
        return axios.post(this.baseUrl +`/management/api/user/GetAllQuizAttempts`, {module_name:"Playlist"}, this.auth_Header);
    };

    async GetPlaylistbyId(id) {
        let response = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?id=' + id, {}, this.auth_Header);
        return response.data;
    }
    async GetAllPlaylist(param, body={}) {
        if (param) {
            return await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?'+param, body, this.auth_Header);
        }
        else {
            return await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist', body, this.auth_Header);
        }
    }
    async GetPublishersByCountry(param) {
        return axios.post(this.baseUrl + '/management/api/user/GetPublishersByCountry?' + param, {}, this.auth_Header);
    };
    async GetAllContentAudit () {
        return axios.post(this.baseUrl + '/content/api/contentAudit/GetAllContentAudit', {}, this.auth_Header);
    
      }
    async SendEmail(title, country, created, link, publisherEmail) {
        let obj = {
            'mail_type': 'playlist_uploaded',
            'to': publisherEmail,
            'playlist_name': title,
            'country': country,
            'created_at': created,
            'playlist_link': link,
            'subject': 'Playlist Uploaded : '+title
        }
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/aws/SendEmail', obj, this.auth_Header);
    };
    async GetAllUsers(param) {
        if (param) {
            // return axios.post(this.baseUrl + '/management/api/user/GetUsersByQueryCount' + param, {}, this.auth_Header);
            return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/user/GetUsersIdByRoles', {"roles":["2","3","4"]}, this.auth_Header);
        }
        
        else {
            // return axios.post(this.baseUrl + '/management/api/user/GetUsersByQueryCount', {}, this.auth_Header);
            return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/user/GetUsersIdByRoles', {"roles":["2","3","4"]}, this.auth_Header);
        }

    };
    async GetAllHubs() {
        return axios.post(this.baseUrl + '/management/api/user/GetHubsByQuery', {}, this.auth_Header);
    };
    async GetAllAffiliates() {
        return axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery', {}, this.auth_Header);
    };
    async GetAllSpecialities() {
        return await axios.post(this.baseUrl + '/management/api/country/GetSpecialtyByQuery', {}, this.auth_Header);
    }
    async GetCategoryByCountry() {
        return axios.post(this.baseUrl + '/management/api/country/GetCountryIndicationByQuery', {}, this.auth_Header);
    };
    async GetAllTherapeuticAreas() {
        return axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.auth_Header);
    }
    async GetAllBrands() {
        return axios.post(this.baseUrl + '/management/api/category/GetBrandByQuery?del=false', {}, this.auth_Header);
    };
    async GetAllIndications() {
        return axios.post(this.baseUrl + '/management/api/category/GetIndicationByQuery?del=false', {}, this.auth_Header);
    };
    async GetAllContent() {
        return await axios.post(this.baseUrl + '/content/api/video/GetVideosByQuery?category_id=All', {}, this.auth_Header);
    };
    async GetVideosForPlaylist(affiliates, seletedTherapeuticAreas, seletedBrands, seletedIndications, libraries, audience, specialties, timeRange) {

        let obj = {
            affiliateId : affiliates,
            ta_id : seletedTherapeuticAreas,
            brand_id : seletedBrands,
            ind_id : seletedIndications,
            libraries : libraries,
            audience : audience,
            specialties : specialties,
            timeRange : timeRange
        }
        return await axios.post(this.baseUrl + '/content/api/video/GetVideosForPlaylist', obj, this.auth_Header);
        //return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/search/SearchData/recent/0/1000?category_id=` + filterId +`&country=`+affiliates.toString()+`&library=`+selectedLibraryid+`&speciality=`+encodeURIComponent(special)+`&Audiance=`+encodeURIComponent(audiance), {}, this.auth_Header);
    };
    async GetPlayListVideos(videoIds) {
        return await axios.post(this.baseUrl + '/content/api/video/GetVideoByIds', videoIds, this.auth_Header);
    }
    async UpdatePlaylist(model) {
        let response = await axios.post(this.baseUrl + '/content/api/playlist/UpdatePlaylist', model, this.auth_Header);
        return response.data;
    }
    async UploadFileToS3(fileName, renderResult) {
        let _key =fileName;// 'Country/Banner/' + (new Date()).getTime().toString() + fileName;
        let _result = renderResult;
        let dataUrl = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedUrlPutObject', { key: _key }, this.auth_Header);
        return (await axios.put(dataUrl.data, renderResult)).data;
    }
    async GetS3File(key) {
        try {
            if (key != null && key.length > 0) {
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: key.trim() }, this.auth_Header);
                return (await axios.get(data.data)).data//.binarySring;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }
    async GetAllLibrary() {
        return axios.post(this.baseUrl +'/management/api/library/GetAllLibrary', {}, this.auth_Header);
    };
    async GetAllQuiz(obj) {
        if(obj){
            return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, obj, this.auth_Header);
        }
        else{
            return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, {}, this.auth_Header);
        }
    };
    async deleteContentById(id) {
        return axios.post(this.baseUrl + '/content/api/playlist/RemovePlaylist/' + id, {}, this.auth_Header);
    };

    async GetCountryCategories(countryCodes){
        let taList =[];
        let cat1List = [];
        let cat2List = [];
        let checkCat2 =[]
        if(Array.isArray(countryCodes)){
            for(let i =0;i<countryCodes.length;i++){
                let model = await this.GetCategoriesFromSelectedCountries(countryCodes[i]);
                model.cat2 = await this.addThIdinCat2(model.cat2, model.cat1)
                

                if(taList.length>0){
                    taList = model.ta.filter(value => (taList.map(v=>v.therapeutic_area_id)).includes(
                        value.therapeutic_area_id));
                    
                }else{
                    if(i < (countryCodes.length - 1) || i == 0){
                        taList = model.ta;
                    }
                }
                if(cat1List.length>0){
                    cat1List = model.cat1.filter(value => (cat1List.map(v=>v.therapeutic_area_id)).includes(
                        value.therapeutic_area_id) && (cat1List.map(v=>v.category_1_id)).includes(
                            value.category_1_id));
                    
                }else{
                    if(i < (countryCodes.length - 1) || i == 0){
                        cat1List = model.cat1;
                    }
                }  
                if(cat2List.length>0){
                    cat2List = model.cat2.filter(value => (cat2List.map(v=>v.category_1_id)).includes(
                        value.category_1_id) && cat2List.map(v=>v.therapeutic_area_id).includes(
                            value.therapeutic_area_id) && cat2List.map(v=>v.category_2_id).includes(
                                value.category_2_id) );
                    
                }else{
                    if(i < (countryCodes.length - 1) || i == 0){
                        cat2List = model.cat2;
                    }
                } 
            }
            return new TAModel(taList, cat1List, cat2List);
        }
        
    }
    async GetCategoriesFromSelectedCountries (countryCode){
        let countryData = await axios.post(this.baseUrl + 
            '/management/api/user/GetAffiliateByQuery?country_code='+countryCode, 
        {}, this.auth_Header); //therapeutic_area_ids, category_1_ids,category_2_ids
        if(Array.isArray(countryData.data)){
            for(let i =0;i<countryData.data.length;i++){
                let tas = await this.GetTAfromTAIds(countryData.data[i].therapeutic_area_ids);
                let cat1Ids = await this.GetCategory1fromIds(countryData.data[i].category_1_ids);
                let cat2Ids = await this.GetCategory2fromIds(countryData.data[i].category_2_ids);
                return new TAModel(tas, cat1Ids, cat2Ids);
            }
        }
        
    }
    async addThIdinCat2 (cat2, cat1){
        for(let i=0;i<cat1.length;i++){
            for(let k=0; k<cat2.length;k++){
                if(cat1[i].category_1_id == cat2[k].category_1_id){
                    cat2[k].therapeutic_area_id = cat1[i].therapeutic_area_id
                }
            }
        }
        return cat2
    } 
    async GetCategory2fromIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory2ByTaIds', 
        ids, this.auth_Header);
        return data.data;
    }
    async GetCategory1fromIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory1ByTaIds', 
        ids, this.auth_Header);
        return data.data;
    }
    async GetTAfromTAIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAresByTaIds', 
        ids, this.auth_Header);
        return data.data;
    }
}

export { PlaylistService }