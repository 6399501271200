import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PlaylistService } from '../Service/playlist-service';

const GetAllCategoryTree = (therapeuticAreas, category1, category2) => {
  let mainObj = [];
  if (therapeuticAreas.length > 0) {
    for (let t = 0; t < therapeuticAreas.length; t++) {
      let obj = { id: "", name: "", children: [] };
      let ta_id = therapeuticAreas[t].therapeutic_area_id;
      let ta_name = therapeuticAreas[t].therapeutic_area_name;
      obj.id = ta_id;
      obj.name = ta_name;
      obj.children = [];
      if (category1.length > 0) {
        for (let b = 0; b < category1.length; b++) {
          let cat1_ta_id = category1[b].therapeutic_area_id;
          let cat1_id = category1[b].category_1_id;
          let cat1_name = category1[b].category_1_name;
          if (cat1_ta_id === ta_id) {
            obj.children.push({ id: cat1_id, name: cat1_name, children: [] });
          }
          if (category2.length > 0) {
            for (let i = 0; i < category2.length; i++) {
              let cat2_ta_id = cat1_ta_id;
              let cat2_cat1_id = category2[i].category_1_id;
              let cat2_id = category2[i].category_2_id;
              let cat2_name = category2[i].category_2_name;
              if (cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                if (obj.children.length > 0) {
                  for (let k = 0; k < obj.children.length; k++) {
                    if (obj.children[k] && obj.children[k].id === cat2_cat1_id &&
                      cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                      obj.children[k].children.push({ id: cat2_id, name: cat2_name });
                    }
                  }
                }
              }
            }
          }
        }
      }
      mainObj.push(obj);
    }
  }
  return mainObj;
}
const ContentTherapeuticArea = (props) => {
  // Get All Country TA, Cat1 & Cat2 
  const [categories, SetCategoryTree] = React.useState([]);

  React.useEffect(() => {
    let country_code = []
    for (let i = 0; i < props.affiliates.length; i++) {
      country_code.push(props.affiliates[i].country_code)
    }
    let tharea = [];
    let cat1 = [];
    let cat2 = [];

    for (let i = 0; i < props.affiliates.length; i++) {
      tharea.push(props.affiliates[i].therapeutic_area_ids)
      cat1.push(props.affiliates[i].category_1_ids)
      cat2.push(props.affiliates[i].category_2_ids)
    }
    let thUnique = []
    let cat1Unique = []
    let cat2Unique = []

    if (tharea.length > 0) {
      thUnique = tharea.reduce((a, b) => a.filter(c => b.includes(c)));
    }
    if (cat1.length > 0) {
      cat1Unique = cat1.reduce((a, b) => a.filter(c => b.includes(c)));
    }
    if (cat2.length > 0) {
      cat2Unique = cat2.reduce((a, b) => a.filter(c => b.includes(c)));
    }
    // let tree = GetAllCategoryTree(thUnique, cat1Unique, cat2Unique)
    // SetCategoryTree(tree);
    let datata = []
    let datacat1 = []
    let datacat2 = []

    if(props?.allcategory?.length > 0 && thUnique?.length > 0){
      for(let i = 0 ; i <props.allcategory.length; i++){
        if(thUnique.includes(props.allcategory[i].id)){
          let list = props.allcategory[i]
          list['therapeutic_area_id'] = props.allcategory[i].id
          list['therapeutic_area_name'] = props.allcategory[i].name
          datata.push(list)
        }
      }
      if( cat1Unique.length > 0){
        for(let i = 0 ; i <datata.length; i++){
          if(thUnique.includes(datata[i].id)){
            for(let j = 0 ;j<datata[i].children.length; j++){
              let list = []
              if(cat1Unique.includes(datata[i].children[j].id)){
                list['category_1_id'] = datata[i].children[j].id
                list['category_1_name'] = datata[i].children[j].name
                list['children'] = datata[i].children[j].children
                list['id'] = datata[i].children[j].id
                list['therapeutic_area_id'] = datata[i].therapeutic_area_id
                datacat1.push(list)
              }

            }
          }
        }
        if( cat2Unique.length > 0){
          for(let i = 0 ; i <datacat1.length; i++){
            if(cat1Unique.includes(datacat1[i].id)){
              for(let j = 0 ;j<datacat1[i].children.length; j++){
                let list = []

                if(cat2Unique.includes(datacat1[i].children[j].id)){
                  list['category_2_id'] = datacat1[i].children[j].id
                  list['category_2_name'] = datacat1[i].children[j].name
                  list['category_1_id'] = datacat1[i].category_1_id
                  datacat2.push(list)

                }
              }
              
            }
          }
        }
      }
    }
     let tree = GetAllCategoryTree(datata, datacat1, datacat2)
    SetCategoryTree(tree);
    let listtree = []
    for(let i = 0;i< tree.length;i++){
      if(tree[i].children.length > 0){
        listtree.push(tree[i]);
      }
    }
    SetCategoryTree(listtree);
  }, [props.affiliates, props.affiliatesName, props.allcategory])

  return (
    <Grid container className="content white_row">
      <Grid container className="content_inner_box">
        <Grid item lg={12} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Therapeutic Areas, Category 1 and Category 2 (Category 1 is mandatory field)</FormLabel>
            <div className='ml20'>
              <>
                {categories.length > 0 ?
                  <TreeView className='contentTreeview' defaultCollapseIcon={<ExpandMoreIcon className='iconSvg' />} defaultExpandIcon={<ExpandMoreIcon className='iconSvg' />}>
                    {categories.map((treeItemData, index) => {
                      return (
                        <>
                          <TreeItem className={index} nodeId={treeItemData?.id?.toString()} key={index} label={
                            <>
                              <div className='childDrop'>{treeItemData?.name ? treeItemData.name : null}</div>
                            </>
                          }>
                            {treeItemData.children.length > 0 ? treeItemData.children.map((treeItemChildren, innerIndex) => {
                              return (
                                <TreeItem nodeId={"treeItemChildren" + treeItemChildren.id.toString()} key={innerIndex} label={
                                  <>
                                    <div className='childDrop'>
                                      <FormControlLabel control={
                                        <Checkbox value={treeItemChildren.name} name="categories"
                                          checked={props.selectedBrands.includes(treeItemChildren.id)}
                                          onChange={(event) => {
                                            if (event.target.checked) {
                                              if (props.selectedBrands == null) {
                                                props.SetselectedBrands([treeItemChildren.id]);
                                              } else {
                                                props.SetselectedBrands([...props.selectedBrands, treeItemChildren.id]);
                                              }
                                              if (props.selectedTherapeuticAreas == null) {
                                                props.SetselectedTherapeuticAreas([treeItemData.id]);
                                              } else {
                                                props.SetselectedTherapeuticAreas([...props.selectedTherapeuticAreas, treeItemData.id]);
                                              }
                                            } else {
                                              props.SetselectedBrands(props.selectedBrands.filter(item => item !== treeItemChildren.id));
                                              props.SetselectedTherapeuticAreas(props.selectedTherapeuticAreas.filter(item => item !== treeItemData.id));
                                            }
                                          }}
                                        />
                                      } />
                                      {treeItemChildren?.name ? treeItemChildren.name : null}
                                    </div>
                                  </>
                                }
                                >
                                  {treeItemChildren.children.length > 0 ? treeItemChildren.children.map((treeItemSubchildren, innerIndex2) => {
                                    return (
                                      <TreeItem nodeId={"treeItemSubchildren" + treeItemSubchildren?.id} key={innerIndex2} label={
                                        <>
                                          <div className='subchildDrop'>
                                            <FormControlLabel control={
                                              <Checkbox value={treeItemSubchildren.name} name="categories"
                                                checked={props.selectedIndications.includes(treeItemSubchildren.id)}
                                                onChange={(event) => {
                                                  if (event.target.checked) {
                                                    if (props.selectedIndications == null) {
                                                      props.SetselectedIndications([treeItemSubchildren.id]);
                                                    } else {
                                                      props.SetselectedIndications([...props.selectedIndications, treeItemSubchildren.id]);
                                                    }
                                                    if (props.selectedTherapeuticAreas == null) {
                                                      props.SetselectedTherapeuticAreas([treeItemData.id]);
                                                    } else {
                                                      props.SetselectedTherapeuticAreas([...props.selectedTherapeuticAreas, treeItemData.id]);
                                                    }
                                                  } else {
                                                    props.SetselectedIndications(props.selectedIndications.filter(item => item !== treeItemSubchildren.id));
                                                    props.SetselectedTherapeuticAreas(props.selectedTherapeuticAreas.filter(item => item !== treeItemData.id));
                                                  }
                                                }}
                                              />
                                            } />
                                            {treeItemSubchildren?.name ? treeItemSubchildren.name : null}
                                          </div>
                                        </>
                                      }
                                      />
                                    )
                                  }) : null}
                                </TreeItem>
                              )
                            }) : null}
                          </TreeItem>
                        </>
                      );
                    })}
                  </TreeView>
                  : <Grid>No Data Found</Grid>}
              </>
            </div>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ContentTherapeuticArea