import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

const AddEditPopup = (props) => {
    let ModalTitle = "";
    if(props.indType === "Category1") {
        ModalTitle = "Category 1";
    } else if(props.indType === "Category2") {
        ModalTitle = "Category 2";
    } else {
        ModalTitle = "Therapeutic Area";
    }
  return (
    <Modal open={props.openModal} onClose={props.handleClose}>
        <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                {props.indOldName === "" ? "Create New " : "Edit "} 
                {ModalTitle}
                <button className='closeModal' onClick={props.handleClose}></button>
            </Grid>
            <Grid className="modal-body">
                <label className='modalLabel'>{ModalTitle} Name</label>
                <TextField variant="outlined" className='modalTxt' value={props.indName} onChange={(e) => props.setIndName(e.target.value)} />
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" className="modalBtn" onClick={() => props.handleClose()}>Cancel</button>
                <button type="button" className="modalBtn" disabled={props.isDisabled} onClick={() => {props.saveItem(); props.setIsDisabled(true)}}>OK</button>
            </Grid>
        </Grid>
    </Modal>
  )
}

export default AddEditPopup